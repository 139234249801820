import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';

import { useAppStore } from 'MobxStores/context';
import { api } from 'Services/api';
import { DeviceSubType, VehicleUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { ProjectFleets } from '@electreon/electreon-metadata-service-gen-ts-client';
import { updateDeviceInDeployment } from '@electreon_ui/shared/utils/queriesUtils';

import { getNextVUId } from 'Components/Forms/CreateDevicePopup/utils/CreateDeviceUtils';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';

import { prefixStyles } from './utils/InputStyles';
import { vehicleTypes } from '@electreon_ui/shared/stores/deviceStore/deviceStoreTypes';

const createVUSchema = z.object({
  deviceName: z
    .string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: z
    .string()
    .min(1, 'Device ID is required')
    .refine(async (value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  deviceSubType: z.string().min(1, 'Device subtype is required'),
  projectName: z.string().optional(),
  prefix: z.string().min(1, 'Prefix is required'),
  vehicleType: z.enum(vehicleTypes).refine((value) => {
    return vehicleTypes.includes(value);
  }, 'Vehicle type is required'),
  numOfReceivers: z.number().optional(),
  batteryCapacity: z.string().optional(),
  ignoreAlerts: z.boolean().optional(),
});

const createVUDefaultValues = {
  deviceName: '',
  deviceId: '',
  deviceSubType: '',
  projectName: '',
  prefix: 'VU',
  vehicleType: vehicleTypes[0],
  numOfReceivers: 1,
  ignoreAlerts: false,
};

type PartialDeviceSubTypeOptions = Partial<Record<DeviceSubType, DeviceSubType>>;

const VuDeviceSubTypeOptions: PartialDeviceSubTypeOptions = { VU_UNKNOWN: 'VU_UNKNOWN', VU_CU_N: 'VU_CU_N' };

type CreateVuInput = z.TypeOf<typeof createVUSchema>;

export const CreateVuForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const deviceSubTypeValues = Object.values(VuDeviceSubTypeOptions);
    const { queryClient, projectStore } = useAppStore();

    const mutation = useMutation({
      mutationFn: (device: VehicleUnitModel) => api.deviceMetadata.vu.createVehicleUnit(device),
      onMutate: (device) => {
        queryClient?.setQueryData(['fleets', +projectStore.selectedProject?.id!], (oldData: ProjectFleets) =>
          updateDeviceInDeployment(oldData, device)
        );
      },
      onSuccess: (res) => {
        if (res.status === 201) {
          toast.success('VU device created');
          reset(createVUDefaultValues);
          onSuccessfulSubmit?.();
        } else {
          toast.error('VU device creation failed');
          setSubmitError('VU Device creation failed');
          throw new Error('Create VU device failed');
        }
      },
      onError: (err) => {
        toast.error('VU Device creation failed');
        console.error('Create VU device error', JSON.stringify(err));
      },
    });

    const {
      register,
      formState: { errors /* isSubmitSuccessful */ },
      reset,
      watch,
      setError,
      clearErrors,
      handleSubmit,
      setValue,
    } = useForm<CreateVuInput>({
      resolver: zodResolver(createVUSchema),
      defaultValues: createVUDefaultValues,
    });

    const { deviceNameAvaliable, deviceIDAvaliable } = useInputAvailability(
      watch,
      selectedProject?.id,
      setError,
      clearErrors,
      'VU'
    );

    useEffect(() => {
      getNextVUId().then((nextID) => {
        setValue('deviceId', String(nextID || ''));
      });
    }, []);

    const onSubmitHandler: SubmitHandler<CreateVuInput> = (values) => {
      setLoading(true);
      setSubmitError(null);
      const { deviceName, deviceId, prefix, batteryCapacity, ignoreAlerts } = values;
      const vehicleType = values.vehicleType;

      mutation.mutate(
        {
          // todo: change to createVUDevice
          deviceType: 'VU',
          deviceSubType: values.deviceSubType as DeviceSubType,
          id: `${prefix}${deviceId}`,
          name: deviceName,
          projectId: selectedProject?.id as number,
          vehicleType: vehicleType,
          timezone: selectedProject?.timezoneStr as string,
          batteryCapacity: Number(batteryCapacity),
          ignoreAlerts: ignoreAlerts,
        },
        {
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    };

    return (
      <>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Create Vehicle Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField
              select
              label='Device SubType'
              sx={{ flex: 1 }}
              error={!!errors['deviceSubType']}
              helperText={errors['deviceSubType'] ? errors['deviceSubType'].message : ''}
              defaultValue=''
              {...register('deviceSubType')}
            >
              {deviceSubTypeValues.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField sx={prefixStyles} disabled {...register('prefix')} />
            <TextField
              sx={{ flex: 5 }}
              InputLabelProps={{ shrink: !!watch('deviceId') }}
              label='Device ID'
              fullWidth
              error={!!errors['deviceId']}
              helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InputAdornmentIcon isValid={deviceIDAvaliable} />
                  </InputAdornment>
                ),
              }}
              {...register('deviceId')}
            />
          </Stack>
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...register('deviceName')}
          />
          <TextField
            select
            sx={{ mb: 2 }}
            label='Vehicle Type'
            fullWidth
            error={!!errors['vehicleType']}
            helperText={errors['vehicleType'] ? errors['vehicleType'].message : ''}
            {...register('vehicleType')}
          >
            <MenuItem value='BUS'>Bus</MenuItem>
            <MenuItem value='TRUCK'>Truck</MenuItem>
            <MenuItem value='PRIVATE'>Private</MenuItem>
            <MenuItem value='VAN'>Van</MenuItem>
            <MenuItem value='DELIVERY_TRUCK'>Delivery Truck</MenuItem>
          </TextField>
          <TextField
            sx={{ mb: 2 }}
            label='Battery Capacity'
            fullWidth
            type='number'
            error={!!errors['batteryCapacity']}
            helperText={errors['batteryCapacity'] ? errors['batteryCapacity'].message : ''}
            {...register('batteryCapacity')}
          />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <FormControlLabel
              control={<Checkbox />}
              {...register('ignoreAlerts')}
              label='Ignore Alerts for This Device'
            />
          </Stack>
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons onCancel={onCancel} submitLabel='Create Device' />
        </Box>
      </>
    );
  }
);
