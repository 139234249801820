import { useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { boolean, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@tanstack/react-query';

import { updateMUDevice } from 'Components/Forms/FormsAPIs/api';
import { useAppStore } from 'MobxStores/context';
import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { ProjectDeployment } from '@electreon/electreon-metadata-service-gen-ts-client';

import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { EditDeviceFormProps } from 'Components/Forms/EditDeviceForm/EditDeviceForm';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { editMuMutation } from 'Components/Forms/EditDeviceForm/utils';

const EditMUSchema = object({
  deviceName: string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: string()
    .min(1, 'Device ID is required')
    .refine((value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  projectName: string().optional(),
  prefix: string().min(1, 'Prefix is required'),
  chargingType: string().min(1, 'Charging type is required'),
  deploymentType: string().min(1, 'Deployment type is required'),
  longitude: string()
    .nullable()
    .refine((value) => {
      if (!value) return true;
      const longitude = parseFloat(value);
      return longitude >= -180 && longitude <= 180;
    }, 'Longitude must be between -180 and 180')
    .refine((value) => {
      if (!value) return true;
      return !value.includes(' ');
    }, 'Longitude must not include spaces'),
  latitude: string()
    .nullable()
    .refine((value) => {
      if (!value) return true;
      const latitude = parseFloat(value);
      return latitude >= -90 && latitude <= 90;
    }, 'Latitude must be between -90 and 90')
    .refine((value) => {
      if (!value) return true;
      return !value.includes(' ');
    }, 'Latitude must not include spaces'),
  ignoreAlerts: boolean().optional(),
});

type EditMuInput = TypeOf<typeof EditMUSchema>;

export const EditMuForm: React.FC<EditDeviceFormProps> = observer(
  ({ device, onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [muPrefix, setMUPrefix] = useState<'AG' | 'UG' | 'SM' | ''>('');
    const { deviceStore, queryClient, projectStore } = useAppStore();
    const deviceChangeVersion = deviceStore.versions?.[device.id || '']?.deviceChangesVersion;

    const mutation = useMutation({
      mutationFn: (updatedMu: ManagementUnitModel) => updateMUDevice(updatedMu),
      onMutate: (updatedMu) => {
        queryClient.setQueryData(['projectDeployment', +selectedProject?.id!], (oldData: ProjectDeployment) =>
          editMuMutation(oldData, updatedMu)
        );
      },
      onSuccess: (res) => {
        if (res.status === 200) {
          toast.success('MU device updated');
          reset();
          const updatedMu = res.data;
          const updatedMuList = projectStore.muList.map((mu) => (mu.id === updatedMu.id ? updatedMu : mu));
          projectStore.setMuList(updatedMuList);
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('MU device update failed');
          setSubmitError('MU Device update failed');
          throw new Error('Update MU device failed');
        } else if (res.status === 409) {
          toast.error('MU creation conflict');
          setSubmitError('MU Creation conflict');
          throw new Error('Update MU device failed');
        } else {
          toast.error('MU device update failed');
          setSubmitError('MU Device update failed');
          throw new Error('Update MU device failed');
        }
      },
      onError: (err) => {
        toast.error('MU device update failed');
        console.error('Update MU device error', JSON.stringify(err));
      },
    });

    const {
      register,
      formState: { errors /* isSubmitSuccessful */ },
      watch,
      reset,
      setError,
      clearErrors,
      handleSubmit,
    } = useForm<EditMuInput>({
      resolver: zodResolver(EditMUSchema),
      defaultValues: {
        deviceName: device.name,
        deviceId: device?.id && device.id.slice(2),
        projectName: selectedProject?.name,
        prefix: device?.id && device.id.slice(0, 2),
        chargingType: device.chargingType,
        deploymentType: device.deploymentType,
        longitude: String(device.longitude),
        latitude: String(device.latitude),
        ignoreAlerts: false,
      },
    });

    const { deviceNameAvaliable } = useInputAvailability(
      watch,
      selectedProject?.id,
      setError,
      clearErrors,
      'MU',
      device
    );

    const onSubmitHandler: SubmitHandler<EditMuInput> = (values) => {
      setSubmitError(null);
      const { deviceName, deviceId, prefix, latitude, longitude, ignoreAlerts } = values;
      const chargingType = values.chargingType as 'STATIC' | 'DYNAMIC';
      const deploymentType = values.deploymentType as 'UNDERGROUND' | 'SURFACE';

      mutation.mutate({
        ...device,
        name: deviceName,
        projectId: selectedProject?.id,
        chargingType,
        deploymentType,
        deviceType: 'MU',
        id: `${prefix}${deviceId}`,
        latitude: Number(latitude) || 0,
        longitude: Number(longitude) || 0,
        version: deviceChangeVersion || device.version,
        ignoreAlerts: ignoreAlerts,
      });
    };

    return (
      <>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Edit Management Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField
              label={'Prefix'}
              disabled
              sx={{ ...disabledTextFieldStyle }}
              error={!!errors['prefix']}
              helperText={errors['prefix'] ? errors['prefix'].message : ''}
              value={watch('prefix')}
              {...register('prefix')}
            >
              <MenuItem key='MU' value={muPrefix}>
                {muPrefix}
              </MenuItem>
            </TextField>
            <TextField
              InputLabelProps={{ shrink: !!watch('deviceId') }}
              disabled
              sx={{ ...disabledTextFieldStyle }}
              label='Device ID'
              fullWidth
              error={!!errors['deviceId']}
              helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
              type='number'
              {...register('deviceId')}
            />
            <TextField
              value={device.deviceSubType ?? '--'}
              disabled
              sx={{ ...disabledTextFieldStyle }}
              label='Subtype'
              fullWidth
            />
          </Stack>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField
              select
              label='Charging Type'
              sx={{ flex: 1 }}
              error={!!errors['chargingType']}
              helperText={errors['chargingType'] ? errors['chargingType'].message : ''}
              value={watch('chargingType')}
              {...register('chargingType')}
            >
              <MenuItem value='STATIC'>Static</MenuItem>
              <MenuItem value='DYNAMIC'>Dynamic</MenuItem>
            </TextField>
            <TextField
              select
              label='Deployment Type'
              sx={{ flex: 1 }}
              error={!!errors['deploymentType']}
              helperText={errors['deploymentType'] ? errors['deploymentType'].message : ''}
              value={watch('deploymentType')}
              {...register('deploymentType')}
            >
              <MenuItem value='UNDERGROUND'>Under Ground</MenuItem>
              <MenuItem value='SURFACE'>Above Ground</MenuItem>
            </TextField>
          </Stack>
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...register('deviceName')}
          />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...register('longitude')}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...register('latitude')}
            />
          </Stack>
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <FormControlLabel
              control={<Checkbox defaultChecked={!!device?.ignoreAlerts} />}
              {...register('ignoreAlerts')}
              label='Ignore Alerts for This Device'
            />
          </Stack>
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons onCancel={onCancel} />
        </Box>
      </>
    );
  }
);

EditMuForm.displayName = 'EditMuForm';
