import { useState } from 'react';
import { Alert, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { observer } from 'mobx-react-lite';

import { api } from 'Services/api';
import { useAppStore } from 'MobxStores/context';
import { Fleet } from '@electreon/electreon-metadata-service-gen-ts-client';

import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';

const createFleetSchema = object({
  fleetName: string().min(1, 'Fleet name is required'),
  projectName: string().optional(),
});

const createFleetDefaultValues = {
  fleetName: '',
  projectName: '',
};

type CreateFleetInput = TypeOf<typeof createFleetSchema>;

export const CreateFleetForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { projectStore } = useAppStore();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<CreateFleetInput>({
    resolver: zodResolver(createFleetSchema),
    defaultValues: createFleetDefaultValues,
  });

  const onSubmitHandler: SubmitHandler<CreateFleetInput> = (values) => {
    setSubmitError(null);

    const fleet: Fleet = {
      projectId: projectStore.selectedProject?.id,
      name: values.fleetName,
    };

    api.metadata.fleets
      .createFleet(fleet)
      .then((res) => {
        toast.success('Fleet created');
        reset(createFleetDefaultValues);
        onSuccessfulSubmit?.();
      })
      .catch((err) => {
        toast.error('Create fleet failed');
        console.error('Create fleet failed:', JSON.stringify(err));
        setSubmitError('Create fleet failed');
        throw new Error('Create fleet failed');
      });
  };

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Create Fleet
      </Typography>
      <Stack component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <SelectProject selectedProject={projectStore.selectedProject} errors={errors} />
        <TextField
          sx={{ mb: 2 }}
          label='Fleet Name'
          fullWidth
          error={!!errors['fleetName']}
          helperText={errors['fleetName'] ? errors['fleetName'].message : ''}
          {...register('fleetName')}
        />
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons onCancel={onCancel} submitLabel='Create Fleet' />
      </Stack>
    </>
  );
});

CreateFleetForm.displayName = 'CreateFleetForm';
