import { ParkingSpot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { getProjectParkingSpots } from 'Utils/APIUtils';

export const getAndRefreshParkingSpots = async (depotId: number, muId: string) => {
  let parkingSpotsRes;
  let filteredParkingSpots: ParkingSpot[] = [];
  if (depotId) parkingSpotsRes = await getProjectParkingSpots(depotId);
  if (parkingSpotsRes) {
    filteredParkingSpots = parkingSpotsRes.data.filter((spot) => spot.muId === muId);
  }
  return filteredParkingSpots;
};

export const getAndRefreshOcppParkingSpots = async (depotId: number, ocppId: string) => {
  let parkingSpotsRes;
  let filteredParkingSpots: ParkingSpot[] = [];
  if (depotId) parkingSpotsRes = await getProjectParkingSpots(depotId);
  if (parkingSpotsRes) {
    filteredParkingSpots = parkingSpotsRes.data.filter((spot) => spot.muId === ocppId);
  }

  return filteredParkingSpots;
};

export const getFirstAvailableSegmentNum = (parkingSpots: ParkingSpot[]) => {
  const getCurrentSelectedSegmentNums = (parkingSpots: ParkingSpot[]) => {
    const segmentNums = parkingSpots
      .flatMap((spot) => spot.segments?.map((segment) => segment.segmentNum) ?? [])
      .sort((a, b) => (a ? (b ? a - b : -1) : 1));
    return segmentNums;
  };

  const segmentNums = getCurrentSelectedSegmentNums(parkingSpots);
  const firstAvailableSegmentNum = segmentNums
    .sort((a, b) => (a as number) - (b as number))
    .reduce<number>((acc, curr, i) => {
      if (acc === curr) return acc + 1;
      return acc;
    }, 0);
  return firstAvailableSegmentNum > 11
    ? undefined
    : firstAvailableSegmentNum < 0
      ? 0
      : firstAvailableSegmentNum;
};
