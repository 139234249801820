import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { ProjectNameDisplay } from './ProjectNameDisplay';
import { observer } from 'mobx-react-lite';
import { useProjects } from 'Utils/APIUtils';

interface ProjectSelectionInputProps {
  selectedProject: Project | null;
  setSelectedProject?: React.Dispatch<React.SetStateAction<Project | null>>;
  disabled: boolean;
}

export const ProjectSelectionFormInput: React.FC<ProjectSelectionInputProps> = observer(
  ({ selectedProject, setSelectedProject, disabled }) => {
    const projectStore = useAppStore().projectStore;
    const { data: projectsMap } = useProjects();
    const projects = [...(projectsMap || []).values()];
    const {
      register,
      formState: { errors },
    } = useFormContext();
    return (
      <Autocomplete
        disabled={disabled}
        freeSolo={disabled ? true : false} // to remove the down arrow
        sx={{ mb: 2 }}
        options={projects ?? []}
        getOptionLabel={(option) => (option as { name: string }).name}
        isOptionEqualToValue={(option: Project, value: Project) => option.id === value.id}
        defaultValue={selectedProject}
        renderOption={(props, option) => (
          <MenuItem key={`project-selection-form-input-${option.id}`} value={option.id}>
            <ProjectNameDisplay project={option} />
          </MenuItem>
        )}
        onChange={(e: any) => {
          const selectedProject = projectStore.projectIdToProjectMap.get(e.target.outerText);
          setSelectedProject?.(selectedProject ?? null);
        }}
        value={selectedProject}
        renderInput={(params) => (
          <TextField
            sx={disabled ? { ...disabledTextFieldStyle, mb: 2 } : {}}
            {...params}
            label='Project Name'
            fullWidth
            error={!!errors['projectName']}
            helperText={errors['projectName'] ? errors['projectName'].message?.toString() : ''}
            {...register('projectName')}
            value={String(selectedProject?.name || '')}
          />
        )}
      />
    );
  }
);
