import { useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { ApfcModelRefined } from '@electreon_ui/shared/types/globals';

import { ProjectSelectionFormInput } from '../ProjectSelectionInputs/ProjectSelectionFormInput';
import { DeviceNameInput } from './FormInputs/DeviceNameInput';
import { APFCSelectionInput } from './FormInputs/APFCSelectionInput';
import { AssignedMusSelection } from '../CreateDevicePopup/utils/AssignedMusSelection';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';

import { updateAPFCDevice } from 'Components/Forms/FormsAPIs/api';
import { EditAPFCInput, EditAPFCSchema } from './Utils/FormsSchemas';
import { FormLoading } from '../FormUtils/FormLoading';

interface EditAPFCFormProps extends FormProps {
  selectedProject?: Project | null;
}

export const EditAPFCForm: React.FC<EditAPFCFormProps> = ({
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
}) => {
  const { projectStore, deviceStore } = useAppStore();
  const [selectedApfc, setSelectedApfc] = useState<ApfcModelRefined | null>(
    projectStore?.apfcList[0] ?? null
  );
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const deviceChangeVersion = selectedApfc?.id
    ? deviceStore.versions?.[selectedApfc?.id]?.deviceChangesVersion
    : selectedApfc?.version;

  const methods = useForm<EditAPFCInput>({
    resolver: zodResolver(EditAPFCSchema),
    defaultValues: {
      deviceId: selectedApfc?.id || '',
      deviceName: selectedApfc?.name || '',
      projectName: selectedProject?.name,
      muIds: [],
    },
  });

  const onSubmitHandler: SubmitHandler<EditAPFCInput> = (values) => {
    setLoading(true);
    setSubmitError(null);
    const { deviceName, deviceId, muIds } = values;
    updateAPFCDevice(
      {
        deviceType: 'APFC',
        id: deviceId,
        name: deviceName,
        projectId: selectedProject?.id as number,
        deviceSubType: 'SIMATIC_S7_1200',
        timezone: selectedProject?.timezoneStr ?? 'UTC',
        version: deviceChangeVersion || selectedApfc?.version || 0,
      },
      muIds || []
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success('APFC device updated');
          methods.reset();
          const updatedApfc = res.data;
          const updatedApfcList = projectStore.apfcList.map((apfc) =>
            apfc.id === updatedApfc.id ? updatedApfc : apfc
          );
          projectStore.setApfcList(updatedApfcList);
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('APFC device update failed');
          setSubmitError('APFC Device update failed');
          throw new Error('Update APFC device failed');
        } else if (res.status === 409) {
          toast.error('APFC creation conflict');
          setSubmitError('APFC Creation conflict');
          throw new Error('Update APFC device failed');
        } else {
          toast.error('APFC device update failed');
          setSubmitError('APFC Device update failed');
          throw new Error('Update APFC device failed');
        }
      })
      .catch((err) => {
        toast.error('APFC device update failed');
        console.error('Update APFC device error', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit APFC
      </Typography>

      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
          console.error(e);
        })}
      >
        <ProjectSelectionFormInput selectedProject={selectedProject || null} disabled />
        <APFCSelectionInput selectedApfc={selectedApfc} setSelectedApfc={setSelectedApfc} />
        <DeviceNameInput currentDeviceName={selectedApfc?.name || ''} deviceType={'APFC'} />
        <AssignedMusSelection disabled={false} apfcId={selectedApfc?.id || ''} />
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormLoading open={loading} />
        <FormSubmitionButtons onCancel={onCancel} />
      </Box>
    </FormProvider>
  );
};
