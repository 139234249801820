import { useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { PosModelRefined } from '@electreon_ui/shared/types/globals';

import { EditPOSInput, EditPOSSchema } from './Utils/FormsSchemas';
import { ProjectSelectionFormInput } from '../ProjectSelectionInputs/ProjectSelectionFormInput';
import { AssignedVuSelectionInput } from './FormInputs/AssignedVuSelectionInput';
import { DeviceNameInput } from './FormInputs/DeviceNameInput';
import { POSSelectionInput } from './FormInputs/POSSelectionInput';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';

import { updatePOSDevice } from 'Components/Forms/FormsAPIs/api';
import { FormLoading } from '../FormUtils/FormLoading';

interface EditPOSFormProps extends FormProps {
  selectedProject?: Project | null;
}

export const EditPOSForm: React.FC<EditPOSFormProps> = ({
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
}) => {
  const { projectStore, deviceStore } = useAppStore();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPos, setSelectedPos] = useState<PosModelRefined | null>(projectStore?.posList[0] || null);

  const deviceChangeVersion = selectedPos?.id
    ? deviceStore.versions?.[selectedPos?.id]?.deviceChangesVersion
    : selectedPos?.version;

  const methods = useForm<EditPOSInput>({
    resolver: zodResolver(EditPOSSchema),
    defaultValues: {
      deviceId: selectedPos?.id || '',
      deviceName: selectedPos?.name || '',
      projectName: selectedProject?.name,
      vuId: selectedPos?.vuId || '',
    },
  });
  const onSubmitHandler: SubmitHandler<EditPOSInput> = (values) => {
    setLoading(true);
    setSubmitError(null);
    const { deviceName, deviceId, vuId } = values;
    updatePOSDevice({
      deviceType: 'POS',
      id: deviceId,
      name: deviceName,
      projectId: selectedProject?.id as number,
      vuId,
      deviceSubType: 'SIMATIC_S7_1200_POS',
      timezone: selectedProject?.timezoneStr ?? 'UTC',
      version: deviceChangeVersion || selectedPos?.version || 0,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success('POS device updated');
          methods.reset();
          const updatedPos = res.data;
          const updatedPosList = projectStore.posList.map((pos) =>
            pos.id === updatedPos.id ? updatedPos : pos
          );
          projectStore.setPosList(updatedPosList);
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('POS device update failed');
          setSubmitError('POS Device update failed');
          throw new Error('Update POS device failed');
        } else if (res.status === 409) {
          toast.error('POS creation conflict');
          setSubmitError('POS Creation conflict');
          throw new Error('Update POS device failed');
        } else {
          toast.error('POS device update failed');
          setSubmitError('POS Device update failed');
          throw new Error('Update POS device failed');
        }
      })
      .catch((err) => {
        toast.error('POS device update failed');
        console.error('Update POS device error', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit POS
      </Typography>

      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
          console.error(e);
        })}
      >
        <ProjectSelectionFormInput selectedProject={selectedProject || null} disabled />
        <POSSelectionInput selectedPos={selectedPos} setSelectedPos={setSelectedPos} />
        <DeviceNameInput currentDeviceName={selectedPos?.name || ''} deviceType={'POS'} />
        <AssignedVuSelectionInput selectedPosVuId={selectedPos?.vuId || ''} />
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormLoading open={loading} />
        <FormSubmitionButtons onCancel={onCancel} />
      </Box>
    </FormProvider>
  );
};
