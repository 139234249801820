import { useState } from 'react';
import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';

import { Project, ProjectDeployment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { OcppChargerMetadataModel } from '@electreon/electreon-ocpp-device-metadata-service-gen-ts-client';

import { ProjectSelectionFormInput } from 'Components/Forms/ProjectSelectionInputs/ProjectSelectionFormInput';
import { DeviceNameInput } from 'Components/Forms/EditAPFC&POSForms/FormInputs/DeviceNameInput';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { FormLoading } from 'Components/Forms/FormUtils/FormLoading';
import { EditOCPPInput, EditOCPPSchema } from 'Components/Forms/EditAPFC&POSForms/Utils/FormsSchemas';
import { updateOCPPDevice } from 'Components/Forms/FormsAPIs/api';
import { OCPPSelectionInput } from 'Components/Forms/EditAPFC&POSForms/FormInputs/OCPPSelectionInput';
import { editMuMutation } from 'Components/Forms/EditDeviceForm/utils';

interface EditOCPPFormProps extends FormProps {
  selectedProject?: Project | null;
  selectedDevice?: OcppChargerModelRefined;
}

export const EditOCPPForm: React.FC<EditOCPPFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject, selectedDevice }) => {
    const { projectStore, deviceStore, queryClient } = useAppStore();
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const deviceChangeVersion = selectedDevice?.id
      ? deviceStore.versions?.[selectedDevice?.id]?.deviceChangesVersion
      : selectedDevice?.version;

    const mutation = useMutation({
      mutationFn: (device: OcppChargerMetadataModel) => updateOCPPDevice(device),
      onMutate: (device) => {
        queryClient.setQueryData(['projectDeployment', +selectedProject?.id!], (oldData: ProjectDeployment) =>
          editMuMutation(oldData, device)
        );
      },
      onSuccess: (res) => {
        if (res.status === 200) {
          toast.success('OCPP device updated');
          methods.reset();
          const updatedOcpp = res.data;
          const updatedOcppList = projectStore.ocppList.map((ocpp) =>
            ocpp.id === updatedOcpp.id ? updatedOcpp : ocpp
          );
          projectStore.setOcppList(updatedOcppList);
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('OCPP device update failed');
          setSubmitError('OCPP Device update failed');
          throw new Error('Update OCPP device failed');
        } else if (res.status === 409) {
          toast.error('OCPP creation conflict');
          setSubmitError('OCPP Creation conflict');
          throw new Error('Update OCPP device failed');
        } else {
          toast.error('OCPP device update failed');
          setSubmitError('OCPP Device update failed');
          throw new Error('Update OCPP device failed');
        }
      },
      onError: (err) => {
        toast.error('OCPP device update failed');
        console.error('Update OCPP device error', JSON.stringify(err));
      },
    });

    const methods = useForm<EditOCPPInput>({
      resolver: zodResolver(EditOCPPSchema),
      defaultValues: {
        deviceId: selectedDevice?.id || '',
        deviceName: selectedDevice?.name || '',
        projectName: selectedProject?.name,
        longitude: String(selectedDevice?.longitude),
        latitude: String(selectedDevice?.latitude),
      },
    });

    const errors = methods?.formState?.errors;

    const onSubmitHandler: SubmitHandler<EditOCPPInput> = (values) => {
      setLoading(true);
      setSubmitError(null);
      const { deviceName, deviceId, longitude, latitude } = values;

      mutation.mutate(
        {
          ...selectedDevice,
          id: deviceId,
          name: deviceName,
          projectId: selectedProject?.id as number,
          version: deviceChangeVersion || selectedDevice?.version || 0,
          longitude: Number(longitude) || selectedDevice?.longitude || 0,
          latitude: Number(latitude) || selectedDevice?.latitude || 0,
          timezone: selectedProject?.timezoneStr || 'Asia/Jerusalem',
        },
        {
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    };

    return (
      <FormProvider {...methods}>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Edit OCPP
        </Typography>

        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
            console.error(e);
          })}
        >
          <ProjectSelectionFormInput selectedProject={selectedProject || null} disabled />
          <OCPPSelectionInput selectedOcpp={selectedDevice} disabled={!!selectedDevice} />
          <DeviceNameInput currentDeviceName={selectedDevice?.name || ''} deviceType={'OCPP'} />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...methods.register('longitude')}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...methods.register('latitude')}
            />
          </Stack>
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormLoading open={loading} />
          <FormSubmitionButtons onCancel={onCancel} />
        </Box>
      </FormProvider>
    );
  }
);

EditOCPPForm.displayName = 'EditOCPPForm';
