import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@repo/ui/components/ui/alertDialog';
import { buttonVariants } from '@repo/ui/components/ui/button';
import { cn } from '@repo/ui/lib/utils';

type ConfirmationDialogProps<T> = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  confirmTitle?: React.ReactNode;
  confirmMessage?: React.ReactNode;
  actionContext?: T;
  action: (actionContext?: T) => void;
  isDestructive?: boolean;
};

export const ConfirmationDialog = <T,>({
  action,
  actionContext,
  confirmMessage,
  confirmTitle,
  isOpen,
  setIsOpen,
  isDestructive,
}: ConfirmationDialogProps<T>): JSX.Element => {
  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className='font-[urbanist] px-4 py-4'>
        <AlertDialogHeader>
          <AlertDialogTitle className='m-0'>{confirmTitle}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>{confirmMessage}</AlertDialogDescription>
        <AlertDialogFooter className='mt-0'>
          <AlertDialogCancel
            className={cn('cursor-pointer', buttonVariants({ variant: 'outline' }))}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            className={cn(
              'cursor-pointer',
              buttonVariants({ variant: isDestructive ? 'destructive' : 'outline' })
            )}
            onClick={() => {
              action(actionContext);
              setIsOpen(false);
            }}
          >
            Confirm
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

ConfirmationDialog.displayName = 'ConfirmationDialog';
