import { isValidStatusNotificationRequestV16 } from '@cshil/ocpp-tools/validation/v16';
import { OcppChargerModelRefined } from '../../types/globals';
import { LockInfo } from '../../types/socketMessageTypes';
import { parseOCPPMeterMesage } from '../../utils/ocppUtils';
import ProjectStore from './ProjectStore';
import { removeNullValues } from '../../utils/globalUtils';

const METADATA_STALE_TIME = 1000 * 5;

export function getProjectMetadata(selectedProjectId: number, store: ProjectStore) {
  const queryClient = store.rootStore.queryClient;
  const api = store.rootStore.api;
  const vuPromise = queryClient?.fetchQuery({
    queryKey: ['vuList'],
    queryFn: () => api.deviceMetadata.vu.getDevices(selectedProjectId),
    staleTime: METADATA_STALE_TIME,
  });
  const muPromise = queryClient?.fetchQuery({
    queryKey: ['muList'],
    queryFn: () => api.deviceMetadata.mu.getProjectManagementUnits(selectedProjectId),
    staleTime: METADATA_STALE_TIME,
  });
  const posPromise = queryClient?.fetchQuery({
    queryKey: ['posList'],
    queryFn: () => api.deviceMetadata.pos.getProjectPosUnits(selectedProjectId),
    staleTime: METADATA_STALE_TIME,
  });
  const apfcPromise = queryClient?.fetchQuery({
    queryKey: ['apfcList'],
    queryFn: () => api.deviceMetadata.apfc.getProjectApfcUnits(selectedProjectId),
    staleTime: METADATA_STALE_TIME,
  });
  const ocppPromise = queryClient?.fetchQuery({
    queryKey: ['ocppList'],
    queryFn: () => api.ocppChargersMetadata.ocpp.getProjectOcppChargers(selectedProjectId),
    staleTime: METADATA_STALE_TIME,
  });

  return Promise.allSettled([muPromise, vuPromise, posPromise, apfcPromise, ocppPromise])
    .then((results) => {
      const muList = results[0].status === 'fulfilled' ? (results[0].value?.data?.devices ?? []) : [];
      const vuList = results[1].status === 'fulfilled' ? (results[1].value?.data?.devices ?? []) : [];
      const posList = results[2].status === 'fulfilled' ? (results[2].value?.data?.devices ?? []) : [];
      const apfcList = results[3].status === 'fulfilled' ? (results[3].value?.data?.devices ?? []) : [];
      const ocppList = (
        results[4].status === 'fulfilled' ? (results[4].value?.data ?? []) : []
      ) as OcppChargerModelRefined[];

      // Set locked devices
      [muList, vuList, posList, apfcList].forEach((deviceList) => {
        deviceList.forEach((device) => {
          const sessionId = store.rootStore.userStore?.userData?.sub;
          if (device.lockInfo && device.id)
            store.rootStore.deviceLockStore?.addLockedDevice(
              device.id,
              device.lockInfo as LockInfo,
              sessionId
            );
        });
      });

      // Set ocpp states
      const noNullsOcppList = removeNullValues(ocppList);
      noNullsOcppList.forEach((device) => {
        if (device.id) {
          const chargerId = device.id;

          // Set connection state
          store.rootStore.ocppStore.setOCPPConnectionState(
            chargerId,
            device.connectionStatus === 'CONNECTED'
          );

          // Set connectors charging states
          for (const connectorId in device.statusNotification) {
            const statusNotification = device.statusNotification[connectorId];
            if (isValidStatusNotificationRequestV16(statusNotification)) {
              store.rootStore.ocppStore.setConnectorStatus(
                chargerId,
                Number(connectorId),
                statusNotification
              );
              if (statusNotification.status === 'Charging')
                store.rootStore.ocppStore.setLiveChargingState(chargerId, Number(connectorId), true);
            } else {
              console.error(
                `Invalid statusNotificationMessage for charger ${chargerId} and connector ${connectorId}`
              );
            }
          }

          // Set meter values
          if (device.meterValues) {
            Object.values(device.meterValues).forEach((meterValues) => {
              store.rootStore.ocppStore.setOCPPMeterValues(
                chargerId,
                parseOCPPMeterMesage({ data: meterValues, properties: {} })
              );
            });
          }
        }
      });

      store.setMuList(muList);
      store.setVuList(vuList);
      store.setPosList(posList);
      store.setApfcList(apfcList);
      store.setOcppList(noNullsOcppList);
    })
    .catch((error) => {
      console.error('Error while fetching muList and vuList', error);
    });
}
