import { ProjectDeployment, ParkingSpot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { ManagementUnitModelRefined } from '@electreon_ui/shared/types/globals';

export const manageParkingSpotsMutation = (
  oldData: ProjectDeployment,
  depotId: number,
  muId: string,
  parkingSpotsWithMuAndDepotId: ParkingSpot[],
  selectedDevice: ManagementUnitModelRefined
) => {
  if (oldData) {
    const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
      if (depot.id === depotId) {
        selectedDevice.depotId = depotId;
        return {
          ...depot,
          managementUnits: [
            ...(depot.managementUnits || []),
            { managementUnit: selectedDevice, parkingSpots: parkingSpotsWithMuAndDepotId },
          ],
        };
      }
      return depot;
    });

    return {
      ...oldData,
      unassignedUnits: oldData?.unassignedUnits?.filter((unit) => unit.id !== muId),
      staticDeployment: {
        depots: updatedStaticDeployment,
      },
    };
  }
};
