import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { PosModelRefined } from '@electreon_ui/shared/types/globals';

interface POSSelectionInputProps {
  selectedPos: PosModelRefined | null;
  setSelectedPos: React.Dispatch<React.SetStateAction<PosModelRefined | null>>;
  disabled?: boolean;
}

export const POSSelectionInput: React.FC<POSSelectionInputProps> = ({ selectedPos, setSelectedPos, disabled }) => {
  const { projectStore } = useAppStore();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Autocomplete
      disabled={disabled}
      sx={{ mb: 2 }}
      options={projectStore?.posList ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option as { id: string }).id}
      defaultValue={selectedPos}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          {option.id}
        </MenuItem>
      )}
      onChange={(e: any) => {
        const selectedPos = projectStore?.posList?.find((pos) => pos.id === e.target.outerText);
        selectedPos && setSelectedPos(selectedPos);
      }}
      value={selectedPos}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Device ID'
          fullWidth
          error={!!errors['deviceId']}
          helperText={errors['deviceId'] ? errors['deviceId'].message?.toString() : ''}
          {...register('deviceId')}
          value={String(selectedPos?.id || '')}
        />
      )}
    />
  );
};
