import { ConfigureMuShadowForm } from 'Components/Forms/ConfigureShadowForm/ConfigureMuShadowForm';
import { ConfigureVuShadowForm } from 'Components/Forms/ConfigureShadowForm/ConfigureVuShadowForm';
import { observer } from 'mobx-react-lite';
import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';
import { isMu, isOcpp, isVu } from '@electreon_ui/shared/utils/globalUtils';
import PopupStore from '@electreon_ui/shared/stores/popupStore/PopupStore';
import { useAppStore } from 'MobxStores/context';

export const ConfigureDeviceForm: React.FC<{
  selectedDevice: AbstractIoTDeviceModelRefined;
  popupStore: PopupStore;
}> = observer(({ selectedDevice, popupStore }) => {
  if (isOcpp(selectedDevice)) return null;
  const { projectStore, queryClient } = useAppStore();

  return (
    <>
      {isMu(selectedDevice) && (
        <ConfigureMuShadowForm
          device={selectedDevice}
          onSuccessfulSubmit={() => {
            popupStore.closeConfigureDevicePopup();
            queryClient.invalidateQueries({
              queryKey: ['projectDeployment', +projectStore?.selectedProject?.id!],
            })
          }}
          onCancel={() => {
            popupStore.closeConfigureDevicePopup();
          }}
        />
      )}
      {isVu(selectedDevice) && (
        <ConfigureVuShadowForm
          device={selectedDevice}
          onSuccessfulSubmit={() => {
            popupStore.closeConfigureDevicePopup();
            queryClient.invalidateQueries({
              queryKey: ['fleets', +projectStore?.selectedProject?.id!],
            })
          }}
          onCancel={() => {
            popupStore.closeConfigureDevicePopup();
          }}
        />
      )}
    </>
  );
});
ConfigureDeviceForm.displayName = 'ConfigureDeviceForm';
