import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { UserRole } from '@electreon_ui/shared/constants/constants';

export const ManageUsers = observer(() => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const selectedProjectDisabled = false;
  const { popupStore, userStore } = useAppStore();
  const currentUserRole = userStore.userData?.role;
  return (
    <Stack width='90%' margin='0 auto'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 60,
          marginBlock: `17px`,
        }}
      >
        <Typography sx={{ py: 2, textAlign: 'left' }} variant='projectsMainDashboardHeader'>
          Manage Users
        </Typography>
      </Box>
      <Divider />
      <Stack width='800px'>
        <Stack direction='row' justifyContent='space-between'>
          {/* <Stack direction='column' width='400px'>
            <ProjectSelectionInput
              setSelectedProject={setSelectedProject}
              disabled={selectedProjectDisabled}
            />
          </Stack> */}
          <Stack direction='column' width='400px'>
            <Button
              sx={{
                width: '200px',
                padding: 1,
                mt: 2,
                mb: 1,
                border: `1px ${theme.palette.accent.gray.light} solid`,
              }}
              onClick={(e) => {
                popupStore.openInviteUserPopup(selectedProject?.id);
              }}
            >
              Invite a new user
            </Button>
            <Button
              sx={{
                width: '200px',
                padding: 1,
                mt: 1,
                mb: 2,
                border: `1px ${theme.palette.accent.gray.light} solid`,
              }}
              onClick={(e) => {
                popupStore.openUpdateUserPopup();
              }}
            >
              Change user authority
            </Button>
            <Button
              sx={{
                width: '200px',
                padding: 1,
                mt: 1,
                mb: 2,
                border: `1px ${theme.palette.accent.gray.light} solid`,
              }}
              onClick={(e) => {
                popupStore.openUserAccountActivationPopup();
              }}
              disabled={currentUserRole !== UserRole.SUPER_ADMIN}
            >
              Disable/Enable User Account
            </Button>
            <Button
              sx={{
                width: '200px',
                padding: 1,
                mt: 1,
                mb: 2,
                border: `1px ${theme.palette.accent.gray.light} solid`,
                ':hover': {
                  color: 'red',
                },
              }}
              onClick={(e) => {
                popupStore.openDeleteUserPopup();
              }}
              disabled={currentUserRole !== UserRole.SUPER_ADMIN}
            >
              Delete User Account
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
