import { Alert, Box, Container, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { CreateRoadInput, CreateRoadSchema, roadDefaultValues } from './utils/roadSchemas';
import { RoadNameInput } from './RoadFormsInputs/RoadNameInput';
import { RoadTotalLengthInput } from './RoadFormsInputs/RoadTotalLengthInput';
import { createRoad } from '../FormsAPIs/api';
import { toast } from 'sonner';

export const CreateRoadForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);

    const methods = useForm<CreateRoadInput>({
      resolver: zodResolver(CreateRoadSchema),
      defaultValues: roadDefaultValues,
    });
    const onSubmitHandler: SubmitHandler<CreateRoadInput> = (values) => {
      setSubmitError(null);
      const { roadName, totalLengthKm, geoPath } = values;
      createRoad({
        name: roadName,
        totalLengthKm: totalLengthKm,
        geoPath,
        projectId: selectedProject?.id as number,
      })
        .then((res) => {
          if (res.status === 201) {
            toast.success('Road Created');
            methods.reset(roadDefaultValues);
            onSuccessfulSubmit?.();
          } else {
            toast.error('Road creation failed');
            setSubmitError('Road creation failed');
            throw new Error('Create Road Failed');
          }
        })
        .catch((error) => {
          toast.error('Road creation failed');
          setSubmitError('Something went wrong');
          console.error('Create Road error', JSON.stringify(error));
        });
    };
    return (
      <FormProvider {...methods}>
        <Container disableGutters>
          <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
            Create Road
          </Typography>

          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <SelectProject selectedProject={selectedProject} errors={methods.formState.errors} />
            <RoadNameInput currentRoadName='' />
            <RoadTotalLengthInput />
            {submitError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}
            <FormSubmitionButtons onCancel={onCancel} submitLabel='Create Road' />
          </Box>
        </Container>
      </FormProvider>
    );
  }
);
