import { Alert, Autocomplete, Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import timezones from 'Utils/DefaultValues/timezones.json';
import { isoCountries } from 'Utils/DefaultValues/countries';
import { api } from 'Services/api';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { collectImageFiles } from 'Components/Forms/ProjectForms/utils/projectFormUtils';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { toast } from 'sonner';
import { isValid } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

const editProjectSchema = object({
  id: string().optional(),
  name: string()
    .min(1, 'Project name is required')
    .regex(/[A-Za-z0-9]/, 'Project name can only contain letters and numbers'),
  countryCode: string().min(1, 'Country code is required'),
  timezoneStr: string()
    .min(1, 'Timezone is required')
    .refine((value) => {
      return isValid(utcToZonedTime(new Date(), value));
    }, 'Timezone is invalid'),
});

type EditProjectInput = TypeOf<typeof editProjectSchema>;

export const EditProjectForm: React.FC<FormProps & { project: Project }> = ({
  onSuccessfulSubmit,
  onCancel,
  project,
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    setError,
    handleSubmit,
  } = useForm<EditProjectInput>({
    resolver: zodResolver(editProjectSchema),
    defaultValues: {
      id: String(project.id),
      name: project.name,
      countryCode: project.countryCode,
      timezoneStr: project.timezoneStr,
    },
  });

  const [logoSmall, setLogoSmall] = useState<File | null>(null);
  const [logoMain, setLogoMain] = useState<File | null>(null);
  const [cardImage, setCardImage] = useState<File | null>(null);

  const handleCardImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCardImage(e.target.files[0]);
    }
  };

  const handleLogoSmallChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogoSmall(e.target.files[0]);
    }
  };

  const handleLogoMainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogoMain(e.target.files[0]);
    }
  };

  const onSubmitHandler: SubmitHandler<EditProjectInput> = (values) => {
    setSubmitError(null);

    const countryName = values.countryCode.split('/')[0].trim();
    const alpha3code = isoCountries.find(
      (country) => country.country === countryName || country.alpha3 === countryName
    )?.alpha3;

    if (!alpha3code) {
      setError('countryCode', {
        type: 'manual',
        message: 'Country code is invalid',
      });
      return;
    }

    api.metadata.projects
      .updateProject({
        countryCode: alpha3code,
        id: project.id,
        name: values.name,
        timezoneStr: values.timezoneStr,
        managementUnits: project.managementUnits,
        vehicleUnits: project.vehicleUnits,
        version: project.version,
      })
      .then((res) => {
        if (res.data.id) {
          toast.success('Project updated');

          // send images to s3
          if (logoSmall || logoMain || cardImage)
            api.metadata.projects
              .updateProjectImages(res.data.id, collectImageFiles({ logoSmall, logoMain, cardImage }))
              .catch((err) => {
                toast.error(`Updating project images failed`);
                console.error('Updating project images failed', JSON.stringify(err));
              });

          reset();
          onSuccessfulSubmit?.();
        } else {
          toast.error('Edit project failed');
          setSubmitError('Edit project failed');
          throw new Error('Edit project failed');
        }
      })
      .catch((err) => {
        toast.error('Edit project failed');
        console.error('Edit project failed', JSON.stringify(err));
      });
  };

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit Project
      </Typography>

      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <SelectProject selectedProject={project} errors={errors} />
        <Autocomplete
          fullWidth
          options={isoCountries}
          defaultValue={isoCountries.find((country) => country.alpha3 === project.countryCode)}
          sx={{ mb: 2 }}
          getOptionLabel={(option) => `${option.country} / (${option.alpha3})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Country Code'
              error={!!errors['countryCode']}
              helperText={errors['countryCode'] ? errors['countryCode'].message : ''}
              {...register('countryCode')}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={timezones}
          defaultValue={project.timezoneStr}
          sx={{ mb: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Timezone'
              error={!!errors['timezoneStr']}
              helperText={errors['timezoneStr'] ? errors['timezoneStr'].message : ''}
              {...register('timezoneStr')}
            />
          )}
        />
        <Stack direction='row' spacing={2} sx={{ mb: 1 }}>
          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: logoSmall ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Small Logo</Typography>
            <input type='file' hidden onChange={handleLogoSmallChange} />
          </Button>

          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: logoMain ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Main Logo</Typography>
            <input type='file' hidden onChange={handleLogoMainChange} />
          </Button>

          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: cardImage ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Project Card</Typography>
            <input type='file' hidden onChange={handleCardImageChange} />
          </Button>
        </Stack>
        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {logoSmall?.name || 'No file selected'}
          </span>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {logoMain?.name || 'No file selected'}
          </span>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {cardImage?.name || 'No file selected'}
          </span>
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons onCancel={onCancel} />
      </Box>
    </>
  );
};
EditProjectForm.displayName = 'EditProjectForm';
