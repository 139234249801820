import { memo } from 'react';
import { Button } from '@repo/ui/components/ui/button';
import { Check } from 'lucide-react';
import { cn } from '@repo/ui/lib/utils';

type ButtonsVariant = 'default' | 'destructive' | 'brand';

const colorsSchema: Record<ButtonsVariant, Record<string, string>> = {
  default: {
    button: 'bg-gray-500 hover:bg-gray-500',
    value: 'text-[#EEEEEE]',
  },
  destructive: {
    button: 'bg-green-100 hover:bg-green-100',
    value: 'text-gray-dark',
  },
  brand: { button: 'bg-blue-100 hover:bg-blue-100', value: 'text-gray-dark' },
};

type ButtonGroupProps<T extends string | number | null> = {
  variant?: ButtonsVariant;
  options:
    | Array<{ displayValue: string; value: T; props?: React.HTMLAttributes<HTMLButtonElement> }>
    | ReadonlyArray<{ displayValue: string; value: T; props?: React.HTMLAttributes<HTMLButtonElement> }>;
  activeValue?: T;
  onClick: (val: T) => void;
  showCheckIcon?: boolean;
  disabled?: boolean;
  className?: string;
  activeButtonClassName?: string;
  title?: string;
};

const ButtonGroupComponent = <T extends string | number | null>({
  variant = 'default',
  options,
  activeValue,
  onClick,
  showCheckIcon = true,
  className,
  activeButtonClassName,
  disabled,
  title,
  ...props
}: ButtonGroupProps<T>) => {
  return (
    <div
      className={cn(
        'inline-flex rounded-sm border-none shadow-3xl h-[28px]',
        disabled && 'shadow-transparent/20',
        className
      )}
    >
      {options.map((button, index) => {
        const isActive = activeValue === button.value;
        return (
          <Button
            key={index}
            variant='outline'
            onClick={() => {
              onClick(button.value);
            }}
            disabled={disabled}
            className={cn(
              'first:rounded-l-sm last:rounded-r-sm rounded-none hover:opacity-80',
              'w-full h-full p-0',
              'active:bg-[#c4c4c4] transition ease delay-30',
              isActive && colorsSchema[variant].button,
              isActive && activeButtonClassName
            )}
            aria-pressed={isActive}
            title={title}
            {...props}
            {...button.props}
          >
            {showCheckIcon && isActive && (
              <Check className={cn('mr-1.5 h-3.5 w-3.5', colorsSchema[variant].value)} />
            )}
            <span
              className={cn(
                isActive && colorsSchema[variant].value,
                // !isActive && disabled && variant === 'destructive' && 'text-black/30',
                '!text-[1.3ch]'
              )}
            >
              {button.displayValue}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

export const ButtonGroup = memo(ButtonGroupComponent) as typeof ButtonGroupComponent;
