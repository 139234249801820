import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Avatar } from '@mui/material';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Functionalities, WithPermission } from '@electreon_ui/shared/permissions';
import { AccessLevels } from '@electreon_ui/shared/permissions/types/permissionsTypes';
import { UiDevice } from '@electreon_ui/shared/types/globals';
import { toast } from 'sonner';
import { isFilteredByUser } from '@electreon_ui/shared/utils/privateProjectUtils';

//Hooks
import { useAppStore } from 'MobxStores/context';
import { useSearch } from 'Components/CommandMenu/useSearch';

//Utils
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Command,
} from '@repo/ui/components/ui/command';
import { hasDashboard } from 'dashboardConfig';
import { useProjects } from 'Utils/APIUtils';
import { useProjectLogo } from 'Utils/ProjectUtils';
import { isVu } from '@electreon_ui/shared/utils/globalUtils';
import { env } from 'config';

export const CommandMenu = observer(
  ({
    searchMenuOpen,
    setSearchMenuOpen,
    onOpen,
  }: {
    searchMenuOpen: boolean;
    setSearchMenuOpen: (isOpen: boolean) => void;
    onOpen?: () => void;
  }) => {
    const { data: projectsMap } = useProjects();
    const { projectStore, userStore } = useAppStore();
    const navigate = useNavigate();
    const [page, setPage] = useState<string | null>(null);
    const [search, setSearch] = useState<string | null>(null);
    const [depotProject, setDepotProject] = useState<number | null>(null);
    const [selectedProjectId, setSelectedProjectId] = useState<undefined | number>(undefined);
    const { searchDevices, setSearchDevices } = useSearch({
      page,
      search,
    });

    const projects = useMemo(
      () =>
        [...(projectsMap || []).values()].filter(
          (project) => !isFilteredByUser(project.id, userStore.userData!, env)
        ),
      [projectsMap, userStore.userData]
    );

    const projectsWithDashboard = useMemo(
      () => projects.filter((project) => hasDashboard(project.id)),
      [projects]
    );

    const projectWithDepotData = projectsMap?.get(depotProject || -1);
    const isDashboardPage = page === 'Dashboard';
    const isDepotPage = page === 'Depot';
    const isProjectPage = page === 'Projects';

    useEffect(() => {
      const down = (e: KeyboardEvent) => {
        if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
          onOpen?.();
          e.preventDefault();
          setSearchMenuOpen(!searchMenuOpen);
        }
      };
      document.addEventListener('keydown', down);
      return () => document.removeEventListener('keydown', down);
    }, [searchMenuOpen, setSearchMenuOpen, onOpen]);

    const clear = () => {
      setPage(null);
      setDepotProject(null);
      setSearch(null);
      setSearchMenuOpen(false);
      setSearchDevices(null);
      setSelectedProjectId(undefined);
    };

    const redirectFromProjectSelection = (page: string, selectedProjectId: number) => {
      const project = projectStore?.projectIdToProjectMap.get(selectedProjectId) || null;
      page !== 'Depot' && page !== 'Dashboard' && projectStore.setSelectedProject(project);
      switch (page) {
        case 'Analytics':
          navigate(`/dashboard/history/project/${selectedProjectId}`);
          clear();
          break;
        case 'Integration':
          navigate(`/dashboard/integration/${selectedProjectId}`);
          clear();
          break;
        case 'Reports':
          navigate(`/project/${selectedProjectId}/reports`);
          clear();
          break;
        case 'Depot':
          selectedProjectId && setDepotProject(selectedProjectId);
          setPage(page);
          setSearch(null);
          break;
        case 'Dashboard':
          if (hasDashboard(selectedProjectId)) {
            projectStore.setSelectedProject(project);
            navigate(`/dashboard/live/project/${selectedProjectId}`);
            window.location.reload();
            clear();
          } else {
            toast.error(`Dashboard doesn't exist for selected project`);
          }
          break;
        default:
          break;
      }
    };

    const setSelectedPage = (v: string) => {
      if (selectedProjectId) {
        redirectFromProjectSelection(v, selectedProjectId);
      } else {
        setPage(v);
        setSearch(null);
      }
    };

    return (
      <CommandDialog open={searchMenuOpen} onOpenChange={setSearchMenuOpen}>
        <Command
          filter={(value, search, words) => {
            if (value.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || words?.length) return 1;
            return 0;
          }}
        >
          <CommandInput
            placeholder='Type a command or search...'
            value={search ?? ''}
            onValueChange={setSearch}
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup heading='Suggestions'>
              {(!page || isProjectPage) && (
                <>
                  {isProjectPage && (
                    <CommandItem
                      onSelect={() => {
                        setSelectedProjectId(undefined);
                        setSearch(null);
                        setPage(null);
                      }}
                    >
                      Back
                    </CommandItem>
                  )}
                  <WithPermission for={Functionalities.INTEGRATION} userData={userStore.userData}>
                    <CommandItem onSelect={setSelectedPage}>Integration</CommandItem>
                  </WithPermission>
                  <WithPermission for={Functionalities.DEPOT} userData={userStore.userData}>
                    <CommandItem onSelect={setSelectedPage}>Depot</CommandItem>
                  </WithPermission>
                  <WithPermission
                    for={Functionalities.ANALYTICS}
                    userData={userStore.userData}
                    override={userStore.isCompanyUser ? AccessLevels.ALLOWED : undefined}
                  >
                    <CommandItem onSelect={setSelectedPage}>Analytics</CommandItem>
                  </WithPermission>
                  <WithPermission
                    for={Functionalities.REPORTS}
                    userData={userStore.userData}
                    override={userStore.isCompanyUser ? AccessLevels.ALLOWED : undefined}
                  >
                    <CommandItem onSelect={setSelectedPage}>Reports</CommandItem>
                  </WithPermission>
                  <CommandItem onSelect={setSelectedPage}>Dashboard</CommandItem>
                  <WithPermission for={Functionalities.INTEGRATION} userData={userStore.userData}>
                    {searchDevices
                      ?.filter((device) => !isFilteredByUser(device.projectId, userStore.userData!, env))
                      .map((device) => <DeviceCommandItem key={device.id} device={device} clear={clear} />)}
                  </WithPermission>
                  {!selectedProjectId &&
                    search &&
                    projects.map((project) => {
                      if (
                        !String(project?.id).includes(search) &&
                        !project?.name?.toLowerCase().includes(search)
                      ) {
                        return null;
                      }
                      return (
                        <CommandItem
                          key={project.id}
                          onSelect={() => {
                            setSelectedProjectId(project.id);
                            setSelectedPage('Projects');
                          }}
                          forceMount={false}
                          value={project.name}
                          keywords={['noFilter']}
                        >
                          <ProjectNameWithAvatar project={project} />
                        </CommandItem>
                      );
                    })}
                </>
              )}
              {page && !depotProject && !isDashboardPage && !isProjectPage && (
                <>
                  <CommandItem
                    onSelect={() => {
                      setPage(null);
                      setSearch(null);
                    }}
                  >
                    Back
                  </CommandItem>
                  {projects.map((project) => (
                    <CommandItem
                      key={project.id}
                      onSelect={() => {
                        !isDepotPage && projectStore.setSelectedProject(project);
                        switch (page) {
                          case 'Analytics':
                            navigate(`/dashboard/history/project/${project.id}`);
                            clear();
                            break;
                          case 'Integration':
                            navigate(`/dashboard/integration/${project.id}`);
                            clear();
                            break;
                          case 'Reports':
                            navigate(`/project/${project.id}/reports`);
                            clear();
                            break;
                          case 'Depot':
                            project.id && setDepotProject(project.id);
                            setSearch(null);
                            break;
                          default:
                            break;
                        }
                        // }
                      }}
                    >
                      <ProjectNameWithAvatar project={project} />
                    </CommandItem>
                  ))}
                </>
              )}
              {depotProject && (
                // select depot id
                <>
                  <CommandItem
                    onSelect={() => {
                      if (selectedProjectId) {
                        setPage('Projects');
                      } else {
                        setPage('Depot');
                      }
                      setDepotProject(null);
                      setSearch(null);
                    }}
                  >
                    Back
                  </CommandItem>
                  {projectWithDepotData?.depots?.map((depot) => (
                    <CommandItem
                      key={depot.id}
                      onSelect={() => {
                        navigate(`/dashboard/live/depot/${depotProject}/${depot.id}`);
                        projectStore.setSelectedProject(projectWithDepotData);
                        clear();
                      }}
                    >
                      {depot.id} - {depot.name}
                    </CommandItem>
                  ))}
                </>
              )}
              {isDashboardPage && (
                // filtered project array with dashboards
                <>
                  <CommandItem
                    onSelect={() => {
                      setPage(null);
                      setSearch(null);
                    }}
                  >
                    Back
                  </CommandItem>
                  {projectsWithDashboard?.map((project) => (
                    <CommandItem
                      key={project.id}
                      onSelect={() => {
                        projectStore.setSelectedProject(project);
                        navigate(`/dashboard/live/project/${project.id}`);
                        // workaround for issue with dashboard not correctly updating to the new project id
                        window.location.reload();
                        clear();
                      }}
                    >
                      <ProjectNameWithAvatar project={project} />
                    </CommandItem>
                  ))}
                </>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </CommandDialog>
    );
  }
);
CommandMenu.displayName = 'CommandMenu';

const ProjectNameWithAvatar = ({ project }: { project: Project }) => {
  return <ItemWithProjectLogo project={project} itemName={`${project.id} - ${project.name}`} />;
};

const DeviceCommandItem = observer(({ device, clear }: { device: UiDevice; clear: () => void }) => {
  const { projectStore } = useAppStore();
  const navigate = useNavigate();

  const handleDeviceRedirect = (device: UiDevice, project?: Project) => {
    if (!project) return;
    projectStore.setSelectedProject(project);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(isVu(device) ? 'vu' : 'charger', device?.id || '');
    navigate(`/dashboard/integration/${device.projectId}?${searchParams.toString()}`);
    clear();
  };

  const project = projectStore.projectIdToProjectMap.get(device.projectId!);

  return (
    <CommandItem
      key={device.id}
      onSelect={() => handleDeviceRedirect(device, project)}
      forceMount={false}
      value={device.name}
      keywords={['noFilter']}
    >
      <ItemWithProjectLogo project={project!} itemName={`${device.id} - ${device.name}`} />
    </CommandItem>
  );
});

const ProjectLogo: React.FC<{ project: Project }> = observer(({ project }) => {
  const { data: projectLogo } = useProjectLogo(String(project?.id), 'small');
  if (!projectLogo) return null;
  return (
    <Avatar
      alt='project logo'
      src={projectLogo}
      sx={{
        width: '25px',
        height: '25px',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          backgroundColor: 'transparent',
        },
      }}
    />
  );
});

const ItemWithProjectLogo: React.FC<{
  project: Project;
  itemName: string;
}> = ({ project, itemName }) => {
  return (
    <div className='flex flex-row items-center gap-4'>
      <ProjectLogo project={project} />
      {itemName}
    </div>
  );
};
