import { ProjectDeployment, ProjectFleets } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  ManagementUnitModel,
  VehicleUnitModel,
} from '@electreon/electreon-device-metadata-service-gen-ts-client';

export const editMuMutation = (oldData: ProjectDeployment, device: ManagementUnitModel) => {
  if (oldData) {
    if (device.deviceType === 'OCPP') {
      const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
        if (depot.id === device.depotId) {
          const updatedOcppChargers = depot?.ocppChargers?.map((charger) => {
            if (charger?.ocppCharger?.id === device.id) {
              return {
                ...charger,
                ocppCharger: device,
              };
            }
            return charger;
          });
          return {
            ...depot,
            ocppChargers: updatedOcppChargers,
          };
        }
        return depot;
      });
      const updatedUnassignedOcpps = oldData?.unassignedOcppChargers?.map((ocpp) =>
        ocpp.id === device.id ? device : ocpp
      );

      return {
        ...oldData,
        staticDeployment: {
          depots: updatedStaticDeployment,
        },
        unassignedOcppChargers: updatedUnassignedOcpps,
      };
    } else {
      const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
        if (depot.id === device.depotId) {
          const updatedManagementUnits = depot?.managementUnits?.map((unit) => {
            if (unit?.managementUnit?.id === device.id) {
              return {
                ...unit,
                managementUnit: device,
              };
            }
            return unit;
          });
          return {
            ...depot,
            managementUnits: updatedManagementUnits,
          };
        }
        return depot;
      });
      const updatedDynamicDeployment = oldData.dynamicDeployment?.roads?.map((road) => {
        if (road.id === device.roadId) {
          const updatedManagementUnits = road?.roadSections?.map((section) => {
            return {
              ...section,
              managementUnits: section?.managementUnits?.map((mu) => (mu.id === device.id ? device : mu)),
            };
          });
          return {
            ...road,
            roadSections: updatedManagementUnits,
          };
        }
        return road;
      });
      const updatedUnassignedUnits = oldData?.unassignedUnits?.map((mu) =>
        mu.id === device.id ? device : mu
      );

      return {
        ...oldData,
        staticDeployment: {
          depots: updatedStaticDeployment,
        },
        dynamicDeployment: {
          roads: updatedDynamicDeployment,
        },
        unassignedUnits: updatedUnassignedUnits,
      };
    }
  }
};

export const updateVehicleInDeployment = (oldData: ProjectFleets, device: VehicleUnitModel) => {
  if (oldData) {
    const updatedFleets = oldData?.fleets?.map((fleet) => {
      if (fleet.id === device.fleetId) {
        const updatedDevices = fleet?.vehicleUnits?.map((vu) => (vu.id === device.id ? device : vu));
        return {
          ...fleet,
          vehicleUnits: updatedDevices,
        };
      }
      return fleet;
    });

    const updatedUnassignedDevices = oldData?.unassignedUnits?.map((vu) =>
      vu.id === device.id ? device : vu
    );

    return {
      ...oldData,
      fleets: updatedFleets,
      unassignedUnits: updatedUnassignedDevices,
    };
  }
};
