import {
  ManagementUnitConfiguration,
  VehicleUnitConfiguration,
} from '@electreon/electreon-device-control-service-gen-ts-client';
import { AppMode, AppOperationalMode, RECV_STATE, UserRole } from '../constants/constants';
import { ValuesOf } from './globals';
import { ParserEnums } from '@electreon/electreon_messages/dist/src/common_messages/parserEnums';
import z from 'zod';

export interface LockInfo {
  sessionId: string;
  userId: string;
  displayName: string;
  userName: string;
  lockTs: number;
  updatedLockTs: number;
  expiresAt: number;
  role: UserRole;
}

export type DcSamplerKey = `dc_sampler_${number}`;

export type DcSamplerSocketMessage = {
  [key: DcSamplerKey]: DcSamplerSocketData;
  totals: DcSamplerSocketData;
};

export interface DcSamplerSocketData {
  avgVoltage: number;
  avgCurrent: number;
  avgPower: number;
  data: DcSamplerSocketDatum[];
  accumulatedPower: number;
  maxPower: number;
}

export interface DcSamplerSocketDatum {
  voltage: number;
  current: number;
  power: number;
  timestamp: number;
}

export type ReceiversSocketMessage = Record<ReceiverKey, ReceiverMessageData>;

const ReceiverStateNames = Array.from(
  new Set([...Object.values(ParserEnums.receiver_state), ...Object.values(ParserEnums.receiver_state_new)])
);

export type ReceiverStateName = (typeof ReceiverStateNames)[number];

export interface ReceiverMessageData {
  fanState: 0 | 1 | 2 | 3;
  state: ReceiverState;
  errors?: Partial<typeof ParserEnums.receiver_errors>;
  temp: number[];
  stateName: ReceiverStateName | null;
  fanStateName: string;
  rfBoostSens?: number;
}

export type ReceiverState = ValuesOf<typeof RECV_STATE>;

export const CanbusDriverType = ParserEnums.canbus_driver_type;

const CanbusParsedErrorsSunwin = ParserEnums.canbus_sunwin_errors;

export interface CanBusDataSocketMessage {
  ts: number;
  batteryTemp: number; // 0 - 160 -> -40 - 120
  chargingAllowed: number;
  currentLevelEV: number; // 0-1022
  currentLevelStateEV: 0 | 1;
  ecasState: number;
  handbrakeState: keyof typeof ParserEnums.canbus_handbrake_state;
  maxChargingLevel: number; // 0-600
  stateOfChargingPercentages: number; // 0-100
  currentLevelStateEVName: ValuesOf<typeof ParserEnums.canbus_current_state>;
  chargingAllowedName: ValuesOf<typeof ParserEnums.canbus_charging_allowed>;
  ecasStateName: ValuesOf<typeof ParserEnums.canbus_ecas_state>;
  gearState: keyof typeof ParserEnums.canbus_gear_state;
  gearStateName: ValuesOf<typeof ParserEnums.canbus_gear_state>;
  handbrakeStateName: ValuesOf<typeof ParserEnums.canbus_handbrake_state>;
  txChargingPossible: 0 | 1;
  txChargingPower: number; // 0-255
  txHoursToFullyCharged: number;
  txMinutesToFullyCharged: 0 | 15 | 30 | 45;
  txReceiversActive: 0 | 1;
  txReceiversError: 0 | 1;
  canbusDriverType?: ValuesOf<typeof CanbusDriverType>;
  keyState?: keyof typeof ParserEnums.canbus_sunwin_key_state;
  keyStateName?: ValuesOf<typeof ParserEnums.canbus_sunwin_key_state>;
  parsedErrors?: typeof CanbusParsedErrorsSunwin;
  ignition: keyof typeof ParserEnums.canbus_iveco_ignition_state;
  ignitionName: ValuesOf<typeof ParserEnums.canbus_iveco_ignition_state>;
  canbusTx: boolean;
  canbusRx: boolean;
}

export type SegmentKey = `segment_${number}`;
export const isSegmentKey = (key: string): key is SegmentKey => key.match(/segment_\d+/) !== null;

export type FpgaKey = `fpga_${number}`;
export const isFpgaKey = (key: string): key is FpgaKey => key.match(/fpga_\d+/) !== null;
export type FPGASocketMessage = Record<FpgaKey, FPGAData> & { ts?: number; chargingStatus: number };
export type FPGAData = Record<SegmentKey, SegmentData> & { header_data: HeaderData; fpgaId: string };
export type FPGAMessageSegmentsData = Omit<FPGAData, 'header_data' | 'fpgaId'>;

export interface HeaderData {
  applicationMode: number;
  pwn_on_time: number;
  pwn_off_time_dynamic: number;
  pwn_off_time_static: number;
  current_off: number;
  over_current_threshold: number;
  over_current_mask_time: number;
  over_current_allowed_times: number;
  over_current_static_mask_time: number;
  full: number;
  pwm_max: number;
  firmwareVersion: string;
  linuxAppVersion: string;
  events: FpgaEvents;
  phase: number;
  freqDropOnOff: number;
  adjustableParam2: number;
  adjustableParam3: number;
  adjustableParam4: number;
  targetPower: number;
  badCommPrevSegMinDuration: number;
  commFilteringDuration: number;
}

export interface FpgaEvents {
  [key: string]: string;
}

export type EventsMessage = {
  events: Array<EventData>;
};

type EventData<T = NonNullable<unknown>> = Omit<
  {
    function: string;
    name: keyof typeof ParserEnums.som_events;
    nameString: string;
    ts: number;
    type: keyof typeof ParserEnums.eventTypes;
    typeName: string;
  },
  keyof T
> &
  T;

const versionMismatchFPGASOMEventSchema = z.object({
  function: z.literal('FPGA_Thread_Handler'),
  type: z.number(),
  name: z.literal(61),
  nameString: z.string().optional(),
  ts: z.number(),
  fpgaVersion: z.string(),
  somVersion: z.string(),
  minFpgaVersion: z.string(),
  minSomVersion: z.string(),
});

export type VersionMismatchFPGASOMEvent = EventData<z.infer<typeof versionMismatchFPGASOMEventSchema>>;

export const isVersionMismatchFPGASOMEvent = (obj: unknown): obj is VersionMismatchFPGASOMEvent => {
  return versionMismatchFPGASOMEventSchema.safeParse(obj).success;
};

export interface SegmentData {
  segmentStatus?: number;
  commDetected?: number;
  forceComm?: 0 | 1;
  forcePwm?: 0 | 1;
  forceOn?: 0 | 1;
  forceOff?: 0 | 1;
  segmentCommDetect?: number;
  commDuration?: number | null;
  segmentPhaseACurrent?: number;
  segmentPhaseBCurrent?: number;
  segmentIndFrequency?: number;
  segmentDutyCycle?: number;
  segmentDriverVersion?: number;
  segmentCurrentInRms?: number;
  segmentHbridgeATemp?: number;
  segmentHbridgeBTemp?: number;
  segmentWorkingFrequency?: number;
  segmentResonantFrequency?: number;
  segmentIzeroCurrent?: number;
  calibrationExtremeVal?: boolean;
  staticChargingState?: StaticChargingState;
  timestamp?: number;
  events?: FpgaEvents;
  isSegmentCharging?: boolean;
  power?: number;
  neighborModeInd?: 0 | 1 | null;
  badCommIndication?: 0 | 1;
  cableTemp?: number;
  segmentDriverGeneration?: number;
  segmentDcCurrent?: number;
  parsedOccupiedResonantFrequency?: number;
  parsedSegmentResonantFrequency?: number;
  parsedSegmentWorkingFrequency?: number;
  disableByLogicReasonIndex?: number;
  disableByLogicReason?: string;
}

export const isSegmentData = (obj: unknown): obj is SegmentData => {
  return z
    .object({
      segmentStatus: z.number().or(z.undefined()),
      parsedSegmentResonantFrequency: z.number(),
      parsedSegmentWorkingFrequency: z.number(),
    })
    .safeParse(obj).success;
};

export type StaticChargingState = ValuesOf<typeof ParserEnums.staticChargingSegmentState>;

export const isStaticChargingState = (value: unknown): value is StaticChargingState => {
  return Object.values(ParserEnums.staticChargingSegmentState).includes(value as StaticChargingState);
};

export interface ConnectionStateChangesSocketMessage {
  id: string;
  projectId: string;
  deviceType: string;
  timestamp: number;
  opertionState: string;
  ipAddress: string;
}

export interface BatteryDataSocketMessage {
  id: string;
  msg: {
    Percetage: number;
    Voltage: number;
    Current: number;
  };
}

export interface GPSSocketMessage {
  loc: {
    geojson: {
      type: string;
      coordinates: number[];
    };
    dmm: {
      latitude: string;
      longitude: string;
    };
  };
  speed: { knots: number; kmh: number };
}

export interface ChargingSocketMessage {
  id: string;
  msg: {
    chargingStatus: number;
  };
}

export interface DeviceStateSocketMessage {
  treeMap: TreeMap;
}

interface TreeMap {
  [deviceId: string]: TreeMapDeviceState;
}

interface TreeMapDeviceState {
  hpState?: number;
  chargingState?: number;
  chargingDiffTime?: number;
  calibrationExtremeValAcc?: number;
  activeShelves?: number[];
  fpgaToDisabledSegments?: { [key: string]: number[] };
  lastMsgTimestamp?: number;
  gps?: {
    timestamp: number;
    latitude: number;
    longitude: number;
  } | null;
}

export interface ContactorState {
  circuit_breaker_ind: number;
  discharge_ind: number;
  in_rush_ind: number;
  rectifier_ind: number;
  rectifier_temperature: number;
  electric_board_temperature: number;
  cabinet_temperature: number;
}

export type ContactorKey = `contactor_${number}`;
export type GPIOSSocketMessage = Record<ContactorKey, ContactorState> & {
  electric_board_temperature: number;
  cabinet_temperature: number;
  Ntcs?: {
    NTC_Commbox: number;
    NTC_Elec_panel: number;
    NTC_External: number;
    NTC_Inrush: number;
    NTC_Kemet: number;
    NTC_TEC_cold_side: number;
    NTC_TEC_hot_side: number;
    Ain_3_Temp_Ntc: number;
    Ain_4_Temp_Ntc: number;
    Ain_5_Temp_Ntc: number;
    Ain_6_Temp_Ntc: number;
    Ain_7_Temp_Ntc: number;
    Ain_8_Temp_Ntc: number;
    Cabinet_Ntc: number;
    Electric_Board_Ntc: number;
  };
};
const isContactorKey = (key: string): key is ContactorKey => key.match(/contactor_\d+/) !== null;
export const isGPIOSSocketMessage = (obj: unknown): obj is GPIOSSocketMessage => {
  const isObject = typeof obj === 'object' && obj !== null;
  return (
    isObject && Object.entries(obj).some(([key, value]) => isContactorKey(key) && isContactorState(value))
  );
};

export const isContactorState = (obj: unknown): obj is ContactorState => {
  return z
    .object({
      circuit_breaker_ind: z.number(),
      discharge_ind: z.number(),
      in_rush_ind: z.number(),
      rectifier_ind: z.number(),
    })
    .safeParse(obj).success;
};

type ReceiverKey = `receiver_${number}`;
const isReceiverKey = (key: string): key is ReceiverKey => key.match(/receiver_\d+/) !== null;
export type GPIOSVUSocketMessage = Record<ReceiverKey, GPIOVUReceiverState> & { cu: { fan_ind: number } };
export const isGPIOSVUSocketMessage = (obj: unknown): obj is GPIOSVUSocketMessage => {
  const isObject = typeof obj === 'object' && obj !== null;
  return (
    isObject && Object.entries(obj).some(([key, value]) => isReceiverKey(key) && isGPIOVUReceiverState(value))
  );
};

const isGPIOVUReceiverState = (obj: unknown): obj is GPIOVUReceiverState => {
  return z
    .object({
      comm_on_ind: z.number(),
      fan_amd_ind: z.number(),
      fan_hs_ind: z.number(),
      interlock_cu_side_ind: z.number(),
      interlock_rcv_side_ind: z.number(),
    })
    .safeParse(obj).success;
};

export interface GPIOVUReceiverState {
  comm_on_ind: number;
  fan_amd_ind: 0 | 1 | 2; // 0: "Off" : 1: "On" : 2: "Error"
  fan_hs_ind: 0 | 1 | 2; // 0: "Off" : 1: "On" : 2: "Error"
  interlock_cu_side_ind: number;
  interlock_rcv_side_ind: number;
}

export interface OperationalStateSocketMessage {
  // appMode: 0 | 1 | 2 | 3; // 0: "Unknown" : 1: "Auto" : 2: "Manual" : 3: "Dynamic"
  appMode: AppMode;
  appOperationalMode: AppOperationalMode;
}

export type ReceiverFreqName = ValuesOf<typeof ParserEnums.receiver_comm_freq>;

type ChargingPossibleTypeName = ValuesOf<typeof ParserEnums.vuChargingPossibleTypes>;

export interface CUUnitTempSocketMessage {
  ChargingPossible?: { ValidExternalConditions?: 0 | 1; Type?: number; TypeName?: ChargingPossibleTypeName };
  fanState: 0 | 1 | 2 | 3;
  numOfReceivers: number;
  receiversFreq: number;
  rfBoostLevel: number;
  temp: [number, number];
  fanStateName: string;
  receiversFreqName?: ReceiverFreqName | null;
  sendToroomTs: number;
  ts: number;
  msgId: string;
}

export interface HPSocketMessage {
  id: string;
  msg: { hpState: number };
}

export interface LockSocketMessage {
  lockInfo?: LockInfo;
  tags?: {
    lockInfo?: LockInfo;
  };
}

export interface APFCSocketMessage {
  avg_voltage: number;
  total_current: number;
  units_count: number;
  sendToroomTs: number;
  ts: number;
  msgId: string;
  targets?: {
    voltage?: number;
    current?: number;
  };
}
export interface ParkingSpotStatesMessage {
  depotId: number;
  deviceId: string;
  parkingSpotStates: Record<
    string,
    {
      stateNumber: keyof typeof ParserEnums.parkingSpotState;
      stateDescription: ValuesOf<typeof ParserEnums.parkingSpotState>;
    }
  >;
}

export const VU_STATE = {
  OFFLINE: 0, // not used
  NOT_CHARGING: 1,
  CHARGING: 2,
  ERROR: 3,
} as const;

export type AlertsSocketMessage = {
  alertId: string;
  deviceId: string;
  projectId: number;
  eventType: string;
  description: string;
  isActive: boolean;
  deviceName: string;
  rule: {
    description: string;
    id: number;
    name: string;
    severity: ValuesOf<typeof AlertRuleSeverity>;
    version: number;
  };
  ts: number;
  sendToroomTs: number;
  msgId: string;
};

const AlertRuleSeverity = {
  minor: 0,
  major: 1,
  critical: 2,
} as const;

type FansBoardKey = `fans_board_${number}`;
export const isFansBoardKey = (key: string): key is FansBoardKey => key.match(/fans_board_\d+/) !== null;
export type MuFansSocketMessage = Record<FansBoardKey, MuFansBoard>;
export type MuFansBoard = {
  [key: SegmentKey]: MuFansSegment;
  /** D40 only filter temperatures */
  temperatures?: Array<number>;
};

export interface MuFansSegment {
  filter_fan_0?: MuFan;
  filter_fan_1?: MuFan;
  sd_fan_0?: MuFan;
  sd_fan_1?: MuFan;
  rack_fan?: MuFan;
}

export interface MuFan {
  stateId: number;
  stateDescription: string;
  tacho: number;
  temperature?: number;
}

type CommBoardKey = `comm_board_${number}`;
export const isCommBoardKey = (key: string): key is CommBoardKey => key.match(/comm_board_\d+/) !== null;
export type CommBoardsSocketMessage = Record<CommBoardKey, CommBoard>;

export interface CommBoard {
  gains: [number, number, number, number, number, number, number, number, number, number, number, number];
  temperatures: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
  ];
}

export const isDcSamplerKey = (key: string): key is DcSamplerKey => key.match(/dc_sampler_\d+/) !== null;
export type DcSamplersShadowProperties = Record<DcSamplerKey, DcSamplerShadowData>;

type DcSamplerShadowData = {
  activation: string;
  partition: number;
  version: string;
};

export type ChargingActivitiesSocketMessage = {
  deviceId: string;
  chargingType?: string;
  ongoing?: ChargingActivity[];
  finished?: ChargingActivity[];
};

export type ChargingActivity = {
  entityId?: number;
  startTime?: number;
  endTime?: number;
  totalPower?: number;
  startSoc?: number;
};

export type ChargingMessage = {
  chargingStatus: number; // 0 - not charging, 1 - charging
};

export interface DeviceTwinChangesSocketMessage {
  deviceId?: string;
  properties?: Properties;
  version?: number;
  lockInfo?: LockInfo;
  tags?: {
    shouldRepark?: string;
    outOfService?: string;
    lockInfo?: LockInfo;
  };
}

interface Properties {
  reported: Reported;
}

export interface Reported {
  activeDcSamplers?: DcSamplersShadowProperties;
  activeShelves?: ActiveShelves;
  appMode?: AppMode;
  appOperationalMode?: AppOperationalMode;
  configuration?: VehicleUnitConfiguration & ManagementUnitConfiguration;
  deviceType?: string;
  diskFreeMemory?: number;
  diskTotalSize?: number;
  fpgaAppMode?: number;
  fwVersion?: string;
  fwVersionIn?: number;
  fwVersionRootfs?: string;
  fwVersionBoot?: string;
  generationType?: number;
  hpState?: boolean;
  chargingState?: boolean;
  lastReconnect?: number;
  modemConnectionData?: object;
  ApfcState?: 'on' | 'off';
  operation?: 'on' | 'off';
  activeFansBoard?: ActiveFanBoards;
  activeCommBoard?: ActiveCommBoards;
  activeErb?: ActiveErbs;
}

export type ErbKey = `erb_${number}`;
export const isErbKey = (key: unknown): key is ErbKey =>
  typeof key === 'string' && key.match(/erb_\d+/) !== null;
export type ActiveErbs = Record<ErbKey, ActiveErb>;
export type ActiveErb = {
  activation: 'active' | 'not active';
  partition: number;
  version: string;
};

interface FanBoard {
  activation: 'active' | 'not active';
  partition: number;
  version: string;
}

export type ActiveFanBoards = Record<FansBoardKey, FanBoard>;

export interface CommBoard {
  activation: 'active' | 'not active';
  partition: number;
  version: string;
}

export type ActiveCommBoards = Record<CommBoardKey, CommBoard>;
interface ActiveShelves {
  [key: string]: FPGA | number;
}

interface FPGA {
  appMode: number;
  id: number;
}

export type MuAirConditionUserState = ValuesOf<typeof ParserEnums.muAirConditionUserState>;

export interface AirConditionMessage {
  enable: boolean;
  parsedAlarms: Record<number, string>;
  state: number;
  stateName: MuAirConditionUserState;
  ts: number;
}

export interface EnvSensorsSocketMessage {
  dewPoint: number;
  humidity: number;
  temperature: number;
  DC_Surge_indication: 0 | 1;
  AC_Surge_indication: 0 | 1;
  Flood_Indication: 0 | 1;
  Open_Lid_indication: 0 | 1;
  technicianKeyInd: 0 | 1;
  ceilingFanState: keyof typeof ParserEnums.muCeilingFanState;
  ceilingFanStateName: ValuesOf<typeof ParserEnums.muCeilingFanState>;
  heaterState: keyof typeof ParserEnums.muHeaterState;
  heaterStateName: ValuesOf<typeof ParserEnums.muHeaterState>;
  solenoidState: keyof typeof ParserEnums.muSensorsSolenoidState;
  solenoidStateName: ValuesOf<typeof ParserEnums.muSensorsSolenoidState>;
  tecPlateState: keyof typeof ParserEnums.muSensorsTecPlateState;
  tecPlateStateName: ValuesOf<typeof ParserEnums.muSensorsTecPlateState>;
  externalFanState: keyof typeof ParserEnums.muSensorsExternalFanState;
  externalFanStateName: ValuesOf<typeof ParserEnums.muSensorsExternalFanState>;
  blowerFilterState: keyof typeof ParserEnums.muSensorsExternalFanState;
  blowerFilterStateName: ValuesOf<typeof ParserEnums.muSensorsExternalFanState>;
  blowerSdState: keyof typeof ParserEnums.muSensorsExternalFanState;
  blowerSdStateName: ValuesOf<typeof ParserEnums.muSensorsExternalFanState>;
}
