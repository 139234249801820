import React, { PropsWithChildren, useState } from 'react';
import { cn } from '@repo/ui/lib/utils';

const CategoriesContext = React.createContext<{
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}>({ setSelectedCategory: () => {}, selectedCategory: '' });

const CategoriesProvider: React.FC<PropsWithChildren<{ initialCategory: string }>> = ({
  initialCategory,
  children,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  return (
    <CategoriesContext.Provider value={{ selectedCategory, setSelectedCategory }}>
      {children}
    </CategoriesContext.Provider>
  );
};
CategoriesProvider.displayName = 'CategoriesProvider';

const useCategories = () => {
  const context = React.useContext(CategoriesContext);
  if (context === undefined) {
    throw new Error('useCategories must be used within a CategoriesProvider');
  }
  return context;
};

const Categories: React.FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
  return <div className={cn('flex flex-row gap-4', className)}>{children}</div>;
};
Categories.displayName = 'Categories';

const CategoriesMenuWrapper: React.FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return (
    <ul
      className={cn(
        'h-full overflow-y-scroll scrollbar-gutter-stable w-fit min-w-[250px] px-2 border-r border-gray-200',
        className
      )}
    >
      {children}
    </ul>
  );
};
CategoriesMenuWrapper.displayName = 'CategoriesMenuWrapper';

const CategoryList: React.FC<{ categories: Array<string>; className?: string }> = ({
  categories,
  className,
}) => {
  const { setSelectedCategory } = useCategories();
  return (
    <div className={className}>
      {categories.map((category, i) => (
        <Category key={category + i} category={category} onClick={() => setSelectedCategory(category)} />
      ))}
    </div>
  );
};
CategoryList.displayName = 'CategoryList';

const Category: React.FC<{ category: string; className?: string; onClick?: () => void }> = ({
  category,
  onClick,
  className,
}) => {
  const { selectedCategory } = useCategories();
  return (
    <li key={category} onClick={onClick} className={className}>
      <span
        className={cn(
          `block w-full rounded-sm px-2 py-[0.4rem] text-[16px] font-[500] text-start cursor-pointer text-nowrap overflow-x-hidden text-ellipsis`,
          {
            'bg-gray-100/40': selectedCategory === category,
            'bg-inherit': selectedCategory !== category,
            ['hover:bg-gray-100/20']: selectedCategory !== category,
          }
        )}
      >
        {category}
      </span>
    </li>
  );
};
Category.displayName = 'Category';

const CategoryContent: React.FC<PropsWithChildren<{ categoryName?: string; className?: string }>> = ({
  categoryName,
  className,
  children,
}) => {
  return (
    <section className={cn('flex flex-col w-full h-full gap-2 px-6', className)}>
      {categoryName && <h2 className='text-xl font-bold mb-2'>{categoryName}</h2>}
      {children}
    </section>
  );
};
CategoryContent.displayName = 'CategoryContent';

export {
  CategoriesProvider,
  useCategories,
  Categories,
  CategoriesMenuWrapper,
  Category,
  CategoryContent,
  CategoryList,
};
