import { ProjectDeployment, RoadSection } from '@electreon/electreon-metadata-service-gen-ts-client';

export const roadSectionToManagementUnitIdsMap = (deploymentData?: ProjectDeployment) => {
  const roadSectionToManagementUnitIdMap = new Map<string, string[]>();
  deploymentData?.dynamicDeployment?.roads?.forEach((road) => {
    road.roadSections?.forEach((roadSection) => {
      if (!roadSection.id || !roadSection.managementUnits) return;
      roadSectionToManagementUnitIdMap.set(
        String(roadSection.id),
        roadSection.managementUnits.map((mu) => mu.id ?? '')
      );
    });
  });
  return roadSectionToManagementUnitIdMap;
};

export const roadSectionMap = (deploymentData?: ProjectDeployment) => {
  const roadSectionMap = new Map<string, RoadSection>();
  deploymentData?.dynamicDeployment?.roads?.forEach((road) => {
    road.roadSections?.forEach((roadSection) => {
      if (!roadSection.id) return;
      roadSectionMap.set(String(roadSection.id), roadSection);
    });
  });
  return roadSectionMap;
};
