import {
  ManagementUnitWithParkingSpots,
  OcppChargerModel,
  OcppChargerWithParkingSpots,
} from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  ApfcModelRefined,
  ManagementUnitModelRefined,
  OcppChargerModelRefined,
  OcppChargerWithParkingSpotsRefined,
  PosModelRefined,
  UiDevice,
  VehicleUnitModelRefined,
} from '../types/globals';
import _ from 'lodash';

export const convertStringBooleanToBoolean = (str: string | boolean | undefined): boolean | undefined => {
  if (typeof str === 'boolean') return str;
  if (str === 'true') return true;
  if (str === 'false') return false;
  return undefined;
};

export const isMu = (device?: UiDevice | null): device is ManagementUnitModelRefined => {
  return device?.deviceType?.toUpperCase() === 'MU';
};

export const isMuWithParkingSpots = (device?: unknown): device is ManagementUnitWithParkingSpots => {
  const d = device as ManagementUnitWithParkingSpots;
  return Boolean(d?.parkingSpots && d?.managementUnit);
};

export const isOcppWithParkingSpots = (device?: unknown): device is OcppChargerWithParkingSpotsRefined => {
  const d = device as OcppChargerWithParkingSpots;
  return Boolean(d?.parkingSpots && d?.ocppCharger);
};

export const isPos = (device?: UiDevice | null): device is PosModelRefined => {
  return device?.deviceType?.toUpperCase() === 'POS';
};

export const isVu = (device?: UiDevice | null): device is VehicleUnitModelRefined => {
  return device?.deviceType?.toUpperCase() === 'VU';
};

export const isApfc = (device?: UiDevice | null): device is ApfcModelRefined => {
  return device?.deviceType?.toUpperCase() === 'APFC';
};

export const isOcpp = (
  device?: UiDevice | ManagementUnitWithParkingSpots | OcppChargerModel | null
): device is OcppChargerModelRefined => {
  if (isMuWithParkingSpots(device)) return false;
  return device?.deviceType?.toUpperCase() === 'OCPP';
};

export enum VersionComparisonResult {
  Greater = 'GREATER',
  Lesser = 'LESSER',
  Equal = 'EQUAL',
}

/** Compares two version strings and returns the result */
export const compareVersions = (versionX: string, versionY: string): VersionComparisonResult => {
  const segmentsX = versionX.split('.').map(Number);
  const segmentsY = versionY.split('.').map(Number);

  // Find the maximum length to compare
  const maxLength = Math.max(segmentsX.length, segmentsY.length);

  for (let i = 0; i < maxLength; i++) {
    const segmentX = segmentsX[i] || 0; // Assume 0 for missing segments
    const segmentY = segmentsY[i] || 0; // Assume 0 for missing segments

    if (segmentX > segmentY) return VersionComparisonResult.Greater;
    if (segmentX < segmentY) return VersionComparisonResult.Lesser;
  }

  return VersionComparisonResult.Equal; // All compared segments are equal
};

/** Returns true if the firmware version is 2.0.0 or greater than 2.0.251 */
export const isAppModeV2 = (somFirmwareVersion?: string) => {
  if (somFirmwareVersion === '2.0.0') return true;
  return compareVersions(somFirmwareVersion || '', '2.0.251') === VersionComparisonResult.Greater;
};

/** Returns true if the linux app version is 2.0.0 or greater or equal to 2.0.132 */
export const isLinuxVersionV3 = (linuxAppVersion?: string) => {
  if (linuxAppVersion === '2.0.0') return true;
  const comparisonResult = compareVersions(linuxAppVersion || '', '2.0.132');
  return (
    comparisonResult === VersionComparisonResult.Greater || comparisonResult === VersionComparisonResult.Equal
  );
};

export enum MeasurementType {
  ENERGY = 'energy',
  POWER = 'power',
}

/**
 * Formats a numeric value based on the given measurement type.
 * @param value - The numeric value to be formatted. It should be in watts or watt-hours.
 * @param type - The measurement type (ENERGY or POWER).
 * @returns An object containing the formatted value and units.
 * @throws Error if an invalid measurement type is provided.
 */
export const formatValue = (
  value: number | undefined,
  type: MeasurementType,
  options?: {
    formatAsKilo?: boolean;
    roundDown?: boolean;
  }
): { value: number | undefined; units: string | undefined } => {
  if (value === undefined) return { value: undefined, units: undefined };

  let units: string;
  let convertedValue: number | undefined = value;
  if (type === MeasurementType.ENERGY) {
    // Energy: value is in watt-hours, units are MWh, kWh, or Wh
    if (options?.formatAsKilo) {
      convertedValue = value / 1000;
      units = 'kWh';
    } else if (value >= 1000000) {
      units = 'MWh';
      convertedValue = value / 1000000;
    } else if (value >= 1000) {
      units = 'kWh';
      convertedValue = value / 1000;
    } else {
      units = 'Wh';
    }
  } else if (type === MeasurementType.POWER) {
    // Power: value is in watts, units are MW, kW, or W
    if (options?.formatAsKilo) {
      convertedValue = value / 1000;
      units = 'kW';
    } else if (value >= 1000000) {
      units = 'MW';
      convertedValue = value / 1000000;
    } else if (value >= 1000) {
      units = 'kW';
      convertedValue = value / 1000;
    } else {
      units = 'W';
    }
  } else {
    throw new Error(`Invalid type: ${type}`);
  }

  return { value: options?.roundDown ? Math.floor(convertedValue) : convertedValue, units };
};

/** Recursively traverses an object and deletes every entry with a null value */
export function removeNullValues<T>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNullValues(obj[key]);
    }
  }
  return obj;
}

/** Returns the first index of an element in a sorted array that matches the predicate */
export function binarySearch<T>(arr: T[], predicate: (item: T) => boolean): number {
  let low = 0;
  let high = arr.length - 1;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (predicate(arr[mid])) {
      if (mid === 0 || !predicate(arr[mid - 1])) {
        return mid;
      }
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }

  return -1; // Return -1 if no element matches the predicate
}

/** Returns an array of objects of device id to device subtype */
export function getDeviceTypeToSubtypeMap(devices: UiDevice[]) {
  const deviceTypeToSubtypeModel: Array<{ deviceId: string; subType: string }> = [];
  devices.forEach((device) => {
    if (device.deviceSubType && device.id) {
      deviceTypeToSubtypeModel.push({ deviceId: device.id, subType: device.deviceSubType });
    }
  });
  return deviceTypeToSubtypeModel;
}
