import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { SelectProjectInput, SelectProjectSchema } from './utils/ProjectSelectionFormUtils';
import { useAppStore } from 'MobxStores/context';
import { ProjectNameDisplay } from '../ProjectSelectionInputs/ProjectNameDisplay';
import { useProjects } from 'Utils/APIUtils';

export const ProjectSelectionForm: React.FC<FormProps> = ({ onSuccessfulSubmit, onCancel }) => {
  const { data: projectsMap } = useProjects();
  const projects = [...(projectsMap || []).values()];
  const { alertStore } = useAppStore();
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    watch,
    handleSubmit,
  } = useForm<SelectProjectInput>({
    resolver: zodResolver(SelectProjectSchema),
  });
  const onSubmitHandler: SubmitHandler<SelectProjectInput> = (values) => {
    const { projectId } = values;
    alertStore.getLatestAlerts(projectId === 'allSites' ? undefined : Number(projectId));
    onSuccessfulSubmit();
  };

  return (
    <>
      <Typography variant='h4' component='h1'>
        Add Project
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <TextField
            select
            label='Select Project'
            sx={{
              flex: 1,
              '&& .MuiSelect-select': { display: 'flex', direction: 'row', alignItems: 'center' },
            }}
            error={!!errors['projectId']}
            helperText={errors['projectId'] ? errors['projectId'].message : ''}
            value={watch('projectId')}
            {...register('projectId')}
          >
            <MenuItem key={'allSites'} value={'allSites'}>
              {'All Sites'}
            </MenuItem>
            {projects?.map((project) => {
              return (
                <MenuItem key={project.id} value={project.id}>
                  <ProjectNameDisplay project={project} />
                </MenuItem>
              );
            })}
          </TextField>
        </Stack>
        <FormSubmitionButtons onCancel={onCancel} submitLabel='ADD' />
      </Box>
    </>
  );
};
ProjectSelectionForm.displayName = 'ProjectSelectionForm';
