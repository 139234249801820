import { Autocomplete, Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { UserAccountActivationInput, UserAccountActivationSchema } from './ManageUsersFormsUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { useAppStore } from 'MobxStores/context';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';
const optionalAccountStatusValues = { ENABLED: 'Enabled', DISABLED: 'Disabled', NOT_SET: 'Not Set' };

export const UserActivationForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const { authStore } = useAppStore();
  const methods = useForm<UserAccountActivationInput>({
    resolver: zodResolver(UserAccountActivationSchema),
    defaultValues: {
      username: '',
      accountStatus: optionalAccountStatusValues.NOT_SET,
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;
  const [userAccountStatus, setUserAccountStatus] = useState<string>('');

  const onSubmitHandler: SubmitHandler<UserAccountActivationInput> = (values) => {
    const { accountStatus, username } = values;
    if (accountStatus === userAccountStatus) {
      return;
    }
    const functionToCall =
      accountStatus === optionalAccountStatusValues.ENABLED ? 'enableUserAccount' : 'disableUserAccount';
    authStore[functionToCall](username)
      .then(() => {
        toast.success(`User Account ${accountStatus}`);
      })
      .catch((error) => {
        console.error(error, 'error');
        toast.error(`The user account was not ${accountStatus}, please try again.`);
      });
    onSuccessfulSubmit();
  };

  const getUserHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const email = methods.getValues('email');
    authStore
      .getUserData(email)
      .then((userData) => {
        if (!userData || Array.isArray(userData)) {
          !userData && toast.error('There are no users found with this email.');
          Array.isArray(userData) &&
            toast.error('There are more than one user with this email. please contact the software team.');
          methods.reset({ username: '', accountStatus: optionalAccountStatusValues.NOT_SET });
          setUserAccountStatus('');
          return;
        }
        const accountStatus =
          userData.Enabled === true
            ? optionalAccountStatusValues.ENABLED
            : optionalAccountStatusValues.DISABLED;
        methods.reset({
          accountStatus: accountStatus,
          username: userData.Username,
        });
        setUserAccountStatus(
          userData.Enabled ? optionalAccountStatusValues.ENABLED : optionalAccountStatusValues.DISABLED
        );
      })
      .catch((error) => {
        toast.error('Something went wrong with getting the user Data, please try again later.');
        methods.reset({ username: '', accountStatus: optionalAccountStatusValues.NOT_SET });
        setUserAccountStatus('');
      });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1'>
        User Enablement/Disablement
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (errors) => {
          console.log(errors);
        })}
      >
        <Stack direction='column' spacing={2} sx={{ mb: 2 }}>
          <TextField
            label='User Email'
            fullWidth
            required
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            {...register('email')}
            onChange={() => {
              methods.reset({ username: '', accountStatus: optionalAccountStatusValues.NOT_SET });
            }}
          />
          <Button variant='contained' onClick={getUserHandler}>
            Get Account Status
          </Button>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Autocomplete
          fullWidth
          options={Object.values(optionalAccountStatusValues)}
          filterOptions={() =>
            Object.values(optionalAccountStatusValues).filter(
              (option) => option !== optionalAccountStatusValues.NOT_SET
            )
          }
          sx={{ mb: 2 }}
          defaultValue={optionalAccountStatusValues.NOT_SET}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Account Status'
              error={!!errors['accountStatus']}
              helperText={errors['accountStatus'] ? errors['accountStatus'].message : ''}
              {...register('accountStatus')}
            />
          )}
          value={methods.getValues('accountStatus')}
          onChange={(event, newValue) => {
            methods.setValue('accountStatus', newValue || undefined);
          }}
          disabled={!methods.getValues('username')}
        />
        <FormSubmitionButtons
          onCancel={onCancel}
          submitLabel='Change'
          confirmation
          confirmTitle={`Are you sure you want to ${methods.getValues('accountStatus') === optionalAccountStatusValues.ENABLED ? 'enable' : 'disable'} user account?`}
          confirmMessage={`User will ${methods.getValues('accountStatus') === optionalAccountStatusValues.ENABLED ? '' : 'not'} have access to Smart Road platform!`}
          onSubmitHandler={() => onSubmitHandler(methods.getValues())}
          disableSubmit={
            !methods.getValues('username') ||
            methods.getValues('accountStatus') === userAccountStatus ||
            methods.getValues('accountStatus') === optionalAccountStatusValues.NOT_SET
          }
          {...methods.watch()}
        />
      </Box>
    </FormProvider>
  );
});
