import { CognitoUserDataDto } from '@electreon/electreon-user-management-service-gen-ts-client';
import { UserRole } from '@electreon_ui/shared/constants/constants';
import { isRoleLower } from 'Utils/utils';
import { object, TypeOf, string, array, number } from 'zod';

export const InviteUserSchema = object({
  email: string().email({ message: 'Invalid email address.' }),
  firstName: string().min(1, { message: 'Name can not be Empty.' }),
  familyName: string().min(1, { message: 'Family name can not be Empty.' }),
  selectedProjects: array(number()).nonempty('Select at least one project for the user scope.'),
  role: string().optional(),
});

export type InviteUserInput = TypeOf<typeof InviteUserSchema>;

export const isSubScope = (scope1?: number[] | null, scope2?: number[] | null) => {
  if (scope1 === undefined || scope2 === undefined) {
    return false;
  }
  if (scope2 === null) {
    return true;
  }
  if (scope1 === null) {
    return scope2 === null;
  }
  return [...scope1].every((item) => scope2.includes(item));
};

export const UserAuthoritySchema = object({
  email: string().email({ message: 'Invalid email address.' }),
  selectedProjects: array(number()),
  role: string().optional(),
  username: string(),
});

export type UserAuthorityInput = TypeOf<typeof UserAuthoritySchema>;

export const getUserProjectsScope = (userData: CognitoUserDataDto): number[] => {
  return JSON.parse(userData.Attributes.find((att) => att.Name === 'custom:authorization')?.Value ||
    '').projectScope || [];
}

export const getOptionalUserRoles = (userRole?: UserRole) => Object.values(UserRole).filter((role: UserRole) =>
  isRoleLower(role, userRole)
);

export const UserAccountActivationSchema = object({
  email: string().email({ message: 'Invalid email address.' }),
  accountStatus: string().optional(),
  username: string(),
});

export type UserAccountActivationInput = TypeOf<typeof UserAccountActivationSchema>;

export const DeleteUserSchema = object({
  email: string().email({ message: 'Invalid email address.' }),
  username: string(),
});

export type DeleteUserInput = TypeOf<typeof DeleteUserSchema>;