import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { observer } from 'mobx-react-lite';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CreateRoadInput, CreateRoadSchema, roadDefaultValues } from './utils/roadSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Alert, Box, Container, Typography } from '@mui/material';
import { SelectProject } from '../ParkingSpotForm/SelectFields/SelectProject';
import { RoadNameInput } from './RoadFormsInputs/RoadNameInput';
import { RoadTotalLengthInput } from './RoadFormsInputs/RoadTotalLengthInput';
import { editRoad } from '../FormsAPIs/api';
import { RoadSelectionInput } from './RoadFormsInputs/RoadSelectionInput';
import { Road } from '@electreon/electreon-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { FormLoading } from '../FormUtils/FormLoading';
import { toast } from 'sonner';

interface EditRoadFormProps extends FormProps {
  roadId?: number;
}

export const EditRoadForm: React.FC<EditRoadFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject, roadId }) => {
    const [selectedRoad, setSelectedRoad] = useState<Road | null>(null);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [roads, setRoads] = useState<Road[]>([]);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const methods = useForm<CreateRoadInput>({
      resolver: zodResolver(CreateRoadSchema),
      defaultValues: roadDefaultValues,
    });

    const onSubmitHandler: SubmitHandler<CreateRoadInput> = (values) => {
      setSubmitError(null);
      const { roadName, totalLengthKm, geoPath } = values;
      editRoad({
        id: selectedRoad?.id,
        name: roadName,
        totalLengthKm: totalLengthKm,
        geoPath,
        version: selectedRoad?.version,
        projectId: selectedProject?.id as number,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success('Road updated');
            methods.reset(roadDefaultValues);
            onSuccessfulSubmit?.();
          } else {
            toast.error('Road edit failed');
            setSubmitError('Road edit failed');
            throw new Error('Edit Road Failed');
          }
        })
        .catch((error) => {
          toast.error('Road edit failed');
          setSubmitError('Something went wrong');
          console.error('Edit Road error', JSON.stringify(error));
        });
    };
    useEffect(() => {
      const fetchRoads = async () => {
        if (!selectedProject?.id) return;
        const roads = await api.metadata.roads.getProjectRoads(selectedProject.id);
        setFormLoading(false);
        setRoads(roads.data);
      };
      setFormLoading(true);
      fetchRoads();
    }, [selectedProject?.id]);

    useEffect(() => {
      if (roadId) {
        const road = roads.find((road) => road.id === roadId);
        if (road) {
          setSelectedRoad(road);
          methods.setValue('id', road.id);
          methods.setValue('roadName', String(road.name));
        }
      }
    }, [roads, roadId, methods]);

    return (
      <FormProvider {...methods}>
        <Container disableGutters sx={{ opacity: 1 }}>
          <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
            Edit Road
          </Typography>

          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <SelectProject selectedProject={selectedProject} errors={methods.formState.errors} />
            <RoadSelectionInput
              setSelectedRoad={setSelectedRoad}
              roads={roads}
              selectedRoad={selectedRoad}
              disabled={!!roadId}
            />
            <RoadNameInput currentRoadName={selectedRoad?.name || ''} />
            <RoadTotalLengthInput currentLength={selectedRoad?.totalLengthKm} />
            {submitError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}
            <FormLoading open={formLoading} />
            <FormSubmitionButtons onCancel={onCancel} />
          </Box>
        </Container>
      </FormProvider>
    );
  }
);
