import { FunctionalitiesMap } from './types/permissionsTypes';

export const permissionsDefinition: FunctionalitiesMap = {
  header: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'header-logo': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'header-search': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'header-bell': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'header-control-panel': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'header-account': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'header-time': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'header-flag': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  projects: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'toggle-empty-projects': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'projects-cards': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'projects-card-menu': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: false,
  },
  depot: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-spot-toggle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: false,
  },
  'depot-vehicle-tab': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-spot-tab': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-sort-and-filter': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-popup-device-id': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'depot-vehicle-charging-sessions': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-vehicle-sessions-select-date': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-vehicle-charging-sessions-average-power': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'depot-spot-charging-sessions': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'depot-spot-sessions-select-date': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'depot-spot-charging-sessions-average-power': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'depot-map': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-map-vehicle-etf': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'depot-map-vehicle-power': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  dashboard: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'dashboard-toggle-single-multi-vehicle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'alerts-bell-popup': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'alerts-sort': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'alerts-filter': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'alerts-next-previous-page': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'alerts-read-unread': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  'alerts-read-all': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: true,
    PROJECT_VIEWER: true,
  },
  integration: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-device-sidebar': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-ga-devices': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-vu-devices': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-device-options-menu': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'device-energy-meter-change': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-mu': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-lock': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-man-auto': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-lv-power-supply': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-hv-power-toggle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-versions-tooltip': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-rack-idle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-rack-off': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-all-fans-force-on': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-comm-gain-control': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-rack-calibrate': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-fpga-config-view': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-fpga-config-parameters': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-fpga-config-save-default-apply': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-fpga-config-load': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-fpga-versions': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-off': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-idle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-force-on': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-force-comm': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-force-pwm': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-bad-comm': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-voltage': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-i-rms': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-power': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-i-zero': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-i-a': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-i-b': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-frequency-indicated': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-duty-cycle-slider': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-rf-comm': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-segment-temperatures': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-filter-temperatures': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-resonance-frequency': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-phase': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-working-frequency': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'mu-duty-cycle-numeric': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'smu-hybrid-static-dynamic': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'smu-hybrid-rack-idle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'smu-hybrid-pwm-on-time': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: 'limited',
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'smu-hybrid-pwm-off-time': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: 'limited',
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'smu-hybrid-target-power': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-vu': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-lock': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-force-wakeup': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-versions': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-comm-level': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-charging-possible-cloud': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-repark': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-out-of-service': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-GPS': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-CAN': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-receiver-off-ev': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'vu-receiver-fan-force-on': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: 'limited',
    OPERATOR: 'limited',
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-graphs': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-graphs-timespan-select': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-edit-y-domain': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'integration-ocpp': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'ocpp-connector-toggle': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'ocpp-trigger-commands': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  analytics: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-add-params': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-power-select-button': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-expand': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-dc-sampler': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-general': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-control-unit': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-can-bus': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-gps': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-receivers': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-gpios': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-vu-positioning-system': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-expand': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-dc-sampler': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-general': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-fpga': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-gpios': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-sensors': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-mu-fans-boards': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-params-ok-button': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-activity-by-select': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-date-select': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-export': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-export-choose-resolution': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-activity-bar-button': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-span-select': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-events': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-map': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: true,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  'analytics-extra-payload': {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: false,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
  reports: {
    SUPER_ADMIN: true,
    ADMIN: true,
    INTEGRATOR: true,
    EMPLOYEE: true,
    OPERATOR: false,
    PROJECT_USER: false,
    PROJECT_VIEWER: false,
  },
};
