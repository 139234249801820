import * as DCS from '@electreon/electreon-device-control-service-gen-ts-client';
import * as OCPPCS from '@electreon/electreon-ocpp-control-service-gen-ts-client';
import { DeviceControlService } from './DeviceControlService';
import { DeviceMetadataService } from './DeviceMetadataService';
import { DeviceTelemetryService } from './DeviceTelemetryService';
import { MetadataService } from './MetadataService';
import { OcppChargersMetadataService } from './OcppChargersMetadataService';
import { AxiosInstance } from 'axios';
import { UserManagementService } from './UserManagementService';

export class ElectreonApiServices {
  public deviceControl: DeviceControlService;
  public deviceMetadata: DeviceMetadataService;
  public metadata: MetadataService;
  public deviceTelemetry: DeviceTelemetryService;
  public ocppChargersMetadata: OcppChargersMetadataService;
  public ocppControl: OCPPCS.OcppRemoteControlControllerApi;
  public userManagement: UserManagementService;

  constructor(basePath: string, axios: AxiosInstance, config?: DCS.Configuration) {
    this.deviceControl = new DeviceControlService(basePath, axios, config);
    this.deviceMetadata = new DeviceMetadataService(basePath, axios, config);
    this.metadata = new MetadataService(basePath, axios, config);
    this.deviceTelemetry = new DeviceTelemetryService(basePath, axios, config);
    this.ocppChargersMetadata = new OcppChargersMetadataService(basePath, axios, config);
    this.ocppControl = new OCPPCS.OcppRemoteControlControllerApi(config, basePath, axios);
    this.userManagement = new UserManagementService(basePath, axios, config);
  }
}