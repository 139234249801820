import { ProjectFleets, ProjectDeployment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { VehicleUnitModelRefined, UiDevice } from '../types/globals';

export enum FleetUpdateAction {
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',
}

export const updateVehicleInFleet = (
  oldData: ProjectFleets,
  device: VehicleUnitModelRefined,
  action: FleetUpdateAction
) => {
  if (oldData) {
    if (action === FleetUpdateAction.ASSIGN) {
      return {
        ...oldData,
        fleets: oldData?.fleets?.map((fleet) => {
          if (fleet.id === device.fleetId) {
            const updatedDevices = fleet.hasOwnProperty('vehicleUnits')
              ? [...(fleet.vehicleUnits || []), device]
              : [device];
            return {
              ...fleet,
              vehicleUnits: updatedDevices,
            };
          }
          return fleet;
        }),
        unassignedUnits: oldData?.unassignedUnits?.filter((vu) => vu.id !== device.id),
      };
    } else if (action === FleetUpdateAction.UNASSIGN) {
      return {
        ...oldData,
        fleets: oldData?.fleets?.map((fleet) => {
          const updatedDevices = fleet?.vehicleUnits?.filter((device) => device.id !== device?.id);
          if (updatedDevices?.length === 0) {
            const { vehicleUnits, ...fleetData } = fleet;
            return fleetData;
          } else {
            return {
              ...fleet,
              vehicleUnits: updatedDevices,
            };
          }
        }),
        unassignedUnits: [...(oldData.unassignedUnits || []), device],
      };
    }
  }
};

export const updateDeviceInDeployment = (oldData: ProjectDeployment, device: UiDevice) => {
  if (oldData) {
    switch (device.deviceType) {
      case 'APFC':
        return {
          ...oldData,
          apfcDeployment: {
            ...oldData.apfcDeployment,
            apfcs: [...(oldData.apfcDeployment?.apfcs || []), device],
          },
        };
      case 'MU':
        return {
          ...oldData,
          unassignedUnits: [...(oldData.unassignedUnits || []), device],
        };
      case 'OCPP': {
        return {
          ...oldData,
          unassignedOcppChargers: [...(oldData.unassignedOcppChargers || []), device],
        };
      }
      // case 'POS': {
      //   return;
      // }
      case 'VU': {
        return {
          ...oldData,
          unassignedUnits: [...(oldData.unassignedUnits || []), device],
        };
      }
      default: {
        return oldData;
      }
    }
  }
};
