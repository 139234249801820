import { Typography } from '@mui/material';
import { Popup } from 'Components/Popup/Popup';
import { SubmitHandler, useFormContext } from 'react-hook-form';

export function ConfirmationPopup({
  isOpen: isConfirmPopupOpen,
  setIsOpen: setIsConfirmPopupOpen,
  onSubmitHandler,
  confirmTitle = 'Are you sure?',
  confirmMessage = '',
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmitHandler?: SubmitHandler<any>;
  confirmTitle?: string;
  confirmMessage?: string;
}) {
  const { getValues } = useFormContext() || { getValues: () => {} };
  return (
    <Popup
      title={confirmTitle}
      content={<Typography> {confirmMessage} </Typography>}
      isOpen={isConfirmPopupOpen}
      handleClose={() => setIsConfirmPopupOpen(false)}
      handleSuccess={() => {
        onSubmitHandler && onSubmitHandler(getValues());
        setIsConfirmPopupOpen(false);
      }}
    />
  );
}
ConfirmationPopup.displayName = 'ConfirmationPopup';
