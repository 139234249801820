import { MenuItem, ListItemText } from '@mui/material';

import { CustomMenu } from 'Components/Menu/MenuTamplate';
import { ImgListItem } from 'Components/MenuItems/ImgListItem';
import { TextListItem } from 'Components/MenuItems/TextListItem';

import { getFormattedUserRole, getUserFullname } from 'Components/Header/Utils/headerUtils';

import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { rootStore, useAppStore } from 'MobxStores/context';
import { UserData } from '@electreon_ui/shared/stores/userStore/UserStore';
import PopupStore from '@electreon_ui/shared/stores/popupStore/PopupStore';
import { observer } from 'mobx-react-lite';
import { UserRole } from '@electreon_ui/shared/constants/constants';
import { Link } from 'react-router-dom';
import { AppNames } from '@electreon_ui/shared/types/globals';
import { useState } from 'react';
import { ConfirmationPopup } from 'Components/Forms/FormUtils/ConfirmationPopup';
import { toast } from 'sonner';

const menuItems = [
  {
    id: 'Settings',
    Logo: SettingsIcon,
    onClick: (popupStore: PopupStore) => {
      popupStore.openUserAttributesPopup();
    },
  },
  {
    id: 'Profile',
    Logo: AccountCircleIcon,
    onClick: () => {},
  },
  {
    id: 'Support',
    Logo: InfoIcon,
    onClick: () => {},
  },
  {
    id: 'Delete Account',
    Logo: DeleteIcon,
    onClick: () => {},
  },
];

type MenuProps = {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  handleLogout: () => void;
};

export const UserMenu: React.FC<MenuProps> = observer(({ anchorEl, isOpen, setIsOpen, handleLogout }) => {
  const { userStore } = useAppStore();
  const userData = userStore.userData;
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleItemClick = (cb: () => void) => {
    handleClose();
    cb();
  };

  return (
    <CustomMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      body={<UserMenuBody handleItemClick={handleItemClick} handleLogout={handleLogout} />}
      title={<UserMenuTitle userData={userData} />}
      footer={<UserMenuFooter handleLogout={handleLogout} />}
      origin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
});
UserMenu.displayName = 'UserMenu';

const UserMenuTitle: React.FC<{ userData?: UserData }> = ({ userData }) => {
  const { givenName, familyName, email } = userData || {};
  const fullName = getUserFullname(givenName, familyName);
  const formattedUserRole = getFormattedUserRole(userData?.role);

  return (
    <MenuItem sx={{ pointerEvents: 'none', height: 'fit-content' }}>
      <TextListItem title={fullName} paragraph={email || ''} extra={formattedUserRole} />
    </MenuItem>
  );
};

const UserMenuBody: React.FC<{
  handleItemClick: (cb: () => void) => void;
  handleLogout: (cb: () => void) => void;
}> = observer(({ handleItemClick, handleLogout }) => {
  const { popupStore, userStore, authStore } = useAppStore();
  const canManageUsers =
    userStore.userData?.role === UserRole.ADMIN || userStore.userData?.role === UserRole.SUPER_ADMIN;
  const appVersions = rootStore.appVersions;
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  return (
    <>
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          onClick={(e) => {
            if (item.id === 'Delete Account') {
              setIsConfirmPopupOpen(true);
            } else {
              handleItemClick(() => item.onClick(popupStore));
            }
          }}
        >
          <ImgListItem text={item.id} Logo={item.Logo} />
        </MenuItem>
      ))}
      {canManageUsers && (
        <Link to={`/manage/users`}>
          <MenuItem
            key={'manage'}
            onClick={() => {
              handleItemClick(() => {});
            }}
          >
            <ImgListItem text='Manage Users' Logo={ManageAccountsIcon} />
          </MenuItem>
        </Link>
      )}
      <div className='flex flex-col gap-1 pt-2'>
        <p className='px-4 text-xs text-gray-400'>
          Smart Road version: {appVersions[AppNames.SmartRoad] ?? 'N/A'}
        </p>
        {appVersions[AppNames.Integration] && (
          <p className='px-4 text-xs text-gray-400'>
            Integration version: {appVersions[AppNames.Integration]}
          </p>
        )}
      </div>
      {isConfirmPopupOpen && (
        <ConfirmationPopup
          isOpen={isConfirmPopupOpen}
          setIsOpen={setIsConfirmPopupOpen}
          onSubmitHandler={(e) => {
            authStore
              .deleteUserOwnAccount()
              .then((res) => {
                toast.success('Your Account Was Deleted Successfully.');
                handleLogout(e);
              })
              .catch((error) => {
                toast.error('Something WentWrong With Deleting Your Account, Please Try Again Later.');
                console.log(error);
              });
          }}
          confirmMessage={'Deleting your account will block you from using the Smart Road application!'}
          confirmTitle={'Are You Sure You WantTo Delete Your Account?'}
        />
      )}
    </>
  );
});

const UserMenuFooter: React.FC<{ handleLogout: () => void }> = ({ handleLogout }) => {
  return (
    <MenuItem onClick={handleLogout}>
      <ListItemText sx={{ '& span': { fontSize: '14px', fontWeight: 500 } }}>LOG OUT</ListItemText>
    </MenuItem>
  );
};
