import { Alert, Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { postProject } from 'Utils/APIUtils';
import timezones from 'Utils/DefaultValues/timezones.json';
import { isoCountries } from 'Utils/DefaultValues/countries';
import { api } from 'Services/api';
import { collectImageFiles } from 'Components/Forms/ProjectForms/utils/projectFormUtils';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { toast } from 'sonner';
import { isValid } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

const createProjectSchema = object({
  id: string().optional(),
  name: string()
    .min(1, 'Project name is required')
    .regex(/[A-Za-z0-9]/, 'Project name can only contain letters and numbers'),
  countryCode: string().min(1, 'Country code is required'),
  timezoneStr: string()
    .min(1, 'Timezone is required')
    .refine((value) => {
      return isValid(utcToZonedTime(new Date(), value));
    }, 'Timezone is invalid'),
});

const createProjectDefaultValues = {
  id: '',
  name: '',
  countryCode: '',
  timezoneStr: '',
};

type CreateProjectInput = TypeOf<typeof createProjectSchema>;

export const CreateProjectForm: React.FC<FormProps> = ({ onSuccessfulSubmit, onCancel }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    setError,
    handleSubmit,
  } = useForm<CreateProjectInput>({
    resolver: zodResolver(createProjectSchema),
    defaultValues: createProjectDefaultValues,
  });

  const [logoSmall, setLogoSmall] = useState<File | null>(null);
  const [logoMain, setLogoMain] = useState<File | null>(null);
  const [cardImage, setCardImage] = useState<File | null>(null);

  const handleCardImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCardImage(e.target.files[0]);
    }
  };

  const handleLogoSmallChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogoSmall(e.target.files[0]);
    }
  };

  const handleLogoMainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogoMain(e.target.files[0]);
    }
  };

  const onSubmitHandler: SubmitHandler<CreateProjectInput> = (values) => {
    setSubmitError(null);

    const countryName = values.countryCode.split('/')[0].trim();
    const alpha3code = isoCountries.find((country) => country.country === countryName)?.alpha3;

    if (!alpha3code) {
      setError('countryCode', {
        type: 'manual',
        message: 'Country code is invalid',
      });
      return;
    }

    const { id, name, timezoneStr } = values;
    postProject({
      name,
      countryCode: alpha3code,
      timezoneStr,
    })
      .then((res) => {
        if (res.status === 201 && res.data.id) {
          toast.success('Project created');

          // send images to s3
          api.metadata.projects
            .updateProjectImages(res.data.id, collectImageFiles({ logoSmall, logoMain, cardImage }))
            .catch((err) => {
              toast.error(`Updating project images failed`);
              console.error('Updating project images failed', JSON.stringify(err));
            });

          reset(createProjectDefaultValues);
          onSuccessfulSubmit?.();
        } else {
          toast.error('Create project failed');
          setSubmitError('Create project failed');
          throw new Error('Create project failed');
        }
      })
      .catch((err) => {
        toast.error('Create project failed');
        toast.error('Create project failed');
        console.error('Create project failed', JSON.stringify(err));
      });
  };

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Create Project
      </Typography>

      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <TextField
          sx={{ mb: 2 }}
          label='Project Name'
          fullWidth
          error={!!errors['name']}
          helperText={errors['name'] ? errors['name'].message : ''}
          {...register('name')}
        />
        <Autocomplete
          fullWidth
          options={isoCountries}
          getOptionLabel={(option) => `${option.country} / (${option.alpha3})`}
          sx={{ mb: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Country Code'
              error={!!errors['countryCode']}
              helperText={errors['countryCode'] ? errors['countryCode'].message : ''}
              {...register('countryCode')}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={timezones}
          sx={{ mb: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Timezone'
              error={!!errors['timezoneStr']}
              helperText={errors['timezoneStr'] ? errors['timezoneStr'].message : ''}
              {...register('timezoneStr')}
            />
          )}
        />
        <Stack direction='row' spacing={2} sx={{ mb: 1 }}>
          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: logoSmall ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Small Logo</Typography>
            <input type='file' hidden onChange={handleLogoSmallChange} />
          </Button>

          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: logoMain ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Main Logo</Typography>
            <input type='file' hidden onChange={handleLogoMainChange} />
          </Button>

          <Button
            component='label'
            sx={{
              flex: 1,
              backgroundColor: cardImage ? theme.palette.accent.secondary.main : 'inherit',
            }}
          >
            <Typography>Project Card</Typography>
            <input type='file' hidden onChange={handleCardImageChange} />
          </Button>
        </Stack>
        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {logoSmall?.name || 'No file selected'}
          </span>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {logoMain?.name || 'No file selected'}
          </span>
          <span style={{ flex: 1, fontSize: '9px', textAlign: 'center' }}>
            {cardImage?.name || 'No file selected'}
          </span>
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons onCancel={onCancel} submitLabel='Create Project' />
      </Box>
    </>
  );
};
CreateProjectForm.displayName = 'CreateProjectForm';
