import { DeviceChargingActivity } from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import moment from 'moment';
import { CSSProperties } from 'react';
import NotChargingIcon from 'Utils/Images/DepotIcons/dark_triangle_down.svg';
import VuChargingIcon from 'Utils/Images/DepotIcons/blue_triangle_up.svg';
import VuWPTErrorIcon from 'Utils/Images/DepotIcons/alert-triangle-down.svg';
import OfflineIcon from 'Utils/Images/DepotIcons/vu-state-disconnected.svg';
import VacantIcon from 'Utils/Images/DepotIcons/map-spot-vacant.svg';
import ActiveChargingIcon from 'Utils/Images/DepotIcons/map-spot-charging.svg';
import OccupiedChargingIcon from 'Utils/Images/DepotIcons/map-spot-occupied.svg';
import WarningChargingSpotIcon from 'Utils/Images/DepotIcons/map-spot-error.svg';
import OfflineChargingIcon from 'Utils/Images/DepotIcons/map-spot-disconnected.svg';
import ManualModeIcon from 'Utils/Images/DepotIcons/map-spot-setup.svg';
import ParkingSpotOff from 'Utils/Images/DepotIcons/map-spot-off.svg';
import busIcon from 'Utils/Images/DepotIcons/depot-bus-icon.svg';
import truckIcon from 'Utils/Images/DepotIcons/depot-truck-icon.svg';
import carIcon from 'Utils/Images/DepotIcons/depot-car-icon.svg';
import { Circle, Fill, Stroke, Icon, Style, Text } from 'ol/style';
import ShouldReparkBadge from 'Utils/Images/DepotIcons/map-vehicle-should-repark-badge.svg';
import OutOfServiceBadge from 'Utils/Images/DepotIcons/map-vehicle-out-of-service-badge.svg';
import DashboardVacantIcon from 'Utils/Images/DepotIcons/vacant-charging-spot-icon.svg';
import DashboardActiveChargingIcon from 'Utils/Images/DepotIcons/active-charging-spot-icon.svg';
import DashboardOccupiedChargingIcon from 'Utils/Images/DepotIcons/occupied-charging-spot-icon.svg';
import DashboardWarningChargingSpotIcon from 'Utils/Images/DepotIcons/warning-charging-spot-icon.svg';
import DashboardOfflineChargingIcon from 'Utils/Images/DepotIcons/parking-state-disconnected.svg';
import DashboardManualModeIcon from 'Utils/Images/DepotIcons/parking-state-setup.svg';
import DashboardPausedIcon from 'Utils/Images/DepotIcons/parking-state-off-by-gui.svg';
import FullyChargedIcon from 'Utils/Images/DepotIcons/vu-fully-charged-icon.svg';
import MapFullyChargedIcon from 'Utils/Images/DepotIcons/map-vehicle-fully-charged.svg';
import MapNotChargingIcon from 'Utils/Images/DepotIcons/map-vehicle-not-charging.svg';
import { format, isAfter, set, intervalToDuration } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { add } from 'date-fns';
import { ETF_DEADLINE_TIME } from 'Screens/DepotDashboard/depotConfig';
import { isFalseyButNotZero } from 'Utils/utils';
import { addHours, addMinutes, eachMinuteOfInterval, startOfDay } from 'date-fns/esm';
import { CONNECTION_STATUS } from '@electreon_ui/shared/constants/constants';
import AquariumChargingIcon from 'Utils/Images/DepotIcons/aquarium-blue-up-icon.svg';
import AquariumDisChargingIcon from 'Utils/Images/DepotIcons/aquarium-black-down-icon.svg';
import AquariumWPTErrorIcon from 'Utils/Images/DepotIcons/aquarium-error-icon.svg';
import AquariumDisconnectedIcon from 'Utils/Images/DepotIcons/aquarium-disconnected-icon.svg';
import AquariumFullyChargedIcon from 'Utils/Images/DepotIcons/aquarium-fully-charged-icon.svg';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import {
  ManagementUnitModelRefined,
  OcppChargerModelRefined,
  OcppChargerWithParkingSpotsRefined,
} from '@electreon_ui/shared/types/globals';
import MuChargingIcon from 'Utils/Images/DepotIcons/lightning-outlined.svg?raw';
import { DepotTypes } from 'MobxStores/DepotStore';
import { RoadSection, ProjectDeployment } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  MapPolygon,
  OcppMapFeature,
  ParkingSpotState,
  ParkingSpotWithState,
  VUWithState,
} from 'MobxStores/Types/DepotTypes';
import { StatusNotificationRequestV16 } from '@cshil/ocpp-tools';
import _ from 'lodash';
import { DeviceTwinChangesSocketMessage } from '@electreon_ui/shared/types/socketMessageTypes';
import DeviceLockStore from '@electreon_ui/shared/stores/deviceLockStore/DeviceLockStore';

const TWO_MINUTES = 2 * 60 * 1000;
const getWarningCircle = (color: string) => ({
  borderRadius: '50%',
  outline: `2px solid ${color}`,
  outlineOffset: '-1px',
});

type ChargingActivityTimeseriesArgs = {
  data: Array<DeviceChargingActivity>;
  startDate: Date;
  endDate: Date;
  timezoneStr: string;
};

const getChargingActivityTimeseries = ({
  data,
  startDate,
  endDate,
  timezoneStr,
}: ChargingActivityTimeseriesArgs) => {
  const chargeActive: { timestamp: number; chargeActive: boolean }[] = [];
  const chargeMap: { [key: number]: boolean } = {};

  // Preprocessing data and populating the hash map
  data.forEach((item) => {
    const timeStart = utcToZonedTime(item.timeStart || new Date(), timezoneStr);
    const timeEnd = utcToZonedTime(item.timeEnd || new Date(), timezoneStr);

    eachMinuteOfInterval({ start: timeStart, end: timeEnd }).forEach((minute) => {
      chargeMap[minute.getTime()] = true;
    });
  });

  const startHour = addHours(startOfDay(startDate), 6);
  const endHour = addHours(startOfDay(endDate), 30);
  const step = 1;

  for (let timestamp = startHour; timestamp <= endHour; timestamp = addMinutes(timestamp, step)) {
    const chargeActiveAtTime = !!chargeMap[timestamp.getTime()];

    chargeActive.push({
      timestamp: timestamp.getTime(),
      chargeActive: chargeActiveAtTime,
    });
  }
  return chargeActive;
};

export const parseChargingActivityTotals = ({
  data,
  startDate,
  endDate,
  timezoneStr,
}: {
  data: Array<DeviceChargingActivity>;
  startDate: Date;
  endDate: Date;
  timezoneStr: string;
}): {
  sessionCount: number;
  totalDuration: string; // formatted as HH:mm:ss
  totalEnergy: number; // in kWh
  timeSeries: { timestamp?: number; chargeActive?: boolean }[];
} => {
  const { sessionCount, totalDuration, totalEnergy } = data.reduce(
    (acc, curr) => {
      const totalEnergy = acc.totalEnergy + (curr.energy || 0);
      const totalDuration = moment
        .duration(acc.totalDuration)
        .add(moment.duration(moment(curr.timeEnd).diff(moment(curr.timeStart))))
        .valueOf() as number;
      return {
        sessionCount: acc.sessionCount + 1,
        totalDuration,
        totalEnergy,
      };
    },
    { sessionCount: 0, totalDuration: 0, totalEnergy: 0 }
  );

  const parsedTimeSeries = getChargingActivityTimeseries({
    data,
    startDate: new Date(startDate?.getTime()),
    endDate: new Date(endDate?.getTime()),
    timezoneStr,
  });

  return {
    sessionCount,
    totalDuration: moment.utc(totalDuration).format('HH:mm:ss'),
    totalEnergy: totalEnergy / 1000,
    timeSeries: parsedTimeSeries,
  };
};

export const isVuOffline = (vehicle: VUWithState) => {
  const isDisconnectedMoreThanTwoMinutes = vehicle?.lastDisconnect
    ? Date.now() - vehicle.lastDisconnect > TWO_MINUTES
    : true;
  return vehicle.connectionStatus === CONNECTION_STATUS.DISCONNECTED && isDisconnectedMoreThanTwoMinutes;
};

export const getVehicleDisplayOptions = ({
  vehicle,
  timezoneStr,
  isMapIcon,
  showNotFullInTime = false,
}: {
  vehicle: VUWithState;
  timezoneStr: string;
  isMapIcon?: boolean;
  showNotFullInTime?: boolean;
}): {
  icon: string;
  alt: string;
  title: string;
  style: CSSProperties;
} => {
  const vehicleState = vehicle.state;
  const isCharging = vehicleState?.charging;
  const hasWPTError = vehicleState?.wptError;
  const isOffline = isVuOffline(vehicle);
  const isFullyCharged = isVehicleFullyCharged(vehicle);
  const isNotFullyChargedInTime = isNotChargedInTime({
    now: new Date(),
    targetTime: ETF_DEADLINE_TIME,
    timeToFullCharge: vehicle.state?.timeToFullCharge,
    timezoneStr,
  });
  const isOutOfService = vehicle?.state?.outOfService;
  const shouldAddWarningCircle =
    vehicle?.state?.shouldRepark || (isNotFullyChargedInTime && showNotFullInTime && isCharging);
  const circleColor = isOutOfService
    ? theme.palette.accent.red.dark
    : vehicle?.state?.shouldRepark || isNotFullyChargedInTime
      ? theme.palette.accent.orange.light
      : 'transparent';
  const stateStyles = shouldAddWarningCircle ? getWarningCircle(circleColor) : {};

  switch (true) {
    case isOffline:
      return {
        icon: OfflineIcon,
        alt: 'Vehicle offline icon',
        title: 'Offline',
        style: { ...stateStyles, borderRadius: '50%' },
      };
    case isCharging:
      return {
        icon: VuChargingIcon,
        alt: 'Vehicle charging icon',
        title: 'Charging',
        style: { ...stateStyles },
      };
    case hasWPTError:
      return {
        icon: VuWPTErrorIcon,
        alt: 'Vehicle WPT error icon',
        title: 'WPT Error',
        style: { ...stateStyles },
      };
    case isFullyCharged:
      return {
        icon: isMapIcon ? MapFullyChargedIcon : FullyChargedIcon,
        alt: 'Vehicle fully charged icon',
        title: 'Fully Charged',
        style: { ...stateStyles },
      };
    default:
      return {
        icon: isMapIcon ? MapNotChargingIcon : NotChargingIcon,
        alt: 'Vehicle fully charged icon',
        title: 'Not Charging',
        style: { ...stateStyles },
      };
  }
};

const createOpenLayersRing = (color: string, radius: number = 13, strokeWidth: number = 2) =>
  new Style({
    image: new Circle({
      radius,
      fill: new Fill({ color: 'transparent' }),
      stroke: new Stroke({ color, width: strokeWidth }),
    }),
  });

export const getVehicleOpenLayerStyle = ({
  vehicle,
  timezoneStr,
  showNotFullInTime,
}: {
  vehicle: VUWithState;
  timezoneStr: string;
  showNotFullInTime?: boolean;
}) => {
  const { icon, alt, title, style } = getVehicleDisplayOptions({
    vehicle,
    timezoneStr,
    isMapIcon: true,
    showNotFullInTime,
  });
  const vehicleStateIcon = new Icon({
    src: icon,
    scale: 0.85,
  });
  const shouldReparkIcon = new Icon({
    src: ShouldReparkBadge,
    scale: 0.7,
  });
  const outOfServiceIcon = new Icon({
    src: OutOfServiceBadge,
    scale: 0.7,
  });

  const shouldRepark = vehicle?.state?.shouldRepark;
  const isOutOfService = vehicle?.state?.outOfService;
  const isCharging = vehicle?.state?.charging;
  const isNotFullyChargedInTime = isNotChargedInTime({
    now: new Date(),
    targetTime: ETF_DEADLINE_TIME,
    timeToFullCharge: vehicle.state?.timeToFullCharge,
    timezoneStr,
  });
  const shouldAddWarningCircle = shouldRepark || (isNotFullyChargedInTime && showNotFullInTime && isCharging);

  const combinedStyle = isOutOfService
    ? [new Style({ image: vehicleStateIcon }), new Style({ image: outOfServiceIcon })]
    : shouldRepark
      ? [
          new Style({ image: vehicleStateIcon }),
          createOpenLayersRing(theme.palette.accent.orange.light),
          new Style({ image: shouldReparkIcon }),
        ]
      : shouldAddWarningCircle
        ? [new Style({ image: vehicleStateIcon }), createOpenLayersRing(theme.palette.accent.orange.light)]
        : new Style({ image: vehicleStateIcon });

  return combinedStyle;
};

export const getPolygonOpenLayerStyle = ({ polygon }: { polygon: MapPolygon }) => {
  const { fillColor, strokeColor, strokeWidth, fillOpacity, strokeOpacity } = polygon;
  const style = new Style({
    fill: new Fill({
      color: fillColor || `rgba(0, 179, 215, ${fillOpacity || 0.45})`,
    }),
    stroke: new Stroke({
      color: strokeColor || `rgba(0, 178, 215, ${strokeOpacity || 1})`,
      width: strokeWidth || 2,
    }),
  });

  return style;
};

export const getAquariumVehicleIcon = ({
  vehicle,
  showNotFullInTime,
  isNotFullyChargedInTime,
}: {
  vehicle: VUWithState;
  showNotFullInTime: boolean;
  isNotFullyChargedInTime: boolean | null;
}) => {
  const isCharging =
    vehicle.state?.charging && !(vehicle.connectionStatus === CONNECTION_STATUS.DISCONNECTED);
  const hasWPTError = vehicle.state?.wptError;
  const isFullyCharged = isVehicleFullyCharged(vehicle);
  const shouldAddWarningCircle =
    vehicle?.state?.shouldRepark ||
    vehicle?.state?.outOfService ||
    (isNotFullyChargedInTime && showNotFullInTime && isCharging);
  const circleColor = vehicle?.state?.outOfService
    ? theme.palette.accent.red.dark
    : vehicle?.state?.shouldRepark || isNotFullyChargedInTime
      ? theme.palette.accent.orange.light
      : 'transparent';
  const stateStyles = shouldAddWarningCircle ? getWarningCircle(circleColor) : {};

  switch (true) {
    case isCharging:
      return {
        icon: AquariumChargingIcon,
        style: { ...stateStyles },
      };
    case hasWPTError:
      return {
        icon: AquariumWPTErrorIcon,
        style: { ...stateStyles },
      };
    case vehicle?.connectionStatus === CONNECTION_STATUS.DISCONNECTED:
      return {
        icon: AquariumDisconnectedIcon,
        style: { ...stateStyles },
      };
    case isFullyCharged:
      return {
        icon: AquariumFullyChargedIcon,
        style: { ...stateStyles },
      };
    default:
      return {
        icon: AquariumDisChargingIcon,
        style: { ...stateStyles },
      };
  }
};

export function getParkingSpotStateIcon(
  parkingSpot: ParkingSpotWithState | null,
  chargerState: DepotTypes.MuState,
  isMuInManualMode: boolean,
  isMapIcon?: boolean
) {
  if (!parkingSpot) return !isMapIcon ? DashboardVacantIcon : VacantIcon;

  // const isNonOperational =
  //   device?.state?.nonOperational ||
  //   (!Object.keys(device?.state || {}).length &&
  //     device?.muData?.connectionStatus === CONNECTION_STATUS.CONNECTED);

  if (chargerState?.connectionStatus === CONNECTION_STATUS.DISCONNECTED) {
    return !isMapIcon ? DashboardOfflineChargingIcon : OfflineChargingIcon;
  } else if (parkingSpot?.state?.charging) {
    return !isMapIcon ? DashboardActiveChargingIcon : ActiveChargingIcon;
  } else if (parkingSpot.state?.paused) {
    return !isMapIcon ? DashboardPausedIcon : ParkingSpotOff;
  } else if (isMuInManualMode) {
    return !isMapIcon ? DashboardManualModeIcon : ManualModeIcon;
  } else if (parkingSpot?.state?.occupied) {
    return !isMapIcon ? DashboardOccupiedChargingIcon : OccupiedChargingIcon;
  } else if (parkingSpot?.state?.vacant) {
    return !isMapIcon ? DashboardVacantIcon : VacantIcon;
  } else if (parkingSpot?.state?.nonOperational) {
    return !isMapIcon ? DashboardWarningChargingSpotIcon : WarningChargingSpotIcon;
  } else {
    return !isMapIcon ? DashboardVacantIcon : VacantIcon;
  }
}

function getOCPPParkingSpotStateIcon(chargerState?: ParkingSpotState, isMapIcon?: boolean) {
  const { charging, nonOperational, occupied, paused, vacant } = chargerState || {};

  if (charging) {
    return !isMapIcon ? DashboardActiveChargingIcon : ActiveChargingIcon;
  } else if (paused) {
    return !isMapIcon ? DashboardPausedIcon : ParkingSpotOff;
  } else if (vacant) {
    return !isMapIcon ? DashboardVacantIcon : VacantIcon;
  } else if (occupied) {
    return !isMapIcon ? DashboardOccupiedChargingIcon : OccupiedChargingIcon;
  } else if (nonOperational) {
    return !isMapIcon ? DashboardWarningChargingSpotIcon : WarningChargingSpotIcon;
  } else {
    return !isMapIcon ? DashboardVacantIcon : VacantIcon;
  }
}

function getMuStateIcon(state?: DepotTypes.MuState, isMapIcon?: boolean): string {
  const svgTemplate = MuChargingIcon; // imported as ?raw

  // Parse the SVG string into an SVG element
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgTemplate, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Set dynamic properties
  const isDisconnected = state?.connectionStatus === CONNECTION_STATUS.DISCONNECTED;
  const isCharging = state?.isCharging;
  const fill = isCharging ? theme.palette.accent.secondary.main : '#fff';
  const stroke = isDisconnected
    ? theme.palette.accent.gray['200']
    : isCharging
      ? theme.palette.accent.secondary.main
      : '#fff';
  const backgroundColor = isDisconnected ? theme.palette.accent.gray['200'] : theme.palette.accent.gray.dark;
  // const borderColor = isDisconnected ? theme.palette.accent.gray['200'] : theme.palette.accent.gray.dark;
  const borderColor = 'white';

  if (svgElement.querySelector('rect')) {
    svgElement.querySelector('rect')!.setAttribute('fill', backgroundColor);
    svgElement.querySelector('rect')!.setAttribute('stroke', borderColor);
    svgElement.querySelector('rect')!.setAttribute('stroke-width', '5');
  }

  svgElement.querySelectorAll('path').forEach((path) => {
    path.setAttribute('fill', fill);
    path.setAttribute('stroke', stroke);
  });

  // Serialize the modified SVG element back to a string
  const serializer = new XMLSerializer();
  const serializedSvg = serializer.serializeToString(svgElement);

  // Convert to a data URL
  const svgDataUrl = `data:image/svg+xml,${encodeURIComponent(serializedSvg)}`;

  return svgDataUrl;
}

export const getParkingSpotOpenLayerStyle = (
  parkingSpot: ParkingSpotWithState,
  muState: DepotTypes.MuState,
  isMuInManualMode: boolean,
  rotation = 0,
  shortId?: number | string,
  isFlipped?: boolean
) => {
  const iconSrc = getParkingSpotStateIcon(parkingSpot, muState, isMuInManualMode, true);
  const scale = 0.8;
  const image = new Icon({
    src: iconSrc,
    scale: scale,
    rotation: rotation,
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
  });

  const iconSize = image.getSize();
  const offsetX = 0;
  const offsetY = isFlipped ? iconSize?.[1] * scale * 0.5 + 10 : -iconSize?.[1] * scale * 0.5 - 10;

  const text = new Text({
    text: shortId?.toString() || '',
    font: 'bold 12px Urbanist',
    rotation: (-rotation * Math.PI) / 180 + 0.15,
    textAlign: 'center',
    textBaseline: 'middle',
    offsetX: offsetX,
    offsetY: offsetY,
  });

  return new Style({
    image: image,
    text: text,
  });
};

export const getOCPPSpotOpenLayerStyle = (ocppMapFeature: OcppMapFeature) => {
  const { mapData, chargerMetadata, state } = ocppMapFeature;
  const parsedRotation = degreesToRadians(mapData.rotation || 0);
  const connectorState = state?.connectors?.[mapData.ocppConnectorId || 0];
  const iconSrc = getOCPPParkingSpotStateIcon(connectorState, true);
  const scale = 0.8;
  const image = new Icon({
    src: iconSrc,
    scale: scale,
    rotation: parsedRotation,
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
  });

  const isFlipped = mapData.iconPlacement === 'top';
  const iconSize = image.getSize();
  const offsetX = 0;
  const offsetY = isFlipped ? iconSize?.[1] * scale * 0.5 + 10 : -iconSize?.[1] * scale * 0.5 - 10;

  const text = new Text({
    text: mapData.shortId?.toString() || '',
    font: 'bold 12px Urbanist',
    rotation: (-parsedRotation * Math.PI) / 180,
    textAlign: 'center',
    textBaseline: 'middle',
    offsetX: offsetX,
    offsetY: offsetY,
  });

  return new Style({
    image: image,
    text: text,
  });
};

export const getMuOpenLayerStyle = (muData: {
  metaData: ManagementUnitModelRefined;
  state?: DepotTypes.MuState;
}) => {
  const iconSrc = getMuStateIcon(muData.state, true);
  const scale = 0.8;
  const image = new Icon({
    src: iconSrc,
    scale: scale,
    // rotation: mu.rotation,
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
  });

  // const iconSize = image.getSize();
  // const offsetX = 0;
  // const offsetY = -iconSize?.[1] * scale * 0.5 - 10;

  // const text = new Text({
  //   // text: mu.shortId?.toString() || '',
  //   text: muData.metaData.id?.toString().slice(-2),
  //   font: 'bold 12px Urbanist',
  //   // rotation: (-mu.rotation * Math.PI) / 180 + 0.15,
  //   textAlign: 'center',
  //   textBaseline: 'middle',
  //   offsetX: offsetX,
  //   offsetY: offsetY,
  // });

  return new Style({
    image: image,
    // text: text,
  });
};

export const getRoadSectionOpenLayerStyle = (roadSection: {
  metaData: RoadSection;
  state?: DepotTypes.RoadSectionState;
}) => {
  const isCharging = Boolean(roadSection.state?.isCharging);
  return new Style({
    stroke: new Stroke({
      // TODO: Handle charging states
      color: isCharging ? theme.palette.accent.secondary.main : theme.palette.accent.gray['600'],
      width: 8,
    }),
  });
};

export const getStartAndEndTimeForDay = (startDate: Date, endDate?: Date) => {
  const startTime = moment(startDate).startOf('day').add(6, 'hours').format();
  const endTime = moment(endDate || startDate)
    .startOf('day')
    .add(30, 'hours')
    .format();
  return { startTime, endTime };
};

export const getPopupHeaderIcon = (vehicleType?: string) =>
  vehicleType === 'BUS' ? busIcon : vehicleType === 'TRUCK' ? truckIcon : carIcon;

export const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const getTimeToFullChargeDisplay = (
  startTime?: number | null,
  timeToFullCharge?: { hours: number | null; minutes: number | null } | null,
  timezoneStr?: string
) => {
  if (
    !startTime ||
    !timeToFullCharge ||
    !timezoneStr ||
    isFalseyButNotZero(timeToFullCharge.minutes) ||
    isFalseyButNotZero(timeToFullCharge.hours)
  )
    return null;
  const now = new Date();
  const hours = timeToFullCharge.hours;
  const minutes = timeToFullCharge.minutes;
  const totalMinutes = hours * 60 + minutes;
  const roundedMinutes = Math.ceil(totalMinutes / 5) * 5;
  const roundedNowMinutes = Math.ceil(now.getMinutes() / 5) * 5;
  now.setMinutes(roundedNowMinutes, 0, 0);
  return format(utcToZonedTime(now, timezoneStr).getTime() + roundedMinutes * 60 * 1000, 'HH:mm:ss');
};

interface IsNotChargedInTimeParams {
  now: Date;
  targetTime: string;
  timeToFullCharge?: { hours: number | null; minutes: number | null };
  timezoneStr: string;
}

export const isNotChargedInTime = ({
  now,
  targetTime,
  timeToFullCharge,
  timezoneStr,
}: IsNotChargedInTimeParams): boolean | null => {
  if (
    !timeToFullCharge ||
    isFalseyButNotZero(timeToFullCharge.minutes) ||
    isFalseyButNotZero(timeToFullCharge.hours)
  )
    return null;

  const targetDateTime = set(now, {
    hours: parseInt(targetTime.split(':')[0]),
    minutes: parseInt(targetTime.split(':')[1]),
    seconds: 0,
  });
  const nowInTimeZone = utcToZonedTime(now, timezoneStr);
  const targetDateTimeInTimeZone = utcToZonedTime(targetDateTime, timezoneStr);

  // If now is after the target time, set target to next day
  if (isAfter(nowInTimeZone, targetDateTimeInTimeZone)) {
    targetDateTimeInTimeZone.setDate(targetDateTimeInTimeZone.getDate() + 1);
  }
  const etfDate = add(now, { hours: timeToFullCharge.hours, minutes: timeToFullCharge.minutes });

  return isAfter(etfDate, targetDateTimeInTimeZone);
};

export function isVehicleFullyCharged(vuDevice: VUWithState): boolean {
  if (vuDevice.state?.charging) return false;
  if (!vuDevice.state?.batteryLevel) return false;
  if (vuDevice.connectionStatus === CONNECTION_STATUS.DISCONNECTED) return false;
  const maxStateOfChargingPercentages =
    vuDevice.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Max_State_Of_Charging_Percentages;
  const maxChargingPercentagesHysteresis =
    vuDevice.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Max_Charging_Percentages_Hysteresis ||
    0;
  if (!maxStateOfChargingPercentages) return false;
  const isMaxed =
    vuDevice.state?.batteryLevel >= maxStateOfChargingPercentages - maxChargingPercentagesHysteresis;
  return isMaxed;
}

type TooltipTextParams = {
  device: VUWithState;
  showPower: boolean;
  showNotFullInTime: boolean;
  startTime?: number;
  timezoneStr: string;
};

export const getVehicleTooltipTexts = ({
  device,
  showPower,
  showNotFullInTime,
  startTime,
  timezoneStr,
}: TooltipTextParams) => {
  const displayName = device.name;
  const displaySoc =
    device.state?.batteryLevel !== null ? `SOC: ${device.state?.batteryLevel}%` : 'SOC: Unknown';
  const displayPower =
    showPower && device.state?.charging
      ? device.state?.power
        ? `Power: ${(device.state?.power / 1000).toFixed(2)} kW`
        : 'Power: Not Charging'
      : null;

  const etf = device.state?.timeToFullCharge;
  const isValidEtf = !isFalseyButNotZero(etf?.hours) && !isFalseyButNotZero(etf?.minutes);

  const isFullInUnder15Minutes =
    !isVuOffline(device) &&
    device.state?.charging &&
    isValidEtf &&
    device.state?.timeToFullCharge?.hours === 0 &&
    device.state?.timeToFullCharge?.minutes! <= 15;

  const displayEtf =
    isValidEtf && device.state?.charging && showNotFullInTime
      ? `ETF: ${
          isFullInUnder15Minutes
            ? '< 15'
            : getTimeToFullChargeDisplay(startTime, device.state?.timeToFullCharge, timezoneStr)
        }`
      : null;

  return {
    displayName,
    displaySoc,
    displayPower,
    displayEtf,
  };
};

export function calcDurationSince(startTime?: number) {
  if (!startTime) return null;
  const now = new Date();
  const durationMs = now.getTime() - new Date(startTime).getTime();
  const durationHours = durationMs / 3600000;
  const durationObj = intervalToDuration({ start: new Date(startTime), end: now });
  const duration = `${String(durationObj.hours).padStart(2, '0')}:${String(durationObj.minutes).padStart(2, '0')}:${String(durationObj.seconds).padStart(2, '0')}`;

  return {
    duration,
    durationHours,
    durationMs,
  };
}

export function addMillisecondsToDuration(duration?: string, addMs?: number): string | null {
  if (!duration) return null;
  if (!addMs) return duration;

  const [hours, minutes, seconds] = duration.split(':').map(Number);

  let dateTime = new Date();
  dateTime.setHours(hours, minutes, seconds, 0);

  dateTime = new Date(dateTime.getTime() + addMs);

  const formattedTime = `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}:${dateTime.getSeconds().toString().padStart(2, '0')}`;
  return formattedTime;
}

export const moreThanOneVehicle = (vehicle: VUWithState, allVehicles: VUWithState[]) => {
  const vehiclesWithSameData = allVehicles.filter(
    (veh) =>
      veh.state?.batteryLevel === vehicle.state?.batteryLevel &&
      veh.state?.charging === vehicle.state?.charging
  );

  return vehiclesWithSameData.length > 1 ? vehiclesWithSameData : null;
};

export const isVehiclesHasErrorState = (vehicles: VUWithState[] | null) => {
  if (!vehicles) return false;
  return !!vehicles.find(
    (vehicle) => vehicle.state?.wptError || vehicle.state?.shouldRepark || vehicle.state?.outOfService
  );
};

/** Returns true if current time is before the required hour in the given timezone */
const currentTimeIsBefore = (requiredHour: number, timezoneStr: string): boolean => {
  const now = utcToZonedTime(new Date(), timezoneStr);
  const hours = now.getHours();
  return hours < requiredHour;
};

/** Returns the default date for the given timezone */
export const getDefaultDate = (timezoneStr: string): Date => {
  const now = utcToZonedTime(new Date(), timezoneStr);
  return currentTimeIsBefore(12, timezoneStr) ? new Date(now.setDate(now.getDate() - 1)) : now;
};

export const filterLowAvgValues = (chargingSessions: Array<DeviceChargingActivity>) =>
  chargingSessions.filter((session) => session?.avgPower && session?.avgPower > 1000);

export const ocppConnectorStatusToDepotState = new Map<
  StatusNotificationRequestV16['status'] | undefined,
  ParkingSpotState
>([
  ['Unavailable', { paused: true }],
  ['SuspendedEV', { paused: true }],
  ['SuspendedEVSE', { paused: true }],
  ['Finishing', { occupied: true }],
  ['Preparing', { occupied: true }],
  ['Reserved', { occupied: true }],
  ['Available', { vacant: true }],
  ['Charging', { charging: true }],
  ['Faulted', { nonOperational: true }],
  ['Unavailable', { nonOperational: true }],
  [undefined, {}],
]);

const isLockMessage = (messageData: {
  deviceId: string;
  socketMessageData: DeviceTwinChangesSocketMessage;
}) => {
  return Boolean(
    messageData?.socketMessageData?.tags?.lockInfo &&
      !(
        Object.keys(messageData.socketMessageData?.tags).includes('numOfOpenRooms') &&
        Object.keys(messageData.socketMessageData?.tags).length === 1
      )
  );
};

const isUnlockMessage = (messageData: {
  deviceId: string;
  socketMessageData: DeviceTwinChangesSocketMessage;
}) => {
  if (!messageData?.socketMessageData?.tags) return false;
  const isUnlock = Boolean(messageData?.socketMessageData?.lockInfo) === false;
  return isUnlock;
  // return Boolean(messageData?.socketMessageData?.tags?.lockInfo) === false;
};

export const handleDeviceTwinChangesMessage = (
  messageData: { deviceId: string; socketMessageData: DeviceTwinChangesSocketMessage },
  deviceLockStore: DeviceLockStore,
  sessionId?: string
) => {
  if (!sessionId) {
    console.error('No session id for device twin changes message');
    return;
  }
  const deviceId = messageData.deviceId;

  // if socketMessageData has a tags property, its either a lock or unlock message
  if (messageData?.socketMessageData?.tags) {
    if (isLockMessage(messageData)) {
      // lock message
      const messageLockInfo = messageData?.socketMessageData?.tags?.lockInfo;
      deviceLockStore.addLockedDevice(deviceId, messageLockInfo, sessionId);
    } else if (isUnlockMessage(messageData)) {
      // unlock message
      deviceLockStore.removeLockedDevice(deviceId);
    }
  }
};

export const getParkingSpotIdToOcppChargerMap = (deploymentData?: ProjectDeployment) => {
  const parkingSpotIdToOcppChargerMap = new Map<number, OcppChargerModelRefined | undefined>();
  deploymentData?.staticDeployment?.depots?.forEach((depot) => {
    depot?.ocppChargers?.forEach((ocppWithParkingSpots) => {
      if (!ocppWithParkingSpots.ocppCharger?.id || !ocppWithParkingSpots.parkingSpots) return;
      ocppWithParkingSpots.parkingSpots?.forEach((parkingSpot) => {
        if (!parkingSpot.id) return;
        parkingSpotIdToOcppChargerMap.set(
          parkingSpot.id,
          ocppWithParkingSpots.ocppCharger as OcppChargerModelRefined
        );
      });
    });
  });
  return parkingSpotIdToOcppChargerMap;
};

export const getDepotIdToOcppChargersMap = (deploymentData?: ProjectDeployment) => {
  const depotIdToOcppChargersMap = new Map<string, OcppChargerWithParkingSpotsRefined[]>();
  deploymentData?.staticDeployment?.depots?.forEach((depot) => {
    if (!depot.id || !depot.ocppChargers) return;
    depotIdToOcppChargersMap.set(
      String(depot.id),
      depot.ocppChargers as OcppChargerWithParkingSpotsRefined[]
    );
  });
  return depotIdToOcppChargersMap;
};
