import {
  DataGraphParameterType,
  DataGraphParametersCategoryModel,
  DeviceEventLog,
  ParametersModel,
  TimeSeriesTelemetryModelDeviceEventLog,
} from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { ValuesOf } from '@electreon_ui/shared/types/globals';
import { sharedUrlParamsSchema } from 'MobxStores/utils/typeCheckers';
import { z } from 'zod';
import { O } from 'ts-toolbelt';
import { LabelPosition } from 'recharts/types/component/Label';

type DeviceId = string;
export type ExpandedParams = Record<DeviceId, DataGraphParametersCategoryModelRefined>;

export type AnalyticsDataGraphRequest = {
  deviceId: string;
  param: DataGraphParameterType;
  filters?:
    | {
        [key: string]: string | number | any /* This is typed as object on the BE for some reason */;
      }
    | undefined;
}[];

export type AnalyticsEventSummary = {
  [deviceId: string]: {
    [eventCategory: string]: {
      [eventType: string]: number;
    };
  };
};

export const EVENT_CATEGORIES = {
  INFO: 'EVENTS_TYPE_INFO',
  WARNING: 'EVENTS_TYPE_WARNING',
  ERROR: 'EVENTS_TYPE_ERROR',
  FPGA: 'EVENTS_TYPE_FPGA',
} as const;

export type EventCategory = ValuesOf<typeof EVENT_CATEGORIES>;

export const isEventCategory = (value: string): value is EventCategory => {
  return Object.values(EVENT_CATEGORIES).includes(value as EventCategory);
};

type CategoryAndTypeSelectionData = {
  isEventCategorySelected: boolean;
  [eventType: string]: boolean;
};

type CategoryAndTypeSelection = {
  [key in EventCategory]?: CategoryAndTypeSelectionData;
};

export type SelectedEventCategoriesAndTypes = {
  [deviceId: string]: CategoryAndTypeSelection | undefined;
};

export type FormattedEventGraphPoint = {
  deviceId: string;
  x?: number;
  y?: number;
  name?: string;
  fill?: string;
} & DeviceEventLog;

export interface FormattedTimeSeriesTelemetryModelDeviceEventLog
  extends Omit<TimeSeriesTelemetryModelDeviceEventLog, 'timeSeries'> {
  timeSeries?: Array<FormattedEventGraphPoint | null>;
}

export type StickyRefLineItem = {
  currentY?: number;
  currentValue?: number | string;
  currentTimestamp?: number;
  activeDotYCoordRef?: React.MutableRefObject<number | undefined>;
};

export type DutyCycleItem = {
  points: Array<number>;
  cycleInPercentage?: string;
  labelPosition?: LabelPosition;
};

export type DataGraphParametersCategoryModelRefined = O.P.Update<
  DataGraphParametersCategoryModel,
  ['filters'],
  Filters
>;

export type StickyMarkersDataKey = `${string}_${string}_${string}`;

export type SharedUrlData = z.infer<typeof sharedUrlParamsSchema>;

export type Filters = Record<string, string | number>;

export type StickyRefLineData = Record<string, StickyRefLineItem[]>;

export type DutyCycleData = Record<string, DutyCycleItem>;
