import { useEffect, useState } from 'react';
import { Alert, Box, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { PosModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';

import { createPOSDevice } from 'Components/Forms/FormsAPIs/api';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';

import { prefixStyles } from './utils/InputStyles';
import { getNextPOSId } from './utils/CreateDeviceUtils';

const createPosSchema = z.object({
  deviceName: z
    .string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: z
    .string()
    .min(1, 'Device ID is required')
    .refine(async (value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  projectName: z.string().optional(),
  prefix: z.string().min(1, 'Prefix is required'),
});

const createPosDefaultValues = {
  deviceName: '',
  deviceId: '',
  deviceSubType: '',
  projectName: '',
  prefix: 'PS',
};

type CreatePosInput = z.TypeOf<typeof createPosSchema>;

export const CreatePosForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const mutation = useMutation({
      mutationFn: (device: PosModel) => createPOSDevice(device),
      onSuccess: (res) => {
        if (res.status === 201) {
          toast.success('POS device created');
          reset(createPosDefaultValues);
          onSuccessfulSubmit?.();
        } else {
          toast.error('POS device creation failed');
          setSubmitError('POS Device creation failed');
          throw new Error('Create POS device failed');
        }
      },
      onError: (err) => {
        toast.error('POS device creation failed');
        console.error('Create POS device error', JSON.stringify(err));
      },
    });

    const {
      register,
      formState: { errors /* isSubmitSuccessful */ },
      reset,
      watch,
      setError,
      clearErrors,
      handleSubmit,
      setValue,
    } = useForm<CreatePosInput>({
      resolver: zodResolver(createPosSchema),
      defaultValues: createPosDefaultValues,
    });

    const { deviceNameAvaliable, deviceIDAvaliable } = useInputAvailability(
      watch,
      selectedProject?.id,
      setError,
      clearErrors,
      'POS'
    );

    useEffect(() => {
      getNextPOSId().then((nextID) => {
        setValue('deviceId', String(nextID || ''));
      });
    }, []);

    const onSubmitHandler: SubmitHandler<CreatePosInput> = (values) => {
      console.log('Submitting');
      setLoading(true);
      setSubmitError(null);
      const { deviceName, deviceId, prefix } = values;

      mutation.mutate(
        {
          deviceType: 'POS',
          deviceSubType: 'SIMATIC_S7_1200_POS',
          id: `${prefix}${deviceId}`,
          name: deviceName,
          projectId: selectedProject?.id as number,
          timezone: selectedProject?.timezoneStr as string,
        },
        {
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    };

    return (
      <Container disableGutters>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Create POS Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField sx={prefixStyles} disabled {...register('prefix')} />
            <TextField
              sx={{ flex: 5 }}
              InputLabelProps={{ shrink: !!watch('deviceId') }}
              label='Device ID'
              fullWidth
              error={!!errors['deviceId']}
              helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InputAdornmentIcon isValid={deviceIDAvaliable} />
                  </InputAdornment>
                ),
              }}
              {...register('deviceId')}
            />
          </Stack>
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...register('deviceName')}
          />
          <TextField
            label='Device Subtype'
            fullWidth
            sx={{ ...disabledTextFieldStyle, mb: 2 }}
            disabled
            value={'SIMATIC_S7_1200_POS'}
            InputLabelProps={{ shrink: true }}
          />
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons onCancel={onCancel} submitLabel='Create Device' />
        </Box>
      </Container>
    );
  }
);
