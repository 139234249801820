import { computed, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import jwtDecode from 'jwt-decode';
import {
  AuthStoreConfig,
  DecodedAccessToken,
  DecodedIdToken,
  DecodedTokens,
  LoginResponse,
} from './authStoreTypes';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { decodeTokensFromSession, setUserInfo } from './authStoreUtils';
import Utf8 from 'crypto-js/enc-utf8';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import { AbstractRootStore } from '../storeModels';
import { redirect } from 'react-router-dom';
import { CognitoChallengeResponse } from '../../types/authTypes';
import _ from 'lodash';
import { WindowEvents, WindowEventService } from '../../services/WindowEventService';
import { CognitoAttribute, CognitoUserDataDto, UserRoleDto } from '@electreon/electreon-user-management-service-gen-ts-client';

class AuthStore {
  rootStore: AbstractRootStore;
  federatedSignInUrl: string;
  devElectreonCognitoClientID: string;
  userPoolId: string;
  clientSecret: string;
  redirectUrl: string;
  tokenEndpoint: string;
  IdToken: string = '';
  AccessToken: string = '';
  RefreshToken: string = '';
  sub: string = '';
  cognitoChallenge?: CognitoChallengeResponse;
  private tokenCheckInterval: ReturnType<typeof setInterval>; /* NodeJS.Timeout */

  constructor(rootStore: AbstractRootStore, config: AuthStoreConfig) {
    this.rootStore = rootStore;
    this.federatedSignInUrl = config.federatedSignInUrl;
    this.devElectreonCognitoClientID = config.devElectreonCognitoClientID;
    this.userPoolId = config.userPoolId;
    this.clientSecret = config.clientSecret;
    this.redirectUrl = config.redirectUrl;
    this.tokenEndpoint = config.tokenEndpoint;

    makeAutoObservable(this);
    makePersistable(this, {
      name: 'AuthStore',
      properties: ['IdToken', 'AccessToken', 'RefreshToken', 'sub'],
      storage: window.localStorage,
    });

    // checks if the user is logged in every 5 seconds
    this.tokenCheckInterval = setInterval(() => {
      if (this.shouldAcquireToken) {
        this.refreshSession().catch((error: AxiosError<unknown, DecodedTokens>) => {
          console.error('Failed to refresh session: ', error);
          WindowEventService.emit(WindowEvents.REFRESH_SESSION_FAILED, error?.config?.data);
          this.logout();
        });
      }
    }, 5000);
  }

  @computed
  get shouldAcquireToken(): boolean {
    if (!this.IdToken) return false;
    const decodedToken = jwtDecode(this.IdToken) as DecodedIdToken;
    const expirationTimeInSeconds = decodedToken.exp;
    const currentTimeInSeconds = Date.now() / 1000;
    return expirationTimeInSeconds - currentTimeInSeconds <= 10;
  }

  public async login(
    username: string,
    password: string,
    onPasswordChangeRequired?: (cognitoChallengeResponse: CognitoChallengeResponse) => void
  ): Promise<DecodedTokens> {
    return new Promise(async (resolve, reject) => {
      const body = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: this.devElectreonCognitoClientID,
        UserPoolId: this.userPoolId,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          SECRET_HASH: this.calculateSecretHash(
            this.devElectreonCognitoClientID,
            this.clientSecret,
            username
          ),
        },
      };

      let authData: AxiosResponse;
      try {
        authData = await axios.post('https://cognito-idp.eu-west-1.amazonaws.com/', body, {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          },
        });
      } catch (error) {
        return reject(error);
      }

      if (authData.status !== 200) return reject(authData);

      if (authData.data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        onPasswordChangeRequired?.(authData.data);
        return;
      }
      const { IdToken, AccessToken, RefreshToken } = authData.data.AuthenticationResult;
      this.handleAuthenticatedUser({ IdToken, AccessToken, RefreshToken });
      const { decodedIdToken, decodedAccessToken } = decodeTokensFromSession({
        IdToken,
        AccessToken,
        RefreshToken,
      });

      resolve({
        IdToken: decodedIdToken,
        AccessToken: decodedAccessToken,
        RefreshToken: RefreshToken,
      });
    });
  }

  public async forceChangePassword(username: string, newPassword: string): Promise<DecodedTokens> {
    return new Promise(async (resolve, reject) => {
      let authData: AxiosResponse;
      try {
        authData = await this.rootStore.api.userManagement.userManagementApi.forceChangePassword({
          session: this.cognitoChallenge?.Session || '',
          username: username,
          newPassword,
          userIdForSrp: this.cognitoChallenge?.ChallengeParameters.USER_ID_FOR_SRP || '',
          userAttributes: '',
        });
      } catch (error) {
        return reject(error);
      }

      if (authData.status !== 201 && authData.status !== 200) return reject(authData);
      const { IdToken, AccessToken, RefreshToken } = authData.data.AuthenticationResult;
      this.handleAuthenticatedUser({ IdToken, AccessToken, RefreshToken });
      const { decodedIdToken, decodedAccessToken } = decodeTokensFromSession({
        IdToken,
        AccessToken,
        RefreshToken,
      });

      resolve({
        IdToken: decodedIdToken,
        AccessToken: decodedAccessToken,
        RefreshToken: RefreshToken,
      });
    });
  }

  private setTokens(IdToken: string, AccessToken: string, sub: string, RefreshToken?: string) {
    this.IdToken = IdToken;
    this.AccessToken = AccessToken;
    this.RefreshToken = RefreshToken || this.RefreshToken;
    this.sub = sub;
  }

  public getDecodedTokens(): DecodedTokens {
    const { decodedIdToken, decodedAccessToken } = decodeTokensFromSession({
      IdToken: this.IdToken,
      AccessToken: this.AccessToken,
      RefreshToken: this.RefreshToken,
    });

    return {
      IdToken: decodedIdToken,
      AccessToken: decodedAccessToken,
      RefreshToken: this.RefreshToken,
    };
  }

  public handleAuthenticatedUser({ IdToken, AccessToken, RefreshToken }: LoginResponse) {
    if (!IdToken || !AccessToken || !RefreshToken) {
      console.error('Missing tokens from Cognito response');
      this.logout();
      // window.location.href = this.federatedSignInUrl;
      redirect('/');
      return;
    }

    const decodedIdToken = jwtDecode(IdToken) as DecodedIdToken;
    const decodedAccessToken = jwtDecode(AccessToken) as DecodedAccessToken;

    this.setTokens(IdToken, AccessToken, decodedAccessToken.sub, RefreshToken);
    setUserInfo(
      { AccessToken: decodedAccessToken, IdToken: decodedIdToken, RefreshToken },
      this.rootStore.userStore
    );
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.IdToken;

    return { decodedIdToken, decodedAccessToken };
  }

  public setUserExistingTokens() {
    const authStore = localStorage.getItem('AuthStore');
    const parsedAuthStore = authStore ? JSON.parse(authStore) : {};
    if (Object.keys(parsedAuthStore).length === 0) {
      //do a logout from all tabs and then login again. not sure if needed
      throw new Error('No Tokens Found in LocalStorge, please close all tabs and try again.');
    }
    this.setTokens(
      parsedAuthStore.IdToken,
      parsedAuthStore.AccessToken,
      parsedAuthStore.sub,
      parsedAuthStore.RefreshToken
    );
  }

  async awsCallTokenEndpoint(code: string, grantType = 'authorization_code') {
    const params = new URLSearchParams();
    params.append('grant_type', grantType);
    params.append('client_id', this.devElectreonCognitoClientID);
    params.append('code', code);
    params.append('redirect_uri', this.redirectUrl);
    params.append('client_secret', this.clientSecret);

    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: this.tokenEndpoint,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const instance = axios.create();

    delete instance.defaults.headers.common['Authorization'];

    const awsResponse = await instance(requestData);

    if (awsResponse.status !== 200) {
      throw new Error(`Code ${code} authentication failed`);
    }

    return awsResponse.data;
  }

  public async handleLoginRedirect(code: string | null) {
    if (!code) throw new Error('No code provided to handleLoginRedirect');

    const awsResponse = await this.awsCallTokenEndpoint(code);
    if (!awsResponse) throw new Error('No response from AWS token endpoint');

    this.handleAuthenticatedUser({
      IdToken: awsResponse.id_token,
      AccessToken: awsResponse.access_token,
      RefreshToken: awsResponse.refresh_token,
    });

    const decodedIdToken = jwtDecode(this.IdToken) as DecodedIdToken;
    const decodedAccessToken = jwtDecode(this.AccessToken) as DecodedAccessToken;

    return {
      IdToken: decodedIdToken,
      AccessToken: decodedAccessToken,
      RefreshToken: this.RefreshToken,
    };
  }

  public async logout() {
    this.IdToken = '';
    this.AccessToken = '';
    this.RefreshToken = '';
    this.sub = '';
    delete axios.defaults.headers.common['Authorization'];
    clearInterval(this.tokenCheckInterval);
  }

  public async refreshSession(token?: string): Promise<DecodedTokens> {
    if (!this.shouldAcquireToken) return this.getDecodedTokens();

    const decodedToken = jwtDecode(token || this.IdToken) as DecodedIdToken;
    const username = decodedToken['cognito:username'];

    const body = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: this.devElectreonCognitoClientID,
      UserPoolId: this.userPoolId,
      AuthParameters: {
        REFRESH_TOKEN: this.RefreshToken,
        SECRET_HASH: this.calculateSecretHash(this.devElectreonCognitoClientID, this.clientSecret, username),
      },
    };

    const authData: AxiosResponse = await axios.post('https://cognito-idp.eu-west-1.amazonaws.com/', body, {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      },
    });

    if (authData.status !== 200) return Promise.reject(authData);

    const { IdToken, AccessToken } = authData.data.AuthenticationResult;

    this.handleAuthenticatedUser({
      IdToken,
      AccessToken,
      RefreshToken: this.RefreshToken,
    });

    const { decodedIdToken, decodedAccessToken } = decodeTokensFromSession({
      IdToken,
      AccessToken,
      RefreshToken: this.RefreshToken,
    });

    return Promise.resolve({
      IdToken: decodedIdToken,
      AccessToken: decodedAccessToken,
      RefreshToken: this.RefreshToken,
    });
  }

  public async getCurrentUserData(): Promise<CognitoUserDataDto> {
    const authData = await this.rootStore.api.userManagement.userManagementApi.getCurrentUser();
    if (authData.status !== 200) return Promise.reject(authData);
    return authData.data;
  }

  public async getUserData(email: string): Promise<CognitoUserDataDto | CognitoUserDataDto[] | null> {
    const authData = await this.rootStore.api.userManagement.userManagementApi.getUsersByEmail(email);
    if (authData.status !== 200) return Promise.reject(authData);
    const users = authData.data;
    if (users.length === 1) {
      return users[0];
    }
    if (users.length === 0) {
      return null;
    }
    return users;
  }

  public async getUserRole(username: string): Promise<UserRoleDto> {
    const authData = await this.rootStore.api.userManagement.userManagementApi.getUserRole(username);
    if (authData.status !== 200) return Promise.reject(authData);
    return authData.data;
  }

  public async updateCurrentUserData(username: string, attributes: CognitoAttribute[]) {
    const authData = await this.rootStore.api.userManagement.userManagementApi.updateUserData({ username, attributes })
    if (authData.status !== 201) return Promise.reject(authData);
  }

  public async updateUserData(username: string, attributes: CognitoAttribute[]) {
    const updateDataResponse = await this.rootStore.api.userManagement.userManagementApi.updateUserData({ username, attributes });
    if (updateDataResponse?.status !== 201) return Promise.reject(updateDataResponse);
  }

  public async updateUserRole(username: string, userRole: string) {
    const updateRoleResponse = await this.rootStore.api.userManagement.userManagementApi.updateUserRole({ username, userRole });
    if (updateRoleResponse?.status !== 201) return Promise.reject(updateRoleResponse);
  }

  public async updateUserScope(username: string, userScope: number[]) {
    const updateScopeResponse = await this.rootStore.api.userManagement.userManagementApi.updateUserScope({ username, userScope });
    if (updateScopeResponse?.status !== 201) return Promise.reject(updateScopeResponse);
  }

  public async enableUserAccount(username: string) {
    const enableUserResponse = await this.rootStore.api.userManagement.userManagementApi.enableUser({ username });
    if (enableUserResponse?.status !== 201) return Promise.reject(enableUserResponse);
  }

  public async disableUserAccount(username: string) {
    const disableUserResponse = await this.rootStore.api.userManagement.userManagementApi.disableUser({ username });
    if (disableUserResponse?.status !== 201) return Promise.reject(disableUserResponse);
  }

  public async deleteUserAccount(username: string) {
    const deleteUserResponse = await this.rootStore.api.userManagement.userManagementApi.deleteUser({ username });
    if (deleteUserResponse?.status !== 201) return Promise.reject(deleteUserResponse);
  }

  public async deleteUserOwnAccount() {
    const deleteUserResponse = await this.rootStore.api.userManagement.userManagementApi.deleteCurrentUser();
    if (deleteUserResponse?.status !== 201) return Promise.reject(deleteUserResponse);
  }

  private calculateSecretHash(client_id: string, client_secret: string, username: string): string {
    const key = Utf8.parse(client_secret);
    const message = Utf8.parse(`${username}${client_id}`);
    const hash = HmacSHA256(message, key);
    return hash.toString(Base64);
  }

  setCognitoChallenge(response: CognitoChallengeResponse) {
    if (!this.cognitoChallenge) {
      this.cognitoChallenge = { Session: '', ChallengeName: '', ChallengeParameters: {} };
    }
    this.cognitoChallenge.Session = response.Session;
    this.cognitoChallenge.ChallengeName = response.ChallengeName;
    this.cognitoChallenge.ChallengeParameters = _.cloneDeep(response.ChallengeParameters);
  }
}

export default AuthStore;
