import { GetDataForSelectedTimespan } from 'MobxStores/Types';
import LogRocket from 'logrocket';
import { LabelPosition } from 'recharts/types/component/Label';
import z from 'zod';

const GetDataForSelectedTimespanSchema = z.tuple([
  z.union([z.number(), z.date(), z.null()]),
  z.union([z.number(), z.date(), z.null()]),
  z.boolean().optional(),
]);

const handleError = (error: z.ZodError) => {
  error.errors.forEach((err) => {
    const fieldPath = err.path.length > 0 ? `Argument at index ${err.path[0]}` : '[Root level]';
    console.error(`Error in ${fieldPath}: ${err.message}`);
    LogRocket.captureException(new Error(`Error parsing history traversals in analytics`), {
      extra: {
        fieldPath,
        message: err.message,
      },
    });
  });
};

export const isDataRequest = (args: any[]): args is Parameters<GetDataForSelectedTimespan> => {
  const result = GetDataForSelectedTimespanSchema.safeParse(args);
  if (!result.success) {
    handleError(result.error);
    return false;
  }
  return result.success;
};

export const dutyCycleDataSchema = z.object({
  cycleInPercentage: z.string().optional(),
  labelPosition: z
    .custom<LabelPosition>((val) => {
      return val === 'insideLeft' || val === 'insideRight';
    }, 'incorrect label position')
    .optional(),
  points: z
    .array(z.number().nullable().optional())
    .transform((val): number[] => {
      if (val.some((item) => item == null || item === undefined)) {
        return [] as number[];
      }
      return val as number[];
    })
    .optional(),
});

export const sharedUrlParamsSchema = z.object({
  devicesArr: z.array(
    z.object({
      id: z.string(),
      params: z.custom<Set<string>>((val) => {
        return val instanceof Object;
      }, 'incorrect type for params'),
    })
  ),
  selectedDate: z.union([z.date(), z.string()]),
  selectedTimespan: z
    .object({
      startTime: z.union([z.date(), z.number(), z.string()]),
      endTime: z.union([z.date(), z.number(), z.string()]),
    })
    .optional(),
  activityDeviceId: z.string().optional(),
  selectedEvents: z.record(z.string(), z.record(z.string(), z.record(z.string(), z.boolean())).optional()),
  userTz: z.string(),
  stickyMarkers: z
    .record(
      z.string(),
      z
        .array(
          z.object({
            currentY: z.number().optional(),
            currentValue: z.union([z.number(), z.string()]).optional(),
            currentTimestamp: z.number().optional(),
            activeDotYCoordRef: z
              .custom<React.MutableRefObject<number | undefined> | undefined>((val) => {
                return typeof val.current === 'number' || val.current === undefined;
              })
              .optional(),
          })
        )
        .optional()
    )
    .optional(),
  dutyCycleObj: z.record(z.string(), dutyCycleDataSchema).optional(),
});

export const isValidSharedParams = (
  paramsObj: any
): paramsObj is z.SafeParseSuccess<z.infer<typeof sharedUrlParamsSchema>> => {
  const result = sharedUrlParamsSchema.safeParse(paramsObj);

  if (!result.success) {
    handleError(result.error);
    console.error(result.error.format());
    return false;
  }
  return result.success;
};
