import {
  ActiveCommBoards,
  ActiveErbs,
  ActiveFanBoards,
  DcSamplersShadowProperties,
} from '../../types/socketMessageTypes';

export const vehicleTypes = ['BUS', 'TRUCK', 'PRIVATE', 'VAN', 'DELIVERY_TRUCK'] as const;

export type DeviceStoreVersions = {
  [deviceId: string]: {
    deviceChangesVersion?: number;
    fpgasVersions?: {
      [fpgaId: string]: {
        linuxAppVersion?: string;
        fpgaFWVersion?: string;
      };
    };
    canTxMsgTimeIntervalInMs?: number;
    voltageMin?: number;
    voltageMax?: number;
    receiverMaxCurrent?: number;
    dcSamplersProperties?: DcSamplersShadowProperties;
    firmwareVersion?: string;
    activeFanBoards?: ActiveFanBoards;
    activeCommBoards?: ActiveCommBoards;
    rootFsVersion?: string;
    bootloaderVersion?: string;
    activeErbs?: ActiveErbs;
  };
};

type FpgaHistoryEvent = {
  ts: number;
  nameString?: string;
  typeName?: string;
};

export type EventsMessagesHistoryProps = {
  key: string | null;
  data: FpgaHistoryEvent | null;
  showMoreInfo: boolean;
  deviceId: string;
  shelfNumber?: string | null;
  segmentNumber?: string | null;
  deviceType?: 'MU' | 'VU';
};
