import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, useWatch, SubmitErrorHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { api } from 'Services/api';
import { number, object, string } from 'zod';
import { PopupWrapper } from 'Components/Forms/PopupWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { GeoJsonMultipolygon, MultiPolygonCoordinates } from 'Components/Map/MapLoader';
import { Map } from 'Components/Map/Map';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';

const setNewPolygonsFromGeoJsonMultipolygon = (multiPolygon?: GeoJsonMultipolygon): google.maps.Polygon[] => {
  if (!multiPolygon || typeof google === 'undefined' || !google.maps.Polygon) return [];

  const newPolygons = multiPolygon.coordinates?.map((polygon) => {
    const paths = polygon.map((path) => {
      return path.map((point) => {
        return { lng: point[0], lat: point[1] };
      });
    });

    return new google.maps.Polygon({ paths, draggable: true, editable: true });
  });

  return newPolygons || [];
};

const editDepotSchema = object({
  depotId: number().optional(),
  depotName: string().optional(),
});

type EditDepotInput = {
  depotId: number | undefined;
  depotName: string;
};

interface EditDepotForm extends FormProps {
  depotId?: number;
}

export const EditDepotForm: React.FC<EditDepotForm> = ({
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
  depotId,
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [depotList, setDepotList] = useState<Depot[]>([]);
  const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
  const [geoFenceModalOpen, setGeoFenceModalOpen] = useState(false);
  const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);

  const setModalState = ({ isOpen }: { isOpen: boolean }) => {
    setGeoFenceModalOpen(isOpen);
  };

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
    setValue,
  } = useForm<EditDepotInput>({
    resolver: zodResolver(editDepotSchema),
    defaultValues: {
      depotId: undefined,
      depotName: '',
    },
    mode: 'onChange',
    resetOptions: { keepDirtyValues: true, keepDirty: true, keepDefaultValues: false },
  });

  const onSubmitHandler: SubmitHandler<EditDepotInput> = (values) => {
    const coordinates: MultiPolygonCoordinates = polygons.map((polygon) => {
      const path = polygon.getPath();
      const points = path.getArray().map((point) => [point.lng(), point.lat()]);
      return [points];
    });

    const geoJsonMultipolygon = {
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'MultiPolygon',
            coordinates,
          },
        },
      ],
    };

    const depot: Depot = {
      id: values.depotId,
      name: values.depotName,
      projectId: selectedProject?.id,
      version: selectedDepot?.version,
      geoFence: JSON.stringify(geoJsonMultipolygon),
    };

    api.metadata.depots
      .updateDepot(depot)
      .then((res) => {
        toast.success('Depot updated');
        reset({ depotId: undefined, depotName: '' }, { keepDirtyValues: true });
        onSuccessfulSubmit?.();
      })
      .catch((err) => {
        toast.error('Edit depot failed');
        console.error('Edit depot failed:', JSON.stringify(err));
        setSubmitError('Edit depot failed');
        throw new Error('Edit depot failed');
      });
  };

  const onSubmitError: SubmitErrorHandler<EditDepotInput> = (err, event) => {
    console.error(JSON.stringify(err));
    return false;
  };

  const form = useWatch({ control });

  // get depots and static mus on project change (or on first render)
  useEffect(() => {
    const getDepots = async () => {
      if (!selectedProject?.id) return;
      try {
        const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
        if (depotRes) setDepotList(depotRes.data);
      } catch (e) {
        setDepotList([]);
        console.error(JSON.stringify(e));
      }
    };
    getDepots();
  }, [selectedProject]);

  useEffect(() => {
    if (depotId) {
      const depot = depotList.find((depot) => depot.id === depotId);
      if (depot) {
        setSelectedDepot(depot);
        setValue('depotId', depot.id);
        setValue('depotName', String(depot.name));
      }
    }
  }, [depotList, depotId, setValue]);

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit Depot
      </Typography>

      <Stack
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmitHandler, onSubmitError)}
      >
        <SelectProject selectedProject={selectedProject} errors={errors} />
        <Autocomplete
          fullWidth
          options={depotList}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name || 'Unnamed Depot'}
          onChange={async (_, depot) => {
            setPolygons([]);
            if (!depot) return;

            setTimeout(() => {
              if (!depot.geoFence || !JSON.parse(depot.geoFence)?.features) return;
              const newPolygons = setNewPolygonsFromGeoJsonMultipolygon(
                JSON.parse(depot.geoFence).features[0].geometry as GeoJsonMultipolygon
              );
              setPolygons(newPolygons);
            }, 0);

            setSelectedDepot(depot);
            reset({ ...form, depotName: depot.name, depotId: depot.id }, { keepDirtyValues: true });
          }}
          value={depotId ? selectedDepot : null}
          renderInput={(params) => <TextField {...params} label='Depot' value={selectedDepot?.name || ''} />}
          disabled={!!depotId}
        />
        {selectedDepot && (
          <TextField
            sx={{ my: 2 }}
            label='Depot Name'
            fullWidth
            error={!!errors['depotName']}
            helperText={errors['depotName'] ? errors['depotName'].message : ''}
            {...register('depotName')}
          />
        )}
        {/* button to open another modal with map for selecting geofence */}
        <Button
          variant='outlined'
          sx={{ minWidth: '200px', alignSelf: 'center', mt: 3 }}
          onClick={() => setModalState({ isOpen: true })}
        >
          {polygons.length > 0 ? 'Edit Geofence' : 'Add Geofence'}
        </Button>

        <Stack direction='row' sx={{ placeContent: 'center' }}>
          <FormHelperText sx={{ mb: 2, alignSelf: 'center' }}>
            <Typography
              component='span'
              sx={{
                display: 'block',
                px: 2,
                pt: 1,
                color: (theme) =>
                  polygons.length > 0 ? theme.palette.accent.primary.main : theme.palette.accent.red.main,
              }}
            >
              {polygons.length > 0
                ? `${polygons.length} geo-fence${polygons.length > 1 ? 's' : ''} selected`
                : 'No geo-fence selected'}
            </Typography>
          </FormHelperText>
          {polygons.length > 0 && (
            <Box
              sx={{
                alignSelf: 'center',
                cursor: 'pointer',
                '&:hover': { color: (theme) => theme.palette.accent.red.light },
              }}
              onClick={() => {
                setPolygons([]);
              }}
            >
              <DeleteIcon />
            </Box>
          )}
        </Stack>

        <Stack
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            width: '100%',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          {submitError && (
            <Stack alignItems={'center'} sx={{ mt: 2 }}>
              <Alert severity='error'>{submitError}</Alert>
            </Stack>
          )}
          <FormSubmitionButtons onCancel={onCancel} />
        </Stack>
      </Stack>

      {/* Map popup */}
      <PopupWrapper
        open={geoFenceModalOpen}
        onClose={() => setModalState({ isOpen: false })}
        wrapperStyles={{
          width: '70vw',
          height: '65vh',
          padding: 0,
        }}
      >
        <Map
          polygons={polygons}
          setPolygons={setPolygons}
          onSubmitted={() => setModalState({ isOpen: false })}
        />
      </PopupWrapper>
    </>
  );
};
EditDepotForm.displayName = 'EditDepotForm';
