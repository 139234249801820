import { observer } from 'mobx-react-lite';
import {
  Alert,
  Autocomplete,
  Box,
  TextField,
  Typography,
  InputAdornment,
  Container,
  Button,
} from '@mui/material';
import { InputAdornmentIcon } from '../CreateDevicePopup/utils/InputAdormentIcon';
import { useState } from 'react';
import { api } from 'Services/api';
import { formButtonStyle } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtonsStyles';
import { downloadFile } from 'Utils/APIUtils';
import {
  FirmwareOptionName,
  FirmwareOptionToApiMap,
  debouncedCheckFotaVersion,
} from 'Components/Forms/FirmwareDownloadForm/firmwareDownloadFormUtils';
import { ValuesOf } from '@electreon_ui/shared/types/globals';
import { toast } from 'sonner';

export const FirmwareDownloadForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
  const [firmwareOption, setFirmwareOption] = useState<ValuesOf<typeof FirmwareOptionName> | null>(null);
  const [fotaVersionExists, setFotaVersionExists] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async () => {
    setLoading(true);
    if (!firmwareOption || !selectedVersion) {
      setLoading(false);
      return toast.error('Please fill all fields');
    }
    const { fnName, indirectDownload, apiBase } = FirmwareOptionToApiMap[firmwareOption];
    try {
      if (!indirectDownload) {
        // Handle direct download (blob)
        const options = { responseType: 'blob' };
        // @ts-ignore
        const res = await api.deviceControl[apiBase][fnName](selectedVersion, options);
        downloadFile(
          window.URL.createObjectURL(res.data as Blob),
          `${FirmwareOptionToApiMap[firmwareOption].fileName?.replace('%s', selectedVersion)}`
        );
      } else {
        // Handle indirect download (S3 URL)
        // @ts-ignore
        const res = await api.deviceControl[apiBase][fnName](selectedVersion);
        downloadFile(
          res.data as string,
          `${FirmwareOptionToApiMap[firmwareOption].fileName?.replace('%s', selectedVersion)}`
        );
      }
      toast.success('Download success');
      onSuccessfulSubmit?.();
    } catch (err: any) {
      toast.error('Download failed: ' + err.message || 'Unknown error');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFirmwareOptionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ValuesOf<typeof FirmwareOptionName> | null
  ) => {
    setFotaVersionExists(null);
    setSelectedVersion(null);
    setFirmwareOption(value);
  };

  return (
    <>
      <span className='!text-2xl'>Download Firmware Files</span>
      <Box sx={{ mt: 2 }}>
        <Autocomplete
          disablePortal
          options={Object.values(FirmwareOptionName)}
          value={firmwareOption}
          onChange={handleFirmwareOptionChange}
          renderInput={(params) => (
            <TextField {...params} label='Firmware Type' sx={{ mb: 2 }} placeholder='Firmware Type' />
          )}
        />
        <TextField
          sx={{ mb: 2 }}
          label='Version'
          fullWidth
          type='version'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <InputAdornmentIcon isValid={fotaVersionExists} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            if (!firmwareOption) return setFotaVersionExists(false);
            setFotaVersionExists(null);
            setSelectedVersion(e.target.value);
            debouncedCheckFotaVersion({
              version: e.target.value,
              deviceType: FirmwareOptionToApiMap[firmwareOption].deviceType,
              setFotaVersionExists,
              firmwareType: FirmwareOptionToApiMap[firmwareOption].fotaType,
              firmwareSubType: FirmwareOptionToApiMap[firmwareOption].fotaSubType,
            });
          }}
        />
      </Box>
      {!fotaVersionExists && (
        <Alert severity='error' sx={{ mb: 2 }}>
          Valid version is required
        </Alert>
      )}
      <Container disableGutters sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 1 }}>
        <Button
          variant='contained'
          sx={formButtonStyle}
          disabled={!fotaVersionExists}
          onClick={onSubmitHandler}
        >
          Download
        </Button>
        <Button variant='outlined' sx={formButtonStyle} onClick={onCancel}>
          Cancel
        </Button>
      </Container>
    </>
  );
});
