import { ProjectDeployment, ParkingSpot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { OcppChargerModelRefined, ManagementUnitModelRefined } from '@electreon_ui/shared/types/globals';
import { isMu, isOcpp } from '@electreon_ui/shared/utils/globalUtils';

export const updateOcppInDepot = (
  oldData: ProjectDeployment,
  parkingSpots: ParkingSpot[],
  ocppId: string,
  depotId: number,
  device?: OcppChargerModelRefined
) => {
  if (oldData && device) {
    //incorrect parckingSpotsId while optimistic update
    const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
      if (depot.id === depotId) {
        device.depotId = depotId;
        return {
          ...depot,
          ocppChargers: [...(depot.ocppChargers || []), { ocppCharger: device, parkingSpots: parkingSpots }],
        };
      }
      return depot;
    });

    return {
      ...oldData,
      unassignedOcppChargers: oldData?.unassignedOcppChargers?.filter((charger) => charger.id !== ocppId),
      staticDeployment: {
        depots: updatedStaticDeployment,
      },
    };
  }
};

export const updateDeviceInDepot = (
  oldData: ProjectDeployment,
  deviceId: string,
  selectedDevice?: OcppChargerModelRefined | ManagementUnitModelRefined
) => {
  const isOcppDevice = isOcpp(selectedDevice);
  const isMuDevice = isMu(selectedDevice);

  if (oldData) {
    const depotId = selectedDevice?.depotId;
    if (isOcppDevice) {
      const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
        if (depot.id === depotId) {
          return {
            ...depot,
            ocppChargers: depot?.ocppChargers?.filter((ocpp) => ocpp?.ocppCharger?.id !== deviceId),
          };
        }
        return depot;
      });
      selectedDevice.depotId = undefined;
      return {
        ...oldData,
        unassignedOcppChargers: [...(oldData.unassignedOcppChargers || []), selectedDevice],
        staticDeployment: {
          depots: updatedStaticDeployment,
        },
      };
    } else if (isMuDevice) {
      const updatedStaticDeployment = oldData.staticDeployment?.depots?.map((depot) => {
        if (depot.id === depotId) {
          return {
            ...depot,
            managementUnits: depot?.managementUnits?.filter((mu) => mu?.managementUnit?.id !== deviceId),
          };
        }
        return depot;
      });
      selectedDevice.depotId = undefined;
      return {
        ...oldData,
        staticDeployment: {
          depots: updatedStaticDeployment,
        },
        unassignedUnits: [...(oldData.unassignedUnits || []), selectedDevice],
      };
    }
  }
};
