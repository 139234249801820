import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { computed, makeAutoObservable, reaction } from 'mobx';
import { IoTDeviceType } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import {
  ApfcModelRefined,
  Logo,
  ManagementUnitModelRefined,
  OcppChargerModelRefined,
  PosModelRefined,
  UiDevice,
  VehicleUnitModelRefined,
} from '../../types/globals';
import { ElectreonApiServices } from '../../services/ElectreonApiServices';
import { AbstractRootStore } from '../storeModels';
import { getProjectMetadata } from './projectStoreUtils';

class ProjectStore {
  api: ElectreonApiServices;
  selectedProject: Project | null = null;
  muList: ManagementUnitModelRefined[] = [];
  vuList: VehicleUnitModelRefined[] = [];
  posList: PosModelRefined[] = [];
  apfcList: ApfcModelRefined[] = [];
  ocppList: OcppChargerModelRefined[] = [];
  rootStore: AbstractRootStore;
  cachedLogos: Record<string, Logo> = {};

  constructor(rootStore: AbstractRootStore, api: ElectreonApiServices) {
    this.api = api;
    this.rootStore = rootStore;
    makeAutoObservable(this);

    // Setting MU, VU, POS, APFC lists and sets initial lock states in deviceLockStore
    reaction(
      () => this.selectedProject?.id,
      (projectId) => {
        if (!projectId) {
          // Clear device lists
          this.setMuList([]);
          this.setVuList([]);
          this.setPosList([]);
          this.setApfcList([]);
          this.setOcppList([]);
          return;
        }
        getProjectMetadata(projectId, this);
      }
    );
  }

  setMuList(muList: ManagementUnitModelRefined[]) {
    this.muList = muList?.toSorted((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
  }

  setVuList(vuList: VehicleUnitModelRefined[]) {
    this.vuList = vuList?.toSorted((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
  }

  setPosList(posList: PosModelRefined[]) {
    this.posList = posList;
  }

  setApfcList(apfcList: ApfcModelRefined[]) {
    this.apfcList = apfcList;
  }

  setOcppList(ocppList: OcppChargerModelRefined[]) {
    this.ocppList = ocppList as OcppChargerModelRefined[];
  }

  setSelectedProject = (project: Project | null) => {
    this.selectedProject = project;
  };

  @computed
  get projectIdList() {
    return (
      this.rootStore?.queryClient?.getQueryData<Project[]>(['projects'])?.map((project) => project.id) ?? []
    );
  }

  @computed
  get muMap() {
    const muMap = new Map<string, ManagementUnitModelRefined>();
    this.muList.forEach((mu) => {
      if (!mu.id) return;
      muMap.set(mu.id, mu);
    });
    return muMap;
  }

  @computed
  get vuMap() {
    const vuMap = new Map<string, VehicleUnitModelRefined>();
    this.vuList?.forEach((vu) => {
      if (!vu.id) return;
      vuMap.set(vu.id, vu);
    });
    return vuMap;
  }

  @computed
  get posMap() {
    const posMap = new Map<string, PosModelRefined>();
    this.posList.forEach((pos) => {
      if (!pos.id) return;
      posMap.set(pos.id, pos);
    });
    return posMap;
  }

  @computed
  get apfcMap() {
    const apfcMap = new Map<string, ApfcModelRefined>();
    this.apfcList.forEach((apfc) => {
      if (!apfc.id) return;
      apfcMap.set(apfc.id, apfc);
    });
    return apfcMap;
  }

  @computed
  get ocppMap() {
    const ocppMap = new Map<string, OcppChargerModelRefined>();
    this.ocppList.forEach((ocpp) => {
      if (!ocpp.id) return;
      ocppMap.set(ocpp.id, ocpp);
    });
    return ocppMap;
  }

  @computed
  get deviceIdToIOTDeviceModelMap() {
    const deviceIdToIOTModelMap = new Map<string, UiDevice>();
    this.muList.forEach((mu) => {
      if (!mu.id) throw new Error(`Management Unit ${mu.name} has no id`);
      deviceIdToIOTModelMap.set(mu.id, { ...mu, deviceType: IoTDeviceType.Mu });
    });
    this.vuList?.forEach((vu) => {
      if (!vu.id) throw new Error(`Vehicle Unit ${vu.name} has no id`);
      deviceIdToIOTModelMap.set(vu.id, vu);
    });
    this.posList.forEach((pos) => {
      if (!pos.id) throw new Error(`POS ${pos.name} has no id`);
      deviceIdToIOTModelMap.set(pos.id, pos);
    });
    this.apfcList.forEach((apfc) => {
      if (!apfc.id) throw new Error(`APFC ${apfc.name} has no id`);
      deviceIdToIOTModelMap.set(apfc.id, apfc);
    });
    this.ocppList.forEach((ocpp) => {
      if (!ocpp.id) throw new Error(`OCPP ${ocpp.name} has no id`);
      deviceIdToIOTModelMap.set(ocpp.id, ocpp);
    });
    return deviceIdToIOTModelMap;
  }

  setCachedLogos(projectId: string | number, logo: { main: string; small: string }) {
    if (!this.cachedLogos) this.cachedLogos = {};
    this.cachedLogos = { ...this.cachedLogos, [String(projectId)]: { main: logo?.main, small: logo?.small } };
  }

  @computed
  get projectIdToProjectMap() {
    const projectIdToProjectMap = new Map<number, Project>();
    this.rootStore?.queryClient?.getQueryData<Project[]>(['projects'])?.forEach((project) => {
      if (!project.id) return;
      projectIdToProjectMap.set(project.id, project);
    });
    return projectIdToProjectMap;
  }

  resetStore() {
    this.selectedProject = null;
  }
}

export default ProjectStore;
