import { useEffect, useState } from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, SubmitErrorHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';

import { Depot, ParkingSpot, ProjectDeployment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { useAppStore } from 'MobxStores/context';
import { useLockState } from 'CustomHooks/useLockState';
import { AbstractIoTDeviceModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { ManagementUnitModelRefined } from '@electreon_ui/shared/types/globals';

import {
  CreateParkingSpotsInput,
  createParkingSpotsDefaultValues,
  createParkingSpotsSchema,
} from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotSchema';
import { getAndRefreshParkingSpots } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotUtils';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { ParkingSpotsWithSegmentsFields } from 'Components/Forms/ParkingSpotForm/ParkingSpotsFields/ParkingSpotsFields';
import { getMaxContactors } from 'Components/Forms/ParkingSpotForm/utils/getMaxContactors';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { LockButton } from 'Components/Buttons/LockButton/LockButton';
import { DeviceNameInput } from 'Components/Forms/EditAPFC&POSForms/FormInputs/DeviceNameInput';
import { DepotSelectionInput } from 'Components/Forms/EditAPFC&POSForms/FormInputs/DepotSelectionInput';
import { MuSelectionInput } from 'Components/Forms/EditAPFC&POSForms/FormInputs/MuSelectionInput';
import { manageParkingSpotsMutation } from 'Components/Forms/ParkingSpotForm/utils/manageParkingSpotsFormUtils';

interface ManageParkingSpotsFormProps extends FormProps {
  selectedDevice: ManagementUnitModelRefined;
  depotId?: string | number;
}

export const ManageParkingSpotsForm: React.FC<ManageParkingSpotsFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject, selectedDevice, depotId }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [depotList, setDepotList] = useState<Depot[]>([]);
    const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
    const [maxParkingSpots, setMaxParkingSpots] = useState<number | undefined>(undefined);
    const [selectedMu, setSelectedMu] = useState<ManagementUnitModelRefined | null>(selectedDevice || null);
    const { userStore, projectStore } = useAppStore();
    const methods = useForm<CreateParkingSpotsInput>({
      resolver: zodResolver(createParkingSpotsSchema),
      defaultValues: { ...createParkingSpotsDefaultValues, projectId: selectedProject?.id },
      mode: 'onChange',
      resetOptions: { keepDirtyValues: true, keepDirty: true, keepDefaultValues: false },
    });
    const showEmptyParkingSpotsAlert = methods.getValues('parkingSpots').length === 0;
    const sessionId = userStore.userData?.sub;

    const mutation = useMutation({
      mutationFn: ({
        depotId,
        muId,
        sessionId,
        parkingSpotsWithMuAndDepotId,
      }: {
        depotId: number;
        muId: string;
        sessionId: string;
        parkingSpotsWithMuAndDepotId: ParkingSpot[];
      }) =>
        api.metadata.parkingSpots.createOrUpdateParkingSpots(
          depotId,
          muId,
          sessionId,
          parkingSpotsWithMuAndDepotId
        ),
      onMutate: ({ depotId, muId, parkingSpotsWithMuAndDepotId }) => {
        if (selectedDevice.depotId) return;
        projectStore.rootStore?.queryClient?.setQueryData(
          ['projectDeployment', +projectStore.selectedProject?.id!],
          (oldData: ProjectDeployment) =>
            manageParkingSpotsMutation(oldData, depotId, muId, parkingSpotsWithMuAndDepotId, selectedDevice)
        );
      },
      onSuccess: () => {
        toast.success('Parking spots updated');
        onSuccessfulSubmit?.();
      },
      onError: (err) => {
        toast.error('Parking spots update failed');
        console.error(JSON.stringify(err));
        setSubmitError(err.message);
      },
    });

    const onSubmitHandler: SubmitHandler<CreateParkingSpotsInput> = (values) => {
      if (!sessionId) {
        console.error('No session id found');
        return;
      }
      setSubmitError(null);
      const hasParkingSpotNameChanged = (index: number) =>
        Boolean(methods.formState?.dirtyFields.parkingSpots?.[index]?.name);

      const parkingSpotsWithMuAndDepotId: ParkingSpot[] = values.parkingSpots.map((spot, i) => ({
        ...spot,
        id: hasParkingSpotNameChanged(i) ? undefined : spot.id,
        depotId: values.depotId,
        muId: values.muId,
        segments: spot.segments?.map((segment) => ({
          ...segment,
          muId: values.muId,
          projectId: values.projectId,
          drawerNum: 0,
          parkingSpotId: hasParkingSpotNameChanged(i) ? undefined : spot.id,
        })),
      }));

      mutation.mutate({
        depotId: values.depotId,
        muId: values.muId,
        sessionId,
        parkingSpotsWithMuAndDepotId,
      });
    };

    const onSubmitError: SubmitErrorHandler<CreateParkingSpotsInput> = (err) => {
      if (err?.parkingSpots?.some?.((spot) => spot?.segments?.message)) {
        setSubmitError('At least one segment is required for each parking spot');
      }
      if (err.parkingSpots?.message) setSubmitError(err.parkingSpots.message);
      return false;
    };
    const selectedMuLockState = useLockState(selectedMu as AbstractIoTDeviceModel | undefined);

    // update depots list
    useEffect(() => {
      const getDepots = async () => {
        if (!selectedProject?.id) return;
        try {
          const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
          if (depotRes) {
            setDepotList(depotRes.data);
            const selectedDepot = depotRes.data.find((depot) => depot.id === selectedDevice?.depotId);
            if (selectedDepot) {
              setSelectedDepot(selectedDepot);
              methods.setValue('depotId', Number(selectedDepot?.id));
            }
          }
        } catch (e) {
          setDepotList([]);
          console.error(JSON.stringify(e));
        }
      };
      getDepots();
    }, [selectedProject, methods, selectedDevice?.depotId]);

    // update parking spots list
    useEffect(() => {
      const updateParkingSpots = async () => {
        if (selectedDepot?.id && selectedMu?.id) {
          try {
            const parkingSpots = await getAndRefreshParkingSpots(selectedDepot?.id, selectedMu?.id);
            if (selectedDepot?.id === selectedMu.depotId) {
              methods.reset(
                { ...methods.getValues(), muId: selectedMu?.id, parkingSpots },
                { keepDirtyValues: true }
              );
            } else {
              const parkingSpots = methods
                .getValues('parkingSpots')
                .map((parkingSpot) => ({ ...parkingSpot, depotId: selectedDepot.id, id: undefined }));
              methods.reset(
                { ...methods.getValues(), muId: selectedMu?.id, parkingSpots },
                { keepDirtyValues: true }
              );
            }
          } catch (e) {
            methods.reset({ ...methods.getValues(), muId: '', parkingSpots: [] }, { keepDirtyValues: true });
            console.error(JSON.stringify(e));
          }
        }
      };
      updateParkingSpots();
    }, [selectedMu?.id, selectedDepot?.id, methods, selectedMu?.depotId]);

    useEffect(() => {
      if (!selectedMu?.id) return;
      getMaxContactors(selectedMu?.id).then((max) => setMaxParkingSpots(max));
    }, [selectedMu?.id]);

    return (
      <FormProvider {...methods}>
        {selectedMu && !selectedMuLockState.isLockedByMe && (
          <Alert severity='error' sx={{ mb: 2 }}>
            This MU is not locked by you. Your changes will not be saved.
          </Alert>
        )}
        <Stack direction='row' gap={2}>
          <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
            {depotId ? 'Manage Parking Spots' : 'Assign MU to Depot'}
          </Typography>
          <LockButton device={selectedMu || null} />
        </Stack>
        {showEmptyParkingSpotsAlert && (
          <Alert severity='warning'>
            Assigned MU device should have at least one Parking Spot with one Segment.
          </Alert>
        )}
        <Box
          component='form'
          noValidate
          autoComplete='off'
          sx={showEmptyParkingSpotsAlert ? { mt: 4 } : {}}
          onSubmit={methods.handleSubmit(onSubmitHandler, onSubmitError)}
        >
          <SelectProject selectedProject={selectedProject} errors={methods.formState?.errors} />
          <Stack direction='row'>
            <DeviceNameInput
              currentDeviceName={selectedMu?.name || ''}
              deviceType={'MU'}
              disabled={!!selectedDevice}
            />
            <MuSelectionInput
              selectedMu={selectedMu}
              setSelectedMu={setSelectedMu}
              disabled={!!selectedDevice}
            />
          </Stack>
          <DepotSelectionInput
            depotList={depotList}
            selectedDepot={selectedDepot}
            setSelectedDepot={setSelectedDepot}
          />
          <ParkingSpotsWithSegmentsFields
            errors={methods.formState?.errors}
            maxParkingSpots={maxParkingSpots}
            control={methods.control}
          />
          <Stack
            sx={{
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
              width: '100%',
              backgroundColor: (theme) => theme.palette.background.default,
            }}
          >
            {submitError && (
              <Stack alignItems={'center'} sx={{ mt: 2 }}>
                <Alert severity='error'>{submitError}</Alert>
              </Stack>
            )}
            <FormSubmitionButtons
              onCancel={onCancel}
              submitLabel={
                !selectedMuLockState.isLockedByMe && selectedMu ? 'Please lock MU first' : undefined
              }
              sx={{ py: '0.8rem', marginBlock: 2, height: '45px' }}
            />
          </Stack>
        </Box>
      </FormProvider>
    );
  }
);
ManageParkingSpotsForm.displayName = 'ManageParkingSpotsForm';
