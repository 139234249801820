import { getAlpha2Code } from 'Components/Header/Utils/headerUtils';
import { AWS_CONFIG } from 'aws/config';
import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

type FlagIconProps = {
  countryCode: string;
  className?: string;
  width?: number;
  height?: number;
  borderRadius?: number;
};

const fetchFlag = async (countryCode: string) => {
  const S3_FLAGS_URL = `${AWS_CONFIG.STATIC_ASSETS}/static-assets/country-flags`;
  const flagUrl = `${S3_FLAGS_URL}/${getAlpha2Code(countryCode)}.svg`;
  const response = await fetch(flagUrl);
  return URL.createObjectURL(await response.blob());
};

export const Flag: React.FC<FlagIconProps> = ({
  countryCode,
  className,
  width = 45,
  height = 34,
  borderRadius = 3,
}) => {
  const { data: flagUrl, isLoading } = useQuery({
    queryKey: ['flag', countryCode],
    queryFn: () => fetchFlag(countryCode),
    staleTime: Infinity,
  });

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant='rectangular'
          width={width}
          sx={{ aspectRatio: '16 / 9', borderRadius: `${borderRadius}px` }}
        />
      ) : (
        <img
          src={flagUrl}
          alt={`Flag of ${countryCode}`}
          className={className}
          style={{
            borderRadius: `${borderRadius}px`,
            width: `${width}px`,
            height: `${height}px`,
          }}
        />
      )}
    </>
  );
};
